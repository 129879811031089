import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { Link } from "react-router-dom"
import { ArrowRightIcon, Copy, ExternalLink } from "lucide-react"
import { Button } from "@/components/ui/button"
import { useProvider } from "@/utils/providers"
import LogoFull from "../../svg/logo-full"
import { Separator } from "@/components/ui/separator.tsx"

const ApplicationConsent: React.FC = () => {
	const navigate = useNavigate()
	const [appLinks, setLinks] = useState<{ name: string; link: string }[]>([])
	const [copied, setCopied] = useState<Record<string, boolean>>({})
	const { isAzureDevops } = useProvider()

	useEffect(() => {
		if (!isAzureDevops) {
			navigate("/onboarding")
		} else {
			const tenantId = sessionStorage.getItem("tenantId")
			if (tenantId) {
				// build consent links
				generateConsentLinks(tenantId)
			}
		}
	}, [])

	const buildLink = (tenantId: string, clientId: string) => {
		return `https://login.microsoftonline.com/${tenantId}/adminconsent?client_id=${clientId}&state=azure-devops-${tenantId}&redirect_uri=${import.meta.env.VITE_AZURE_DEVOPS_REDIRECT_URL}`
	}

	const generateConsentLinks = (tenantId: string) => {
		const appLink = buildLink(
			tenantId,
			import.meta.env.VITE_AZURE_DEVOPS_CLIENT_ID,
		)
		const apiLink = buildLink(
			tenantId,
			import.meta.env.VITE_AZURE_DEVOPS_API_CLIENT_ID,
		)

		setLinks([
			{
				name: "CodeRabbit UI",
				link: appLink,
			},
			{
				name: "CodeRabbit API",
				link: apiLink,
			},
		])

		setCopied({
			[appLink]: false,
			[apiLink]: false,
		})
	}

	const copyLink = (link: string) => {
		void navigator.clipboard.writeText(link).then(() => {
			setCopied(oldValue => {
				return {
					...oldValue,
					[link]: true,
				}
			})
			setTimeout(() => {
				setCopied(oldValue => {
					return {
						...oldValue,
						[link]: false,
					}
				})
			}, 2000)
		})
	}

	return (
		<>
			<div className="m-auto flex w-[500px] flex-col">
				<LogoFull className="mx-auto my-10" />
				<div className="flex flex-wrap items-center justify-between gap-4">
					<div className="flex flex-col">
						<div className="font-poppins mb-2 text-xl font-semibold text-black">
							Consent links
						</div>
						<div className="text-gray-secondary text-poppins text-xs">
							Please access the following links and grant admin consent for the
							specified applications. This step is necessary to ensure that the
							applications function correctly within your tenant.
						</div>
					</div>
				</div>

				<div className="mt-5">
					{appLinks.map((appLink, index) => {
						return (
							<div className="mb-2" key={appLink.link}>
								<div className="flex items-center justify-between">
									<p>{appLink.name}</p>
									<div className="flex items-center">
										<Button
											onClick={() => {
												copyLink(appLink.link)
											}}
											variant="outline"
											size="icon"
										>
											{copied[appLink.link] ? "Copied!" : <Copy />}
										</Button>
										<Button
											variant="outline"
											size="icon"
											className="ml-3"
											asChild
										>
											<Link to={appLink.link} target="_blank">
												<ExternalLink />
											</Link>
										</Button>
									</div>
								</div>
								{index === 0 ? <Separator className="my-3" /> : null}
							</div>
						)
					})}
				</div>
				<div className="mt-6 flex items-center justify-center">
					<Button
						variant="outline"
						onClick={() => {
							navigate("/login")
						}}
					>
						Continue <ArrowRightIcon />
					</Button>
				</div>
			</div>
		</>
	)
}

export default ApplicationConsent
