import { useSelectedOrg } from "@/components/Nav/useSelectedOrg"
import { Button } from "@/components/ui/button"
import { Skeleton } from "@/components/ui/skeleton"
import NotFound from "@/pages/404"
import { trpc } from "@/trpc"
import { useInbox } from "@trycourier/react-hooks"
import { CourierProvider } from "@trycourier/react-provider"
import moment from "moment"
import { useEffect, useRef } from "react"
import { LuDownload } from "react-icons/lu"
import ReactMarkdown from "react-markdown"
import { useParams } from "react-router"
import { useReactToPrint } from "react-to-print"
import { toast } from "react-toastify"
import remarkGfm from "remark-gfm"

const ReportsDetailsSkeleton = () => {
	return (
		<div className="flex h-full flex-col gap-4">
			<div className="flex items-center justify-between">
				<div className="flex flex-col gap-2">
					<Skeleton className="h-8 w-40" />
					<Skeleton className="h-4 w-32" />
				</div>
				<Button disabled variant="outline">
					<LuDownload className="mr-2" />
					Download PDF
				</Button>
			</div>
			<Skeleton className="w-full grow" />
		</div>
	)
}

export const ReportDetails: React.FC = () => {
	const { messageId } = useParams<{ messageId: string }>()
	const organization = useSelectedOrg()

	const { data, isLoading } =
		trpc.reporting.getInboxAuthorizationToken.useQuery(undefined, {
			enabled: !!organization?.id,
		})

	return (
		<div className="flex h-screen w-full justify-center">
			<div className="font-inter w-3/5 py-8">
				<h1 className="font-500 font-inter text-foreground mb-8 flex items-center gap-2 text-2xl leading-8">
					Report details
				</h1>
				{isLoading ? (
					<ReportsDetailsSkeleton />
				) : (
					<CourierProvider
						userId={organization?.id ?? ""}
						clientKey={import.meta.env.VITE_COURIER_CLIENT_KEY}
						authorization={data?.token ?? ""}
					>
						<ReportPreviewContent messageId={messageId} isLoading={isLoading} />
					</CourierProvider>
				)}
			</div>
		</div>
	)
}

const markdownClassNames =
	"overflow-y-auto bg-white py-4 px-8 rounded-md prose prose-sm prose-hr:my-4 prose-h1:font-semibold prose-h1:text-2xl prose-h1:mb-4 prose-h2:mt-2 prose-h2:mb-4 prose-h3:my-2 prose-h2:font-semibold prose-code:before:content-none prose-code:after:content-none prose-code:p-1 prose-code:bg-muted max-w-none"

const ReportPreviewContent: React.FC<{
	messageId: string | undefined
	isLoading: boolean
}> = ({ messageId, isLoading }) => {
	const inbox = useInbox()

	// Add ref for the markdown content
	const markdownRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (messageId) {
			inbox.fetchMessages()
		}
	}, [messageId])

	const message = inbox.messages?.find(
		message => message.messageId === messageId,
	)

	const print = useReactToPrint({
		contentRef: markdownRef,
		bodyClass: "p-12",
		documentTitle: `Report ${message?.title || "untitled"}`,
	})

	// Add download handler
	const handleDownload = () => {
		try {
			if (!markdownRef.current) return
			print()
		} catch (error) {
			console.error(error)
			toast.error("Failed to download PDF")
		}
	}

	const isMessageLoading = isLoading || inbox.isLoading

	if (!isMessageLoading && !message) {
		return (
			<div className="flex h-full flex-col">
				<NotFound />
			</div>
		)
	}

	return (
		<div className="flex h-full flex-col">
			<div className="mb-4 flex items-center justify-between">
				{!isMessageLoading && (
					<div className="flex flex-col gap-2">
						<span className="text-xl font-semibold">{message?.title}</span>{" "}
						<span className="text-muted-foreground">
							Sent {moment(message?.created).fromNow()}
						</span>
					</div>
				)}
				{isMessageLoading && (
					<div className="flex flex-col gap-2">
						<Skeleton className="h-8 w-40" />
						<Skeleton className="h-4 w-32" />
					</div>
				)}
				<Button
					disabled={isMessageLoading}
					variant="outline"
					onClick={handleDownload}
				>
					<LuDownload className="mr-2" />
					Download PDF
				</Button>
			</div>
			{isMessageLoading && <Skeleton className="h-full w-full" />}
			{!isMessageLoading && (
				<div className="overflow-y-auto" ref={markdownRef}>
					<ReactMarkdown
						className={markdownClassNames}
						remarkPlugins={[remarkGfm]}
						components={{
							// Sanitize links
							a: ({ href, ...props }) => (
								<a
									href={href}
									target="_blank"
									rel="noopener noreferrer"
									{...props}
								/>
							),
						}}
					>
						{message?.preview}
					</ReactMarkdown>
				</div>
			)}
		</div>
	)
}
