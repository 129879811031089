import { Button } from "@/components/ui/button"
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog"
import { Book, CircleCheck } from "lucide-react"
import type { FC } from "react"
import { Link } from "react-router-dom"
import { OnboardingWizardCtx } from "../ctx"
import type { SupportedProviders } from "../types"
import { useInstallationSteps } from "../useInstallationSteps"

interface Props {
	open: boolean
	service: SupportedProviders
	onOpenChange: (open: boolean) => void
	onComplete?: () => void
	onClose?: () => void
	trigger?: JSX.Element | null
}

export const OnboardingModal: FC<Props> = ({
	service,
	open,
	onClose,
	onComplete,
	onOpenChange,
	trigger,
}) => {
	const {
		config,
		currentStep,
		stepCursor,
		handleNext,
		handlePrevious,
		isLastStep,
		isFirstStep,
		setConfig,
	} = useInstallationSteps({ service })

	const isClickToOpen = Boolean(trigger)

	return (
		<OnboardingWizardCtx.Provider
			value={{
				config,
				setConfig,
			}}
		>
			<Dialog
				open={open}
				onOpenChange={open => {
					onOpenChange(open)
					if (!open) {
						onClose?.()
					}
				}}
			>
				{isClickToOpen && <DialogTrigger asChild>{trigger}</DialogTrigger>}
				<DialogContent className="min-w-[50%]">
					<div className="relative flex flex-col gap-6">
						<div className="flex flex-col gap-1">
							<span className="text-2xl font-semibold">{config.title}</span>
							<span className="text-muted-foreground">
								{config.description}
							</span>
						</div>
						<div className="flex flex-col gap-1">
							<span className="text-crb-primary font-semibold">
								STEP {stepCursor + 1}/{config.steps.length}
							</span>
							<span className="text-base font-semibold">
								{currentStep?.title}
							</span>
							<span className="text-muted-foreground h-16 2xl:h-12">
								{currentStep?.description}
							</span>
						</div>
						{currentStep && <>{currentStep.renderElement()}</>}
						<div className="flex flex-row items-center justify-between">
							<Button variant="outline" asChild>
								<Link
									target="_blank"
									to="https://docs.coderabbit.ai/platforms/bitbucket-cloud"
									rel="noreferrer"
								>
									<Book size={16} className="mr-2" />
									Documentation
								</Link>
							</Button>
							<div className="flex flex-row items-center gap-4">
								{!isFirstStep && (
									<Button
										variant="secondary"
										onClick={handlePrevious}
										className="bg-accent hover:bg-accent"
									>
										Back
									</Button>
								)}
								{!isLastStep && (
									<Button
										onClick={handleNext}
										disabled={
											currentStep?.optional ? false : !currentStep?.isComplete
										}
									>
										Next step
									</Button>
								)}
								{isLastStep && (
									<Button
										onClick={() => {
											onComplete?.()
										}}
										className="gap-2"
									>
										<CircleCheck className="stroke-primary-foreground size-4" />
										Understood
									</Button>
								)}
							</div>
						</div>
					</div>
				</DialogContent>
			</Dialog>
		</OnboardingWizardCtx.Provider>
	)
}
