import { Copy } from "lucide-react"

import Loader from "@/components/Loader/Loader"
import { Button } from "@/components/ui/button"
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { trpc } from "@/trpc"
import { zodResolver } from "@hookform/resolvers/zod"
import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import z from "zod"

const formSchema = z.object({
	name: z.string().min(2, {
		message: "Name must be at least 2 characters long",
	}),
})

interface CreateApiKeyModalProps {
	readonly open: boolean
	readonly onOpenChange: (open: boolean) => void
	readonly onCreateKey?: () => void
}

const CreateApiKeyModal: React.FC<CreateApiKeyModalProps> = ({
	open,
	onOpenChange,
	onCreateKey,
}) => {
	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			name: "",
		},
	})

	const [newApiKey, setNewApiKey] = React.useState<string>()

	const createApiKey = trpc.apiKeys.createApiKey.useMutation({
		onSuccess: ({ data: apiKey }) => {
			setNewApiKey(apiKey)
			onCreateKey?.()
			toast.success("API Key created successfully")
		},
	})

	useEffect(() => {
		if (createApiKey.error) {
			toast.error("Failed to create the API key: " + createApiKey.error.message)
		}
	}, [createApiKey.error])

	const handleSubmit = (data: z.infer<typeof formSchema>) => {
		createApiKey.mutate(data.name)
	}

	useEffect(() => {
		if (open) {
			setNewApiKey(undefined)
			form.reset()
		}
	}, [open])

	const isLoading = createApiKey.isLoading

	return (
		<Dialog open={open} onOpenChange={onOpenChange}>
			<DialogContent
				onPointerDownOutside={e => {
					e.preventDefault()
				}}
				className="sm:max-w-md"
			>
				{isLoading && <Loader />}
				<DialogHeader>
					<DialogTitle>Create API Key</DialogTitle>
				</DialogHeader>

				{newApiKey && (
					<div className="flex space-x-2">
						<div className="grid flex-1 gap-1">
							<Label htmlFor="link" className="sr-only">
								New API Key
							</Label>
							<Input id="link" value={newApiKey} readOnly />
							<div className="text-muted-foreground mx-1 text-xs">
								Copy the key now, you won't be able to see it later
							</div>
						</div>
						<Button
							type="submit"
							size="sm"
							className="mt-0.5 px-3"
							onClick={() => {
								navigator.clipboard
									.writeText(newApiKey)
									.then(() => {
										toast.success("API Key copied to clipboard")
									})
									.catch(error => {
										toast.error("Failed to copy API Key: " + error)
									})
							}}
						>
							<span className="sr-only">Copy</span>
							<Copy className="h-4 w-4" />
						</Button>
					</div>
				)}

				{!newApiKey && (
					<form onSubmit={form.handleSubmit(handleSubmit)}>
						<div className="pb-4">
							<Label htmlFor="name" className="font-inter">
								API Key name
							</Label>
							<Input
								id="name"
								placeholder="API Key name here"
								{...form.register("name")}
								className={`mt-0.5 ${
									form.formState.errors.name
										? "border-red-500"
										: "border-gray-300"
								}`}
								autoComplete="off"
							/>
							{form.formState.errors.name && (
								<p className="font-inter text-destructive mt-2 text-sm">
									{form.formState.errors.name.message}
								</p>
							)}
						</div>
						<DialogFooter>
							<Button
								className="font-inter px-4 py-2 font-semibold"
								type="submit"
							>
								Create API Key
							</Button>
						</DialogFooter>
					</form>
				)}

				{newApiKey && (
					<DialogFooter className="sm:justify-end">
						<DialogClose asChild>
							<Button type="button" variant="secondary">
								Close
							</Button>
						</DialogClose>
					</DialogFooter>
				)}
			</DialogContent>
		</Dialog>
	)
}

export default CreateApiKeyModal
