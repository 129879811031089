import type { PromptTemplates } from "@/pages/Settings/CustomReporting/ReportingComponents/prompt"
import type { PROVIDERS } from "@/utils/providers.js"
import type {
	IntegrationData,
	IssueIntegration,
	OrganizationData,
	UserData,
} from "./index.js"

export interface GHHandlerGetAllOrgsResp {
	readonly data: OrganizationData[]
}

interface GHHandlerGetIntegrationResp extends IntegrationData {
	readonly is_integration_active: boolean
	readonly webhook_id?: number | null
	readonly created_at?: string
	readonly updated_at?: string
}
export type GHHandlerGetIntegrationsResp = GHHandlerGetIntegrationResp[]

export interface GHHandlerGetJiraRefreshTokenResp
	extends Omit<IntegrationData, "id" | "owner_id"> {
	readonly service: IssueIntegration
}

export interface GHHandlerGetLinearRefreshTokenResp
	extends Omit<IntegrationData, "id" | "owner_id"> {
	readonly service: IssueIntegration
}

export interface GHHandlerSelfHostedJiraBody {
	readonly host_url: string
	readonly pat: string
}

export type GHHandlerSaveIntegrationsBody =
	| Omit<IntegrationData, "id">
	| (GHHandlerSelfHostedJiraBody & {
			readonly service: "Jira"
			readonly owner_id: string
	  })

export interface GHHandlerGetAllMembersResp {
	readonly num_seats: number
	readonly usersData: UserData[]
	readonly subscriptionId: number
	readonly joinImmediate: boolean
}

export interface RepoDetails {
	readonly name: string
	readonly id: string
	readonly private: boolean
	readonly isEnabled: boolean
	readonly orgId: string
}

export interface GHHandlerGetAllReposResp {
	readonly repos: RepoDetails[]
	readonly total: number
	readonly message?: string
}

export interface GHHandlerSaveSlackConnectionResp {
	readonly message: string
}

export interface GHHandlerSaveDiscordConnectionResp {
	readonly message: string
}

export interface PlatformChannel {
	readonly id: string
	readonly name: string
}

export interface GHHandlerListReportsResp {
	readonly reports: {
		readonly id: string
		readonly name: string
		readonly time: string
		readonly timezone: string
		readonly disabled: boolean
		readonly type: "MONTHLY" | "ONDEMAND" | "WEEKLY"
		readonly lastRunDate: string
		readonly created_at: string
		readonly updated_at: string
	}[]
}

export interface GHHandlerDeleteReportsResp {
	readonly message: string
	readonly successfulDeletions: number
	readonly failedDeletions: number
}

export interface Platform {
	readonly platform: SchedulePlatform
	readonly channel: string
}

export type PlatformSelection = Platform & { selected: boolean }

export interface GHHandlerGetReportingSettingsResp
	extends Omit<BaseSchedule, "channel" | "platform" | "scheduleStatus"> {
	readonly platforms: Platform[]
	readonly scheduleStatus: boolean
}

export const ScheduleParameters = [
	"REPOSITORY",
	"LABEL",
	"TEAM",
	"USER",
	"SOURCEBRANCH",
	"TARGETBRANCH",
] as const
export type ScheduleParameter = (typeof ScheduleParameters)[number]
export const ScheduleOperators = ["IN", "ALL"] as const
export type ScheduleOperator = (typeof ScheduleOperators)[number]
export const ScheduleGroups = ["NONE", ...ScheduleParameters] as const
export type ScheduleGroup = (typeof ScheduleGroups)[number]
export const SchedulePlatforms = ["SLACK", "DISCORD", "TEAMS"] as const
export type SchedulePlatform = (typeof SchedulePlatforms)[number]
export type ScheduleWeekFrequency = "1" | "2" | "3"
export type ScheduleWeekFrequencyInt = 1 | 2 | 3
export type ScheduleWeekDay = 0 | 1 | 2 | 3 | 4 | 5 | 6
export type ScheduleMonthDay =
	| 1
	| 2
	| 3
	| 4
	| 5
	| 6
	| 7
	| 8
	| 9
	| 10
	| 11
	| 12
	| 13
	| 14
	| 15
	| 16
	| 17
	| 18
	| 19
	| 20
	| 21
	| 22
	| 23
	| 24
	| 25
	| 26
	| 27
	| 28
	| 29
	| 30
	| 31

export type PromptTemplate =
	| "Custom"
	| "Daily Standup Report"
	| "Release Notes"
	| "Sprint Report"

export const frequencyOptions = ["Days of Week", "Days of Month"] as const
export type FrequencyOption = (typeof frequencyOptions)[number]

export interface BaseSchedule {
	readonly id?: string | undefined
	readonly name: string
	readonly time: string
	readonly type: "MONTHLY" | "WEEKLY"
	readonly frequency?: ScheduleWeekFrequency | undefined
	readonly days: ScheduleMonthDay[] | ScheduleWeekDay[]
	readonly timezone: string
	readonly parameters: {
		readonly parameter: ScheduleParameter
		readonly operator: ScheduleOperator
		readonly values: string[]
	}[]
	readonly group: ScheduleGroup
	readonly subgroup: ScheduleGroup | undefined
	readonly platform: SchedulePlatform | undefined
	readonly channel: string | undefined
	readonly emails: string[]
	readonly promptTemplate: string
	readonly prompt?: string | null
	readonly scheduleStatus?: undefined
}

export type GHHandlerSaveReportingSettingsBody =
	| BaseSchedule
	| {
			readonly id: BaseSchedule["id"]
			readonly scheduleStatus: boolean
	  }

export interface GHHandlerSaveReportingSettingsResp {
	readonly message: string
}

export type GHHandlerRemoveSlackConnectionBody = Record<string, never>

export interface GHHandlerRemoveSlackConnectionResp {
	readonly message: string
}

export interface GHHandlerGetSlackConnectionResp {
	readonly message: string
	readonly channels: PlatformChannel[]
}

export type GHHandlerRemoveDiscordConnectionBody = Record<string, never>

export interface GHHandlerRemoveDiscordConnectionResp {
	readonly message: string
}

export interface GHHandlerGetDiscordConnectionResp {
	readonly message: string
	readonly channels: PlatformChannel[]
}

export interface GHHandlerGetReportingPromptsResp {
	readonly promptTemplateList: string[]
	readonly promptTemplates: PromptTemplates
}

export interface GHHandlerAppStatusResp {
	readonly message: string
}

export interface GHHandlerSelfHostedInstanceAddResp {
	readonly success: boolean
	readonly data?:
		| {
				readonly clientId: string
				readonly hostURL: string
				readonly scope: string
				readonly redirectURI: string
		  }
		| undefined
	readonly message?: string | undefined
}

interface NewSelfHostedGitHub {
	readonly hostURL: string
	readonly clientId: string
	readonly clientSecret: string
	readonly provider: typeof PROVIDERS.GITHUB_SELF_HOSTED
	readonly updateInstance?: boolean
	readonly ghAppId: string
	readonly ghAppClientId: string
	readonly ghAppClientSecret: string
	readonly ghAppPrivateKey: string
	readonly ghAppWebhookSecret: string
}

interface NewSelfHostedGitLab {
	readonly hostURL: string
	readonly userToken: string
	readonly clientId: string
	readonly clientSecret: string
	readonly adminToken: string
	readonly updateInstance?: boolean
	readonly provider: typeof PROVIDERS.GITLAB_SELF_HOSTED
}

export type GHHandlerSelfHostedInstanceAddBody =
	| NewSelfHostedGitHub
	| NewSelfHostedGitLab

export interface GHHandlerSelfHostedInstanceGetResp {
	readonly success: boolean
	readonly data: {
		readonly clientId: string
		readonly hostURL: string
		readonly scope: string
		readonly redirectURI: string
	}
}

export interface GHHandlerDisconnectIntegrationResp {
	readonly message: string
}

export interface GitLabSubgroup {
	readonly organization_name: string
	readonly organization_full_path: string
	readonly provider_organization_id: number
}

export interface GHHandlerGetSubgroupsResp {
	readonly id: number
	readonly web_url: string
	readonly name: string
	readonly path: string
	readonly visibility: string
	readonly parent_id?: number | null
}

export interface Label {
	readonly id: string
	readonly name: string
	readonly color: string
}

export interface GHHandlerGetLabelsResp {
	readonly message: string
	readonly labels: Label[]
}

export interface Team {
	readonly id: string
	readonly name: string
}

export interface GHHandlerGetTeamsResp {
	readonly message: string
	readonly teams: Team[]
}

export interface TokenResponse {
	readonly service: IssueIntegration
	readonly service_id?: string
	readonly host_url?: string
	readonly access_token_encrypted: string
	readonly access_token_tag: string
	readonly access_token_iv: string
	readonly access_token_validity: string
	readonly refresh_token_encrypted?: string
	readonly refresh_token_tag?: string
	readonly refresh_token_iv?: string
}
