import type { ConfigSettings } from "@coderabbitai/schemas"
import { isEqual } from "lodash"
import { useEffect, useState, type FC } from "react"

interface SaveConfigMessageProps {
	initialConfig: ConfigSettings | null
	config: ConfigSettings
}

export const SaveConfigMessage: FC<SaveConfigMessageProps> = ({
	config,
	initialConfig,
}) => {
	const [showMessage, setShowMessage] = useState(false)

	useEffect(() => {
		let timer: ReturnType<typeof setTimeout>

		if (!initialConfig) {
			setShowMessage(false)
			return () => {
				clearTimeout(timer)
			}
		}

		// Only show message if there are actual changes
		const hasChanges = !isEqual(
			structuredClone(config), // Deep clone to avoid reference issues
			structuredClone(initialConfig),
		)

		if (hasChanges && !showMessage) {
			timer = setTimeout(() => {
				setShowMessage(true)
			}, 1000)
		} else if (!hasChanges && showMessage) {
			timer = setTimeout(() => {
				setShowMessage(false)
			}, 1000)
		}

		return () => {
			clearTimeout(timer)
		}
	}, [config, initialConfig, showMessage])

	return (
		<>
			{showMessage && (
				<div
					className="font-poppins border-l-4 border-yellow-500 bg-yellow-100 px-4 py-2 text-yellow-700"
					role="alert"
				>
					<p className="text-sm">
						You have unsaved changes. Please apply the changes to save them.
					</p>
				</div>
			)}
		</>
	)
}
