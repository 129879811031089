import { Button } from "@/components/ui/button.tsx"
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form.tsx"
import { Input } from "@/components/ui/input.tsx"
import { Label } from "@/components/ui/label.tsx"
import { trpc } from "@/trpc.ts"
import { zodResolver } from "@hookform/resolvers/zod"
import { Book } from "lucide-react"
import React from "react"
import { useForm } from "react-hook-form"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import z from "zod"
import Loader from "../../../components/Loader/Loader"

const formSchema = z.object({
	userToken: z.string().min(1, {
		message: "User token is required.",
	}),
})

const AzureUser: React.FC = () => {
	const azureUserQuery = trpc.providers.getOrgUser.useQuery()
	const azureUser = azureUserQuery.data

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			userToken: "",
		},
	})

	const saveAzureUserQuery = trpc.providers.saveOrgUser.useMutation({
		onSuccess: resp => {
			if (resp.success) {
				form.reset({
					userToken: "",
				})
				azureUserQuery.remove()
				void azureUserQuery.refetch()
			} else {
				toast.error("Failed to save AzureDevOps user details")
			}
		},
		onError: () => {
			toast.error("Failed to save AzureDevOps user details")
		},
	})

	const onSubmit = (values: z.infer<typeof formSchema>) => {
		saveAzureUserQuery.mutate({
			userToken: values.userToken,
		})
	}

	return (
		<>
			{azureUserQuery.isLoading ? (
				<Loader size="small" />
			) : (
				<div className="container mx-auto px-8 pt-7 pb-2">
					<div className="container mx-auto">
						<div>
							<div className="flex justify-between">
								<div>
									<div className="font-500 font-inter text-foreground mb-2 text-xl leading-8">
										AzureDevOps user
									</div>
									<div className="w-full">
										<div className="flex w-full flex-row justify-between">
											<div className="w-1/2">
												<p className="text-muted-foreground mb-3 text-sm font-light">
													This integration enables CodeRabbit to access the
													AzureDevOps API of the specified AzureDevOps
													organization using an authenticated Personal Access
													Token. This token can be provided by a user from your
													organization.
												</p>
											</div>
										</div>
									</div>
								</div>
								<Button variant="outline" size="sm" asChild>
									<Link
										to="https://docs.coderabbit.ai/integrations/saas-gitlab"
										target="_blank"
									>
										<Book size={16} className="mr-2" />
										Documentation
									</Link>
								</Button>
							</div>

							<div className="mt-3 flex justify-between rounded-md border p-4">
								<div>
									<div className="font-500 font-inter text-foreground leading-5">
										Settings
									</div>
									<p className="text-muted-foreground text-sm font-light">
										Configure AzureDevOps user
									</p>
								</div>
								<div className="w-1/2">
									<Form {...form}>
										<form
											onSubmit={e => {
												e.preventDefault()
											}}
											className="space-y-4"
										>
											<div className="space-x-2 p-2">
												<div className="flex items-center">
													<Label htmlFor="organization-user" className={"ml-6"}>
														Organization user (User ID:{" "}
														{azureUser ? (
															<span className="text-crb-primary">
																{azureUser}
															</span>
														) : (
															"None"
														)}
														)
													</Label>
												</div>
												<p className="text-muted-foreground mt-2 pl-4 text-xs">
													To interact with the AzureDevOps API, a Personal
													Access Token is required. This token can be generated
													by creating a Personal Access Token from one of your
													existing users.
												</p>
											</div>
											<div className="m-0 pl-8">
												<FormField
													control={form.control}
													name="userToken"
													render={({ field }) => (
														<FormItem>
															<FormLabel className="text-sm">
																Personal Access Token
															</FormLabel>
															<FormControl>
																<Input
																	placeholder="Enter token..."
																	{...field}
																/>
															</FormControl>
															<FormMessage />
														</FormItem>
													)}
												/>
												<Button
													type="submit"
													className="mt-3"
													size="sm"
													onClick={async () => {
														await form.handleSubmit(onSubmit)()
													}}
													disabled={saveAzureUserQuery.isLoading}
												>
													Save
												</Button>
											</div>
										</form>
									</Form>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default AzureUser
