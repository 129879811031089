import type { PgLearning } from "@/typings/coderabbitHandler"
import { PROVIDERS } from "@/utils/providers"
import { getSelectedOrg } from "@/utils/utils"
import { camelCase } from "lodash"
import {
	GITHUB_SELF_HOSTED_LS_KEY,
	GITLAB_SELF_HOSTED_LS_KEY,
} from "../Login/selfhosted"
import type { ChipsInfo } from "./components/LearningFilter"

type CreateCommentLinkProps = Pick<
	PgLearning,
	"pull_request" | "repo_name" | "repo_owner" | "top_level_comment_id"
>

export function createCommentLink({
	top_level_comment_id,
	pull_request,
	repo_owner,
	repo_name,
}: CreateCommentLinkProps) {
	if (!top_level_comment_id || !pull_request) {
		return null
	}

	const account = getSelectedOrg()

	switch (account?.provider) {
		case PROVIDERS.GITHUB:
			return `https://github.com/${repo_owner}/${repo_name}/pull/${pull_request}#discussion_r${top_level_comment_id}`
		case PROVIDERS.GITHUB_SELF_HOSTED: {
			const json = localStorage.getItem(GITHUB_SELF_HOSTED_LS_KEY)
			const githubUrl = json ? JSON.parse(json).url : ""
			if (!githubUrl) return null
			return `https://${githubUrl}/${repo_owner}/${repo_name}/pull/${pull_request}#discussion_r${top_level_comment_id}`
		}
		case PROVIDERS.GITLAB:
			return `https://gitlab.com/${repo_owner}/${repo_name}/-/merge_requests/${pull_request}#note_${top_level_comment_id}`
		case PROVIDERS.GITLAB_SELF_HOSTED: {
			const json = localStorage.getItem(GITLAB_SELF_HOSTED_LS_KEY)
			const gitlabUrl = json ? JSON.parse(json).url : ""
			if (!gitlabUrl) return null
			return `https://${gitlabUrl}/${repo_owner}/${repo_name}/-/merge_requests/${pull_request}#note_${top_level_comment_id}`
		}
		case PROVIDERS.BITBUCKET:
			return `https://bitbucket.org/${repo_owner}/${repo_name}/pull-requests/${pull_request}/comments#${top_level_comment_id}`
		case PROVIDERS.AZURE_DEVOPS:
			return `https://dev.azure.com/${repo_owner}/${repo_name}/_git/${repo_name}/pullrequest/${pull_request}/_workitems/edit/${top_level_comment_id}`
		default:
			return null
	}
}

export function createPineconeFilter(chips: ChipsInfo[]) {
	return chips.reduce<Record<string, { $eq: number | string }>>(
		(acc, { key, value }) => {
			const camelCaseKey = camelCase(key)
			acc[camelCaseKey] = { $eq: value }
			return acc
		},
		{},
	)
}
