import { useCallback, useEffect, useMemo, useState } from "react"
import { getConfig } from "./config"
import type { SupportedProviders } from "./types"

export const useInstallationSteps = ({
	service,
}: {
	service: SupportedProviders
}) => {
	const [config, setConfig] = useState(getConfig({ service }))
	const [currentStep, setCurrentStep] = useState(config.steps[0])

	useEffect(() => {
		const currentStepInConfig = config.steps.find(
			step => step.id === currentStep?.id,
		)
		if (currentStepInConfig) {
			setCurrentStep(currentStepInConfig)
		}
	}, [config, currentStep])

	const steps = config.steps

	const stepCursor = steps.findIndex(step => step.id === currentStep?.id)

	const handleNext = useCallback(() => {
		const index = steps.findIndex(step => step.id === currentStep?.id)

		if (index + 1 < steps.length) {
			setCurrentStep(steps[index + 1])
		}
	}, [currentStep, steps])

	const handlePrevious = useCallback(() => {
		const index = steps.findIndex(step => step.id === currentStep?.id)

		if (index - 1 >= 0) {
			setCurrentStep(steps[index - 1])
		}
	}, [currentStep, steps])

	const isLastStep = useMemo(
		() =>
			steps.findIndex(step => step.id === currentStep?.id) === steps.length - 1,
		[steps, currentStep],
	)

	const isFirstStep = useMemo(
		() => steps.findIndex(step => step.id === currentStep?.id) === 0,
		[steps, currentStep],
	)

	const reset = () => {
		setCurrentStep(steps[0])
	}

	return {
		currentStep,
		handleNext,
		handlePrevious,
		config,
		isLastStep,
		isFirstStep,
		stepCursor,
		reset,
		setConfig,
	}
}
