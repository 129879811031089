import Loader from "@/components/Loader/Loader"
import { Button } from "@/components/ui/button"
import { trpc } from "@/trpc"
import type { OrganizationData } from "@/typings"
import type {
	CRHandlerGetReposSettingsResp,
	CRHandlerOrgLevelSettingsResp,
} from "@/typings/coderabbitHandler"
import { getSelectedOrg } from "@/utils/utils"
import type { ConfigSettings } from "@coderabbitai/schemas"
import type { AxiosError } from "axios"
import axios from "axios"
import { noop } from "lodash"
import type {
	Dispatch,
	FC,
	MutableRefObject,
	PropsWithChildren,
	SetStateAction,
} from "react"
import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useReducer,
	useRef,
} from "react"
import { useForm, type UseFormReturn } from "react-hook-form"
import { useNavigate, useParams } from "react-router"
import type { CustomWidgetsProps } from "../schemaForm/types"
import { configReducer, initialConfigState } from "./configReducer"

interface FormValues {
	useOrgSettings: boolean
	dataOptOut: boolean
}

interface ModeState {
	isRepo: boolean
	orgId: string | null
	repoId: string | null
}

interface APICallState {
	isLoading: boolean
	isError: boolean
	error?: AxiosError
}

interface ConfigCtx extends CustomWidgetsProps, APICallState {
	checkIfRepoSettings: () => boolean
	config: ConfigSettings | null
	initialConfig: MutableRefObject<ConfigSettings | null>
	setConfig: Dispatch<SetStateAction<ConfigSettings | null>>
	setError: Dispatch<SetStateAction<boolean>>
	setRepoSettingsEnabled: Dispatch<SetStateAction<boolean>>
	setDoesOrgSettingsExist: Dispatch<SetStateAction<boolean>>
	setIsDefaultSettings: Dispatch<SetStateAction<boolean>>
	selectedOrg: OrganizationData | null
	repo: string | null
	id: string | undefined
	doesOrgSettingsExist: boolean
	form: UseFormReturn<FormValues>
	repoSettingsQuery: ReturnType<
		typeof trpc.repositorySettings.getSettings.useQuery
	>
	isSettingsLoaded: boolean
	setIsSettingsLoaded: Dispatch<SetStateAction<boolean>>
}

const defaultConfigCtx: ConfigCtx = {
	isRepoSettings: false,
	repoSettingsEnabled: false,
	isDefaultSettings: false,
	checkIfRepoSettings: () => false,
	config: null,
	initialConfig: { current: null },
	setConfig: noop,
	isLoading: false,
	isError: false,
	setRepoSettingsEnabled: noop,
	setDoesOrgSettingsExist: noop,
	setIsDefaultSettings: noop,
	selectedOrg: null,
	setError: noop,
	repo: null,
	id: undefined,
	doesOrgSettingsExist: false,
	form: {} as ConfigCtx["form"],
	repoSettingsQuery: {} as ConfigCtx["repoSettingsQuery"],
	isSettingsLoaded: false,
	setIsSettingsLoaded: noop,
}

export const ConfigContext = createContext<ConfigCtx>(defaultConfigCtx)
export function useConfigCtx() {
	return useContext(ConfigContext)
}

export const ConfigProvider: FC<PropsWithChildren> = ({ children }) => {
	const navigate = useNavigate()
	const selectedOrg = getSelectedOrg()
	const { id } = useParams()

	const isRepoSettings = useMemo(() => !!id, [id])
	const repo = useMemo(
		() => new URLSearchParams(location.search).get("repo"),
		[location.search],
	)

	// Initialize state with useReducer
	const [state, dispatch] = useReducer(configReducer, initialConfigState)

	const initialConfig = useRef<ConfigSettings | null>(null)
	const isChangingMode = useRef<string | null>(null)
	const previousMode = useRef<ModeState>({
		isRepo: false,
		orgId: null,
		repoId: null,
	})

	const form = useForm<FormValues>({
		mode: "onChange",
		defaultValues: {
			useOrgSettings: false,
			dataOptOut: false,
		},
	})

	const checkIfRepoSettings = useCallback(
		() =>
			isRepoSettings
				? !state.repoSettingsEnabled && !state.isDefaultSettings
				: false,
		[state.isDefaultSettings, isRepoSettings, state.repoSettingsEnabled],
	)

	const repoSettingsQuery = trpc.repositorySettings.getSettings.useQuery(
		{ repo_id: id || "" },
		{
			enabled: isRepoSettings && !!id,
			retry: 2,
			retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000),
			onError: () => {
				dispatch({ type: "SET_ERROR", payload: true })
				dispatch({ type: "SET_SETTINGS_LOADED", payload: true })
			},
		},
	)

	const resetState = useCallback(() => {
		dispatch({ type: "RESET_STATE" })
		isChangingMode.current = null
	}, [])

	const handleOrgSettingsResponse = useCallback(
		(response: CRHandlerOrgLevelSettingsResp) => {
			dispatch({ type: "SET_CONFIG", payload: response.data.settings })
			initialConfig.current = response.data.settings
			dispatch({ type: "SET_ORG_SETTINGS_EXIST", payload: true })
			form.reset({
				useOrgSettings: false,
				dataOptOut: !response.data.data_opt_out,
			})
			dispatch({ type: "SET_SETTINGS_LOADED", payload: true })
		},
		[form],
	)

	const updateFormState = useCallback(
		(useOrgSettings: boolean) => {
			form.reset(
				{
					useOrgSettings,
					dataOptOut: false,
				},
				{
					keepDefaultValues: true,
					keepDirty: false,
				},
			)
		},
		[form],
	)

	const updateStateFromResponse = useCallback(
		(data: CRHandlerGetReposSettingsResp) => {
			const { settings, enabled = false } = data.data
			const isDefault = data.default

			const hasOrgSettings = !data.default
			dispatch({ type: "SET_ORG_SETTINGS_EXIST", payload: hasOrgSettings })

			dispatch({ type: "SET_DEFAULT_SETTINGS", payload: isDefault })
			const useOrgSettings = !enabled
			dispatch({ type: "SET_REPO_SETTINGS_ENABLED", payload: !useOrgSettings })
			dispatch({ type: "SET_CONFIG", payload: settings })
			initialConfig.current = settings

			return useOrgSettings
		},
		[],
	)

	const handleRepoSettingsResponse = useCallback(
		(data: CRHandlerGetReposSettingsResp) => {
			if (state.isSettingsLoaded) {
				return
			}

			const useOrgSettings = updateStateFromResponse(data)
			updateFormState(useOrgSettings)

			dispatch({ type: "SET_SETTINGS_LOADED", payload: true })
		},
		[state.isSettingsLoaded, updateStateFromResponse, updateFormState],
	)

	// Update the watch effect to use useOrgSettings
	useEffect(() => {
		const subscription = form.watch((value, { name }) => {
			if (name && name !== "useOrgSettings") {
				const { useOrgSettings = false } = value

				form.setValue("useOrgSettings", useOrgSettings, {
					shouldTouch: false,
					shouldDirty: false,
				})
			}
		})
		return () => {
			subscription.unsubscribe()
		}
	}, [form])

	const fetchOrgSettingsAndUpdateFormState = useCallback(async () => {
		const authToken = sessionStorage.getItem("accessToken")
		if (!authToken || !selectedOrg?.id) {
			if (!authToken) {
				sessionStorage.clear()
				navigate("/login")
			}
			return
		}

		try {
			const res = await axios.get<CRHandlerOrgLevelSettingsResp>(
				`${import.meta.env.VITE_CODERABBIT_FUNC_URL}/orgLevelSettings`,
				{
					headers: { Authorization: `Bearer ${authToken}` },
					params: { organization_id: selectedOrg.id },
				},
			)
			handleOrgSettingsResponse(res.data)
		} catch (err) {
			dispatch({ type: "SET_ERROR", payload: true })
			dispatch({ type: "SET_SETTINGS_LOADED", payload: true })
		}
	}, [selectedOrg?.id, navigate, handleOrgSettingsResponse])

	// Mode change effect
	useEffect(() => {
		const currentMode = {
			isRepo: isRepoSettings,
			orgId: selectedOrg?.id || null,
			repoId: id || null,
		}

		const isActualModeChange =
			currentMode.isRepo !== previousMode.current.isRepo ||
			currentMode.orgId !== previousMode.current.orgId

		if (isActualModeChange) {
			isChangingMode.current = `${currentMode.isRepo}-${currentMode.orgId}-${currentMode.repoId}`
			resetState()
		}

		previousMode.current = currentMode
	}, [isRepoSettings, selectedOrg?.id, id, resetState])

	// Data handling effect
	useEffect(() => {
		if (isRepoSettings && repoSettingsQuery.data && !state.isSettingsLoaded) {
			handleRepoSettingsResponse(
				repoSettingsQuery.data as CRHandlerGetReposSettingsResp,
			)
		} else if (!isRepoSettings && !state.isSettingsLoaded && selectedOrg?.id) {
			void fetchOrgSettingsAndUpdateFormState()
		}
	}, [
		isRepoSettings,
		repoSettingsQuery.data,
		state.isSettingsLoaded,
		selectedOrg?.id,
		handleRepoSettingsResponse,
		fetchOrgSettingsAndUpdateFormState,
	])

	const isLoading = useMemo(
		() =>
			!state.isSettingsLoaded ||
			!state.config ||
			(isRepoSettings && repoSettingsQuery.isLoading),
		[
			isRepoSettings,
			repoSettingsQuery.isLoading,
			state.config,
			state.isSettingsLoaded,
		],
	)

	if (state.isError) {
		return (
			<div className="flex flex-col items-center justify-center p-4 text-red-600">
				<div className="mb-2">Failed to load settings</div>
				<Button
					onClick={() => {
						dispatch({ type: "SET_ERROR", payload: false })
						dispatch({ type: "SET_SETTINGS_LOADED", payload: false })
						if (isRepoSettings) {
							void repoSettingsQuery.refetch()
						} else {
							void fetchOrgSettingsAndUpdateFormState()
						}
					}}
				>
					Try Again
				</Button>
			</div>
		)
	}

	if (isLoading) {
		return <Loader message="Loading settings..." size="small" />
	}

	return (
		<ConfigContext.Provider
			value={{
				isRepoSettings,
				repoSettingsEnabled: state.repoSettingsEnabled,
				isDefaultSettings: state.isDefaultSettings,
				checkIfRepoSettings,
				config: state.config,
				initialConfig,
				setConfig: config => {
					dispatch({ type: "SET_CONFIG", payload: config })
				},
				isLoading:
					!state.isSettingsLoaded ||
					(isRepoSettings && repoSettingsQuery.isLoading),
				isError: state.isError,
				setError: error => {
					dispatch({ type: "SET_ERROR", payload: error })
				},
				selectedOrg,
				repo,
				id,
				doesOrgSettingsExist: state.doesOrgSettingsExist,
				setRepoSettingsEnabled: enabled => {
					dispatch({ type: "SET_REPO_SETTINGS_ENABLED", payload: enabled })
				},
				setDoesOrgSettingsExist: exists => {
					dispatch({ type: "SET_ORG_SETTINGS_EXIST", payload: exists })
				},
				setIsDefaultSettings: isDefault => {
					dispatch({ type: "SET_DEFAULT_SETTINGS", payload: isDefault })
				},
				form,
				repoSettingsQuery,
				isSettingsLoaded: state.isSettingsLoaded,
				setIsSettingsLoaded: loaded => {
					dispatch({ type: "SET_SETTINGS_LOADED", payload: loaded })
				},
			}}
		>
			{children}
		</ConfigContext.Provider>
	)
}
