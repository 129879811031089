import { FormField } from "@/components/ui/form.tsx"
import { Switch } from "@/components/ui/switch.tsx"
import { FormControl } from "@mui/material"
import type { Control, FieldPath, FieldValues } from "react-hook-form"

export interface ToggleSwitchProps<
	T extends FieldValues,
	K extends FieldPath<T> = FieldPath<T>,
> {
	readonly label: string
	readonly checked?: boolean
	readonly text?: string
	readonly disabled?: boolean
	readonly name: K
	readonly control: Control<T>
	readonly onChange?: (newState: boolean) => void
}

const SettingsToggleSwitch = <T extends FieldValues>({
	label,
	text,
	name,
	disabled,
	control,
	onChange,
}: ToggleSwitchProps<T>) => {
	return (
		<div className="w-full">
			<div className="flex w-full flex-row justify-between">
				<div>
					<div
						className="font-500 font-inter leading-5 not-italic"
						id="label-email"
					>
						{label}
					</div>
					{text && (
						<p className="text-muted-foreground max-w-3xl pt-1 text-sm leading-[20px] font-light not-italic">
							{text}
						</p>
					)}
				</div>
				<div className="mt-4 ml-4 sm:col-span-2 sm:mt-0">
					<FormField
						control={control}
						name={name}
						render={({ field }) => (
							<FormControl>
								<Switch
									checked={field.value}
									disabled={disabled}
									onCheckedChange={checked => {
										field.onChange(checked)
										onChange?.(checked)
									}}
								/>
							</FormControl>
						)}
					/>
				</div>
			</div>
		</div>
	)
}

export default SettingsToggleSwitch
