import ApplicationConsent from "@/pages/Onboarding/ApplicationConsent.tsx"
import AzureUser from "@/pages/Settings/AzureUser/AzureUser.tsx"
import BitbucketUser from "@/pages/Settings/BitbucketUser/BitbucketUser.tsx"
import GitLabUser from "@/pages/Settings/GitlabUser/GitlabUser.tsx"
import { useContext, useMemo, type FC } from "react"
import { Navigate, useRoutes, type RouteObject } from "react-router-dom"
import ProtectedRoutes from "./ProtectedRoutes"
import { axiosInterceptor } from "./axios-interceptor"
import NavContainer from "./components/Nav/NavContainer"
import { useSelectedOrg } from "./components/Nav/useSelectedOrg"
import { useFirebaseCtx } from "./firebase"
import { GrafanaProvider } from "./grafana"
import { PlanContext } from "./main"
import NotFound from "./pages/404"
import Dashboard from "./pages/Dashboard"
import InstallationMessage from "./pages/InstallationMessage/InstallationMessage"
import Integrations from "./pages/Integrations/Integrations"
import { InviteSelfHostedAdmin } from "./pages/InviteSelfHostedAdmin"
import Login from "./pages/Login"
import GitHubSelfHostedLogin from "./pages/Login/GitHubSelfHostedLogin"
import GitLabSelfHostedLogin from "./pages/Login/GitLabSelfHostedLogin"
import { SignInWithEmailLink } from "./pages/Login/SignInWithEmailLink"
import { VSCodeExtensionAuth } from "./pages/Login/VSCodeExtensionAuth"
import Onboarding from "./pages/Onboarding"
import Profile from "./pages/Profile"
import ApiKeys from "./pages/Settings/ApiKeys/ApiKeys"
import Configure from "./pages/Settings/Configuration/Configure"
import DiscordAppInstall from "./pages/Settings/CustomReporting/Discord/DiscordAppInstall"
import NewRecurringReport from "./pages/Settings/CustomReporting/NewRecurringReport"
import RecurringReports from "./pages/Settings/CustomReporting/RecurringReports"
import { ReportDetails } from "./pages/Settings/CustomReporting/ReportDetails"
import SlackAppInstall from "./pages/Settings/CustomReporting/Slack/SlackAppInstall"
import RepositoryList from "./pages/Settings/Repository/RepositoryList"
import SeatManagement from "./pages/Settings/SeatManagement/SeatManagement"
import { Learnings } from "./pages/learnings"
import { UpgradeToProPlanMsg } from "./pages/upgrade-pro-plan-msg"
import { useProvider } from "./utils/providers"

const ENABLED_GRAFANA = import.meta.env.VITE_ENABLE_GRAFANA === "true"
axiosInterceptor()

function dashboardRouteElement(proPlanLock: boolean) {
	if (proPlanLock) {
		return <ProtectedRoutes Component={UpgradeToProPlanMsg} />
	}

	if (ENABLED_GRAFANA) {
		return (
			<GrafanaProvider>
				<ProtectedRoutes Component={Dashboard} />
			</GrafanaProvider>
		)
	}

	return <ProtectedRoutes Component={Dashboard} />
}

const App: FC = () => {
	const { isSelfHosted, isCRSelfHosted } = useProvider()
	const { isAuthenticated: isFirebaseUser } = useFirebaseCtx()
	const selectedOrg = useSelectedOrg()
	const { proPlanLock } = useContext(PlanContext)

	const routesWithNavContainer: RouteObject[] = useMemo(() => {
		let routes: RouteObject[] = [
			{
				path: "dashboard",
				element: dashboardRouteElement(proPlanLock),
			},
			{
				path: "integrations",
				element: (
					<ProtectedRoutes
						Component={proPlanLock ? UpgradeToProPlanMsg : Integrations}
					/>
				),
			},
			{
				path: "learnings",
				element: <ProtectedRoutes Component={Learnings} />,
			},
			{
				path: "settings/organization",
				element: <ProtectedRoutes Component={Configure} />,
			},
			{
				path: isSelfHosted
					? "settings/account/gitlab-user"
					: "settings/gitlab-user",
				element: <ProtectedRoutes Component={GitLabUser} />,
			},
			{
				path: isSelfHosted
					? "settings/account/azure-user"
					: "settings/azure-user",
				element: <ProtectedRoutes Component={AzureUser} />,
			},
			{
				path: isSelfHosted
					? "settings/account/bitbucket-user"
					: "settings/bitbucket-user",
				element: <ProtectedRoutes Component={BitbucketUser} />,
			},
			{
				path:
					isSelfHosted || isCRSelfHosted
						? "settings/account/subscription"
						: "settings/subscription",
				element: <ProtectedRoutes Component={SeatManagement} />,
			},
			{
				path: "subscription",
				element: (
					<Navigate
						to={
							isSelfHosted || isCRSelfHosted
								? "/settings/account/subscription"
								: "/settings/subscription"
						}
					/>
				),
			},
			{
				path: "profile",
				element: <ProtectedRoutes Component={Profile} />,
			},
			{
				path: "*",
				element: <NotFound />,
			},
		]

		if (selectedOrg?.overrideRole?.toLowerCase() === "super_admin") {
			routes = routes.concat([
				{
					path: "invite-self-hosted-admin",
					element: <ProtectedRoutes Component={InviteSelfHostedAdmin} />,
				},
			])
		}

		if (!isFirebaseUser) {
			routes = routes.concat([
				{
					path: "settings/repositories",
					element: <ProtectedRoutes Component={RepositoryList} />,
				},
				{
					path: "repository/:id/settings",
					element: <ProtectedRoutes Component={Configure} />,
				},
				{
					path: "installation-success",
					element: <ProtectedRoutes Component={InstallationMessage} />,
				},
			])
		}

		if (import.meta.env.VITE_FEATURE_REPORTING_ENABLED) {
			routes = routes.concat([
				{
					path: "reports",
					element: (
						<ProtectedRoutes
							Component={proPlanLock ? UpgradeToProPlanMsg : RecurringReports}
						/>
					),
				},
				{
					path: "reports/recurring",
					element: <Navigate to="/reports" />,
				},
				{
					path: "reports/:reportType/new",
					element: <ProtectedRoutes Component={NewRecurringReport} />,
				},
				{
					path: "reports/:reportType/:reportID",
					element: <ProtectedRoutes Component={NewRecurringReport} />,
				},
				{
					path: "report/details/:messageId",
					element: <ProtectedRoutes Component={ReportDetails} />,
				},
				{
					path: "slack-notifications-install",
					element: <SlackAppInstall />,
				},
				{
					path: "discord-notifications-install",
					element: <DiscordAppInstall />,
				},
			])
		}

		if (import.meta.env.VITE_FEATURE_API_KEYS_ENABLED) {
			routes = routes.concat([
				{
					path: "/settings/api-keys",
					element: <ProtectedRoutes Component={ApiKeys} />,
				},
			])
		}

		if (isSelfHosted) {
			routes = routes.concat([
				{
					path: "settings/subscription",
					element: <Navigate to="/settings/account/subscription" />,
				},
			])
		}

		return routes
	}, [
		// ENABLED_GRAFANA,
		isSelfHosted,
		isCRSelfHosted,
		isFirebaseUser,
		proPlanLock,
		selectedOrg?.overrideRole,
	])

	const appRoutes: RouteObject[] = useMemo(() => {
		let routes: RouteObject[] = [
			{
				path: "/",
				element: <Navigate to="/login" />,
			},
			{
				path: "login",
				element: <Login />,
			},
			{
				path: "sign-in-with-email",
				element: <SignInWithEmailLink />,
			},
			{
				path: "sign-in-with-email/:orgName",
				element: <SignInWithEmailLink />,
			},
			{
				path: "login/gitlab-self-hosted",
				element: <GitLabSelfHostedLogin />,
			},
			{
				path: "login/vscode-extension",
				element: <VSCodeExtensionAuth />,
			},
			{
				path: "/",
				element: <NavContainer />,
				children: routesWithNavContainer,
			},
			{
				path: "onboarding",
				element: <Onboarding />,
			},
			{
				path: "application-consent",
				element: <ApplicationConsent />,
			},
			{
				path: "*",
				element: <NotFound />,
			},
		]

		if (import.meta.env.VITE_ENABLE_CODERABBIT_SELF_HOSTED) {
			routes = routes.concat([
				{
					path: "login/coderabbit-self-hosted",
					element: <Login isCRSelfHostedLogin />,
				},
			])
		}

		if (import.meta.env.VITE_ENABLE_GITHUB_SELF_HOSTED) {
			routes = routes.concat([
				{
					path: "login/github-self-hosted",
					element: <GitHubSelfHostedLogin />,
				},
			])
		}

		return routes
	}, [routesWithNavContainer])

	return useRoutes(appRoutes)
}

export default App
