import ImageButton from "@/components/ImageButton/ImageButton"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { cn } from "@/lib/utils"
import { getSelectedOrg } from "@/utils/utils"

interface NoRepositoriesFoundProps {
	isGitlab: boolean
	isAzureDevops: boolean
	isGithub: boolean
}

export const getGitHubAppUrl = () => {
	const selectedOrg = getSelectedOrg()
	if (!selectedOrg) return import.meta.env.VITE_GITHUB_APP_URL

	try {
		const orgID = selectedOrg.provider_organization_id
		return `${import.meta.env.VITE_GITHUB_APP_URL}/permissions?target_id=${orgID}`
	} catch (e) {
		console.error("Error parsing selected_org:", e)
	}
	return import.meta.env.VITE_GITHUB_APP_URL
}

export function NoRepositoriesFound({
	isGitlab,
	isAzureDevops,
	isGithub,
}: NoRepositoriesFoundProps) {
	const org = getSelectedOrg()
	const type = org?.type === "Organization" ? "organization" : "account"
	return (
		<Card className="mt-8 border-gray-200 bg-gray-50">
			<CardHeader className="pb-4 text-center">
				{(isAzureDevops || isGitlab) && (
					<CardTitle className="font-inter text-lg font-medium">
						No repositories found in this{" "}
						{isGitlab ? "group" : isAzureDevops ? "organization" : "project"}
					</CardTitle>
				)}
				{isGithub && (
					<CardTitle className="font-inter text-lg font-medium">
						CodeRabbit currently doesn’t have access to repositories for this{" "}
						{type}.
					</CardTitle>
				)}
			</CardHeader>
			<CardContent className="text-center">
				<p className="font-inter text-muted-foreground mx-auto mb-6 max-w-[500px]">
					{isGitlab || isAzureDevops ? (
						<ul className="list-inside space-y-2 text-left">
							<li className="flex items-start">
								<span className="mr-2">•</span>
								Use the search bar to find repositories across all{" "}
								{isGitlab ? "groups" : "projects"} you have access to
							</li>
							<li className="flex items-start">
								<span className="mr-2">•</span>
								Select a different {isGitlab ? "group" : "project"} using the
								dropdown menu on the right
							</li>
						</ul>
					) : isGithub ? (
						<>
							Install CodeRabbit on your GitHub {type} and grant access to the
							repositories you want to work with.
						</>
					) : (
						<>
							Try using the search bar to find repositories you have access to.
						</>
					)}
				</p>
				<div className="flex items-center justify-center gap-8">
					{!isGithub && (
						<div className="flex items-center gap-2">
							<span className="text-muted-foreground text-sm">Try:</span>
							<div className={cn("animate-pulse")}>
								<div className="flex items-center gap-1">
									<span className="text-muted-foreground text-sm">←</span>
									<div className="font-poppins text-crb-text-secondary text-sm">
										Search
									</div>
								</div>
							</div>
						</div>
					)}
					{(isGitlab || isAzureDevops) && (
						<div className="flex items-center gap-2">
							<span className="text-muted-foreground text-sm">Or:</span>
							<div className={cn("animate-pulse")}>
								<div className="flex items-center gap-1">
									<span className="text-muted-foreground text-sm">→</span>
									{isGitlab ? (
										<div className="font-poppins text-crb-text-secondary text-sm">
											Group
										</div>
									) : (
										<div className="font-poppins text-crb-text-secondary text-sm">
											Projects
										</div>
									)}
								</div>
							</div>
						</div>
					)}
					{isGithub && (
						<div className="flex items-center gap-2">
							<a href={getGitHubAppUrl()} rel="noreferrer" className="-mb-2">
								<ImageButton title="Add Repositories" />
							</a>
						</div>
					)}
				</div>
				{isGithub && (
					<div className="flex items-center justify-center py-8">
						<div className="flex items-center gap-2">
							<p className="font-inter text-muted-foreground mx-auto mb-6 max-w-[500px]">
								Not seeing the right organization or account? You can switch by
								selecting a different one from the dropdown in the top-left
								corner.
							</p>
						</div>
					</div>
				)}
			</CardContent>
		</Card>
	)
}
