import type { ConfigSettings } from "@coderabbitai/schemas"

// Define the state interface
export interface ConfigState {
	readonly config: ConfigSettings | null
	readonly isError: boolean
	readonly isDefaultSettings: boolean
	readonly repoSettingsEnabled: boolean
	readonly doesOrgSettingsExist: boolean
	readonly isSettingsLoaded: boolean
	readonly isLoading: boolean
}

// Define action types
type BooleanAction = boolean | ((prev: boolean) => boolean)

export type ConfigAction =
	| {
			type: "SET_CONFIG"
			payload:
				| ConfigSettings
				| ((prev: ConfigSettings | null) => ConfigSettings | null)
				| null
	  }
	| { type: "RESET_STATE" }
	| { type: "SET_DEFAULT_SETTINGS"; payload: BooleanAction }
	| { type: "SET_ERROR"; payload: BooleanAction }
	| { type: "SET_LOADING"; payload: BooleanAction }
	| { type: "SET_ORG_SETTINGS_EXIST"; payload: BooleanAction }
	| { type: "SET_REPO_SETTINGS_ENABLED"; payload: BooleanAction }
	| { type: "SET_SETTINGS_LOADED"; payload: BooleanAction }

// Helper function to resolve boolean action
function resolveBooleanAction(
	action: BooleanAction,
	currentValue: boolean,
): boolean {
	return typeof action === "function" ? action(currentValue) : action
}

// Define the initial state
export const initialConfigState: ConfigState = {
	config: null,
	isError: false,
	isDefaultSettings: false,
	repoSettingsEnabled: false,
	doesOrgSettingsExist: false,
	isSettingsLoaded: false,
	isLoading: false,
}

// Define the reducer
export function configReducer(
	state: ConfigState,
	action: ConfigAction,
): ConfigState {
	let newConfig: ConfigSettings | null

	switch (action.type) {
		case "RESET_STATE":
			return initialConfigState
		case "SET_CONFIG":
			newConfig =
				typeof action.payload === "function"
					? action.payload(state.config)
					: action.payload
			return { ...state, config: newConfig }
		case "SET_ERROR":
			return {
				...state,
				isError: resolveBooleanAction(action.payload, state.isError),
			}
		case "SET_DEFAULT_SETTINGS":
			return {
				...state,
				isDefaultSettings: resolveBooleanAction(
					action.payload,
					state.isDefaultSettings,
				),
			}
		case "SET_REPO_SETTINGS_ENABLED":
			return {
				...state,
				repoSettingsEnabled: resolveBooleanAction(
					action.payload,
					state.repoSettingsEnabled,
				),
			}
		case "SET_ORG_SETTINGS_EXIST":
			return {
				...state,
				doesOrgSettingsExist: resolveBooleanAction(
					action.payload,
					state.doesOrgSettingsExist,
				),
			}
		case "SET_SETTINGS_LOADED":
			return {
				...state,
				isSettingsLoaded: resolveBooleanAction(
					action.payload,
					state.isSettingsLoaded,
				),
			}
		case "SET_LOADING":
			return {
				...state,
				isLoading: resolveBooleanAction(action.payload, state.isLoading),
			}
		default:
			return state
	}
}
