import { Combobox as HUICombobox } from "@headlessui/react"
import React, { useState } from "react"
import { FaChevronDown, FaChevronUp } from "react-icons/fa"

interface ComboboxProps {
	options: { id: string; name: string }[]
	value?: string | undefined
	onChange?: (value: string) => void
	placeholder?: string
	disabled?: boolean

	outerContainerProps?: React.HTMLAttributes<HTMLDivElement>
	comboboxProps?: React.HTMLAttributes<HTMLDivElement>
	comboboxButtonProps?: React.HTMLAttributes<HTMLDivElement>
	comboboxButtonIconProps?: React.HTMLAttributes<HTMLDivElement>
	comboboxInputProps?: React.HTMLAttributes<HTMLInputElement>
	comboboxOptionsProps?: React.HTMLAttributes<HTMLUListElement>
}

const Combobox: React.FC<ComboboxProps> = ({
	options,
	value,
	onChange,
	placeholder,
	disabled,
	...props
}) => {
	const [query, setQuery] = useState<string>("")

	const filteredOptions =
		query === ""
			? options
			: options.filter(option =>
					option.name
						.toLowerCase()
						.replace(/\s+/g, "")
						.includes(query.toLowerCase().replace(/\s+/g, "")),
				)

	return (
		<div {...props.outerContainerProps}>
			<HUICombobox
				{...props.comboboxProps}
				value={value}
				onChange={onChange}
				disabled={disabled}
			>
				{({ open }) => (
					<div className="relative">
						<HUICombobox.Button as="div" {...props.comboboxButtonProps}>
							<HUICombobox.Input
								className="mt-1 flex w-full items-center justify-between rounded-lg border border-gray-300 bg-white px-2 py-2"
								onChange={event => {
									setQuery(event.target.value)
								}}
								displayValue={(option: (typeof options)[number]["id"]) =>
									options.find(o => o.id === option)?.name || value || ""
								}
								autoComplete="off"
								placeholder={placeholder || "Select an option"}
								{...props.comboboxInputProps}
							/>
						</HUICombobox.Button>
						<HUICombobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2 pl-4">
							<span className="pr-2">
								{open ? (
									<FaChevronUp
										className="h-3 w-3 text-gray-400"
										aria-hidden="true"
									/>
								) : (
									<FaChevronDown
										className="h-3 w-3 text-gray-400"
										aria-hidden="true"
									/>
								)}
							</span>
						</HUICombobox.Button>
						<HUICombobox.Options
							className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base ring-1 shadow-lg ring-black/5 focus:outline-hidden sm:text-sm"
							{...props.comboboxOptionsProps}
						>
							{filteredOptions.length === 0 && query !== "" && (
								<div className="relative cursor-default px-4 py-2 text-gray-700 select-none">
									Nothing found.
								</div>
							)}
							{filteredOptions.map(option => (
								<HUICombobox.Option
									key={option.id}
									value={option.id}
									className={({ active, selected }) =>
										`relative cursor-pointer px-6 py-2 select-none ${
											active || selected ? "bg-amber-100 text-amber-900" : ""
										} ${selected ? "font-semibold" : ""}`
									}
								>
									{() => (
										<div className="line-clamp-2 break-all">{option.name}</div>
									)}
								</HUICombobox.Option>
							))}
						</HUICombobox.Options>
					</div>
				)}
			</HUICombobox>
		</div>
	)
}

export default Combobox
