import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"
import { DataTableSort } from "@/components/ui/data-table-sort"
import { cn } from "@/lib/utils"
import type { RepoDetails } from "@/typings/githubActionsHandler"
import { checkIfHigherRoleInGitLab } from "@/utils/utils"
import type { ColumnDef } from "@tanstack/react-table"
import { IoSettingsOutline } from "react-icons/io5"
import { Link } from "react-router-dom"

interface ColumnProps {
	isGitLab: boolean
	isAzureDevops: boolean
	isGithub: boolean
	isBitbucket: boolean
	role: string | undefined
	subgroupRole: string | undefined
	onBadgeDetailsOpenChange: (repoDetails: RepoDetails) => void
}

export type RepoTableRow = RepoDetails

export function columns({
	isGitLab,
	isAzureDevops,
	isBitbucket,
	isGithub,
	role,
	subgroupRole,
	onBadgeDetailsOpenChange,
}: ColumnProps): ColumnDef<RepoTableRow>[] {
	const columns: ColumnDef<RepoTableRow>[] = [
		{
			accessorKey: "name",
			header: ({ column }) => {
				return (
					<DataTableSort column={column} className="font-poppins w-22">
						Repository
					</DataTableSort>
				)
			},
			cell: ({ row }) => {
				return (
					<div className="flex flex-row items-center gap-2 px-4">
						<span>{row.original.name}</span>
						{!row.original.private && (
							<div className="text-accent-foreground rounded-2xl border border-gray-300 px-1 text-xs">
								Public
							</div>
						)}
					</div>
				)
			},
		},
		{
			id: "actions",
			accessorKey: "isEnabled",
			header: ({ column }) => {
				return (
					isGitLab && (
						<div className="mr-24 flex items-center justify-end">
							<DataTableSort column={column} className="text-poppins w-22">
								Status
							</DataTableSort>
						</div>
					)
				)
			},
			cell: ({ row }) => {
				return (
					<>
						<div className="flex items-center justify-end gap-4">
							{/* TODO: aquibbaig -- remove "isGithub" check once the PR count API works for all the providers */}
							{!row.original.private && isGithub && row.original.name && (
								<div className="invisible flex gap-4 transition-all ease-out group-hover:visible">
									<div>
										<Button
											variant="shiny"
											onClick={() => {
												onBadgeDetailsOpenChange(row.original)
											}}
											className={cn(
												"!text-muted-foreground h-7 !rounded-xl text-sm",
												"relative",
											)}
											size="sm"
										>
											Generate Badge
										</Button>
									</div>
								</div>
							)}
							<div className={`${isGitLab ? "px-8" : "px-4"} tracking-wider`}>
								{(isAzureDevops || isGitLab || isBitbucket) && (
									<Badge
										variant={row.original.isEnabled ? "default" : "outline"}
									>
										{row.original.isEnabled ? "Installed" : "Not Installed"}
									</Badge>
								)}
								<Link
									to={`/repository/${row.original.id}/settings?repo=${row.original.name}`}
								>
									<Button variant="ghost">
										<IoSettingsOutline size={18} />
									</Button>
								</Link>
							</div>
						</div>
					</>
				)
			},
			enableSorting: true,
		},
	]

	if (
		(isGitLab &&
			(checkIfHigherRoleInGitLab(role) ||
				checkIfHigherRoleInGitLab(subgroupRole))) ||
		isAzureDevops ||
		isBitbucket
	) {
		columns.unshift({
			id: "select",
			header: ({ table }) => {
				const rowsNotInstalled = table
					.getRowModel()
					.rows.filter(row => !row.original.isEnabled)

				const hasRowsNotInstalled = rowsNotInstalled.length > 0

				const allRowsSelected =
					hasRowsNotInstalled &&
					rowsNotInstalled.every(row => row.getIsSelected())

				const someRowsSelected =
					hasRowsNotInstalled &&
					rowsNotInstalled.some(row => row.getIsSelected())

				const handleSelectAllChange = (value: boolean) => {
					rowsNotInstalled.forEach(row => {
						row.toggleSelected(value)
					})
				}

				return (
					<div className="text-left">
						<Checkbox
							checked={
								allRowsSelected
									? true
									: someRowsSelected
										? "indeterminate"
										: false
							}
							// disabled={!hasRowsNotInstalled}
							onCheckedChange={value => {
								handleSelectAllChange(value as boolean)
							}}
							aria-label="Select all Not Installed"
						/>
					</div>
				)
			},
			cell: ({ row }) => {
				const handleRowSelectionChange = (value: boolean) => {
					row.toggleSelected(value)
				}

				return (
					<Checkbox
						checked={row.getIsSelected()}
						onCheckedChange={value => {
							handleRowSelectionChange(!!value)
						}}
						disabled={row.original.isEnabled}
						aria-label="Select row"
					/>
				)
			},
		})
	}

	return columns
}
