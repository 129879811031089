interface PrimaryButtonProps {
	readonly title: string
	readonly disabled?: boolean
	readonly onClick?: () => void
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({
	title,
	disabled,
	onClick,
}) => {
	return (
		<button
			type="button"
			className="font-interSemibold bg-crb-primary enabled:hover:bg-crb-primary-dark dark:bg-crb-primary dark:enabled:hover:bg-crb-primary-dark mb-2 rounded-xl px-6 py-3 text-sm font-normal text-white transition focus:ring-4 focus:ring-[#ff8e6252] focus:outline-hidden disabled:bg-gray-200 disabled:text-slate-400 dark:focus:ring-[#ff8e6252]"
			disabled={disabled}
			onClick={onClick}
		>
			{title}
		</button>
	)
}

export default PrimaryButton
