import Combobox from "@/components/Combobox/Combobox"
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs.tsx"
import type {
	FrequencyOption,
	ScheduleMonthDay,
	ScheduleWeekFrequencyInt,
} from "@/typings/githubActionsHandler"
import { frequencyOptions } from "@/typings/githubActionsHandler"
import moment from "moment"
import React, { useMemo } from "react"
import { FaExclamationTriangle, FaInfoCircle } from "react-icons/fa"
import { days } from "../report-utils.js"

interface CalendarNote {
	message: string
	discard: ScheduleMonthDay[]
	type: "info" | "warning"
}

const calendarNotes: Partial<Record<ScheduleMonthDay, CalendarNote>> = {
	31: {
		message:
			"The report will be generated on the last day of every month, even if it doesn't have a 31st",
		discard: [],
		type: "info",
	},
	30: {
		message:
			"The report won't be sent on February 30th as it does not have a 30th",
		discard: [31],
		type: "warning",
	},
	29: {
		message: "The report won't be sent on February 29th in non-leap years",
		discard: [31],
		type: "warning",
	},
}

interface ReportingScheduleProps {
	frequency: FrequencyOption
	setFrequency: React.Dispatch<React.SetStateAction<FrequencyOption>>
	weekFrequency: ScheduleWeekFrequencyInt
	setWeekFrequency: React.Dispatch<
		React.SetStateAction<ScheduleWeekFrequencyInt>
	>
	selectedDays: string[]
	setSelectedDays: React.Dispatch<React.SetStateAction<string[]>>
	selectedTime: string
	setSelectedTime: React.Dispatch<React.SetStateAction<string>>
	selectedTimezone: string
	setSelectedTimezone: React.Dispatch<React.SetStateAction<string>>
	selectedCalendarDates: ScheduleMonthDay[]
	setSelectedCalendarDates: React.Dispatch<
		React.SetStateAction<ScheduleMonthDay[]>
	>
}

const ReportingSchedule: React.FC<ReportingScheduleProps> = ({
	frequency,
	setFrequency,
	weekFrequency,
	setWeekFrequency,
	selectedTime,
	setSelectedTime,
	selectedTimezone,
	setSelectedTimezone,
	selectedDays,
	setSelectedDays,
	selectedCalendarDates,
	setSelectedCalendarDates,
}) => {
	const timezoneOptions = useMemo(
		() => moment.tz.names().map(timezone => ({ id: timezone, name: timezone })),
		[],
	)

	const renderCalendarNote = (
		date: string,
		{ message, discard, type }: CalendarNote,
	) => {
		if (
			!selectedCalendarDates.includes(Number(date) as ScheduleMonthDay) ||
			!discard.every(d => !selectedCalendarDates.includes(d))
		) {
			return null
		}

		return (
			<div key={date} className="mb-2 flex gap-2">
				{type === "warning" && (
					<FaExclamationTriangle
						className="text-crb-primary-dark mb-0.5 shrink-0"
						size={18}
					/>
				)}
				{type === "info" && (
					<FaInfoCircle
						className="text-crb-primary mb-0.5 shrink-0"
						size={18}
					/>
				)}
				<span className="text-sm">{message}</span>
			</div>
		)
	}

	return (
		<div>
			<div>
				<div className="font-500 font-poppins mt-2">Schedule</div>
				<div className="font-poppins text-muted-foreground max-w-lg text-sm">
					Stick to a schedule to provide reliable insights over time and drive
					improvement with confidence.
				</div>
			</div>

			<div className="mt-4 flex flex-col flex-wrap gap-6 sm:flex-row">
				<div className="flex flex-1 flex-wrap items-center gap-6">
					<div className="flex-1">
						<div className="font-500 font-poppins mb-1.5 text-sm">
							Frequency
						</div>
						<Tabs
							value={frequency}
							onValueChange={value => {
								setFrequency(value as FrequencyOption)
							}}
						>
							<TabsList className="w-full">
								{frequencyOptions.map(tab => (
									<TabsTrigger className="w-full" key={tab} value={tab}>
										{tab}
									</TabsTrigger>
								))}
							</TabsList>
						</Tabs>
					</div>
					{frequency === "Days of Week" && (
						<div>
							<div className="font-500 font-poppins text-sm">Every</div>
							<input
								type="number"
								value={weekFrequency}
								onChange={event => {
									setWeekFrequency(
										Number(event.target.value) as typeof weekFrequency,
									)
								}}
								className="mt-2 mr-2 w-20 rounded-md border border-gray-300 p-2"
								min="1"
								max="8"
							/>
							week(s)
						</div>
					)}
				</div>
				<div className="flex-1">
					<div className="font-500 font-poppins text-sm">Time</div>
					<input
						value={selectedTime}
						onChange={event => {
							setSelectedTime(event.target.value)
						}}
						type="time"
						className="mt-2 w-full rounded-md border border-gray-300 p-2"
					/>
				</div>
			</div>

			<div className="mt-4 flex flex-wrap gap-6">
				<div className="flex flex-1 items-center gap-6">
					{frequency === "Days of Week" && (
						<div className="flex-1">
							<div className="font-500 font-poppins text-sm">Days</div>
							<div className="flex flex-col justify-stretch gap-2 sm:flex-row">
								{days.map(day => (
									<button
										key={day}
										className={`mt-1 w-full cursor-pointer items-center rounded-full border p-2 text-center select-none ${
											selectedDays.includes(day)
												? "border-crb-primary bg-crb-primary text-white"
												: "font-500 border-gray-300 bg-white"
										}`}
										onClick={() => {
											setSelectedDays(prev =>
												prev.includes(day)
													? prev.filter(d => d !== day)
													: [...prev, day],
											)
										}}
									>
										{day.slice(0, 3)}
									</button>
								))}
							</div>
						</div>
					)}

					{frequency === "Days of Month" && (
						<div className="min-w-72 flex-1">
							<div className="font-500 font-poppins mb-0.5 text-sm">
								Frequency
							</div>

							<div className="mt-1 rounded-md border bg-gray-50 p-4">
								<div className="grid grid-cols-7 justify-items-center gap-2">
									{Array.from<number, ScheduleMonthDay>(
										{ length: 31 },
										(_, i) => (i + 1) as ScheduleMonthDay,
									).map(date => (
										<button
											key={date}
											className={`w-10 cursor-pointer rounded-full p-2 text-center select-none ${
												selectedCalendarDates.includes(date)
													? "border-crb-primary bg-crb-primary text-white"
													: "border-gray-300 text-gray-700 hover:bg-gray-200"
											}`}
											onClick={() => {
												setSelectedCalendarDates(prev =>
													prev.includes(date)
														? prev.filter(d => d !== date)
														: [...prev, date],
												)
											}}
										>
											{date}
										</button>
									))}
								</div>
							</div>
						</div>
					)}
				</div>

				<div className="flex flex-1 flex-col justify-between gap-4">
					<div className="min-w-40">
						<div className="font-500 font-poppins text-sm">Timezone</div>
						<Combobox
							options={timezoneOptions}
							value={selectedTimezone}
							onChange={timezone => {
								setSelectedTimezone(timezone)
							}}
							placeholder="No timezone selected"
						/>
					</div>
					{frequency === "Days of Month" && (
						<div className="flex flex-col gap-1">
							{Object.entries(calendarNotes).map(([date, calendarNote]) =>
								renderCalendarNote(date, calendarNote),
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

export default ReportingSchedule
