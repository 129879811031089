import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"
import { DataTableSort } from "@/components/ui/data-table-sort"
import type { RouterOutputs } from "@/trpc"
import { capitalizeFirstLetter, formatDate } from "@/utils/utils"
import type { ColumnDef } from "@tanstack/react-table"
import { FaRegTrashAlt } from "react-icons/fa"
import { FiEdit, FiEye } from "react-icons/fi"
import { MdAlarmOff } from "react-icons/md"
import { useNavigate } from "react-router-dom"
import { scheduleTypes } from "./NewRecurringReport"

interface ColumnProps {
	onDeleteReport: (report: RecurringReportTableRow) => void
}

export type RecurringReportTableRow =
	RouterOutputs["reporting"]["listReports"]["reports"][number]

export const columns = ({
	onDeleteReport,
}: ColumnProps): ColumnDef<RecurringReportTableRow>[] => [
	{
		id: "select",
		header: ({ table }) => (
			<Checkbox
				checked={
					table.getIsAllPageRowsSelected() ||
					(table.getIsSomePageRowsSelected() && "indeterminate")
				}
				onCheckedChange={value => {
					table.toggleAllPageRowsSelected(!!value)
				}}
				aria-label="Select all"
			/>
		),
		cell: ({ row }) => (
			<Checkbox
				checked={row.getIsSelected()}
				onCheckedChange={value => {
					row.toggleSelected(!!value)
				}}
				aria-label="Select row"
			/>
		),
		enableSorting: false,
		enableHiding: false,
	},
	{
		accessorKey: "name",
		header: ({ column }) => {
			return (
				<DataTableSort column={column}>
					<span className="text-muted-foreground">Name</span>
				</DataTableSort>
			)
		},
		cell: ({ row }) => (
			<div className="flex items-center gap-2">
				<div className={row.original.disabled ? "opacity-50" : undefined}>
					{row.original.name}
				</div>
				{row.original.disabled && (
					<span className="ml-2 flex items-center gap-1 rounded-full border border-orange-200 bg-orange-100 px-2 py-1 text-xs font-normal text-orange-800">
						<MdAlarmOff size={14} className="text-orange-500" />
						Disabled
					</span>
				)}
			</div>
		),
	},
	{
		accessorKey: "time",
		header: ({ column }) => {
			return (
				<DataTableSort column={column}>
					<span className="text-muted-foreground">Run time</span>
				</DataTableSort>
			)
		},
		cell: ({ row }) => (
			<div className={row.original.disabled ? "opacity-50" : undefined}>
				{row.original.time} ({row.original.timezone})
			</div>
		),
	},
	{
		accessorKey: "type",
		header: ({ column }) => {
			return (
				<DataTableSort column={column}>
					<span className="text-muted-foreground">Frequency</span>
				</DataTableSort>
			)
		},
		cell: ({ row }) => (
			<div className={row.original.disabled ? "opacity-50" : undefined}>
				<div className="w-fit rounded-full border px-2 py-0.5">
					{row.original.type === scheduleTypes.ONDEMAND
						? "On-Demand"
						: capitalizeFirstLetter(row.original.type.toLowerCase())}
				</div>
			</div>
		),
	},
	{
		accessorKey: "created_at",
		header: ({ column }) => {
			return (
				<DataTableSort column={column}>
					<span className="text-muted-foreground">Created at</span>
				</DataTableSort>
			)
		},
		cell: ({ row }) => (
			<div className={row.original.disabled ? "opacity-50" : undefined}>
				{formatDate(row.original.created_at)}
			</div>
		),
	},
	{
		id: "actions",
		cell: ({ row }) => {
			const navigate = useNavigate()

			return (
				<div className="flex items-center justify-end gap-1">
					<Button
						variant="ghost"
						title="Edit"
						onClick={() => {
							navigate(
								`./${row.original.type === scheduleTypes.ONDEMAND ? "ondemand" : "recurring"}/${row.original.id}`,
							)
						}}
					>
						{row.original.type === scheduleTypes.ONDEMAND ? (
							<FiEye size={18} />
						) : (
							<FiEdit size={18} />
						)}
					</Button>
					<Button
						variant="ghost"
						title="Delete"
						onClick={() => {
							onDeleteReport(row.original)
						}}
					>
						<FaRegTrashAlt className="text-red-500" />
					</Button>
				</div>
			)
		},
	},
]
