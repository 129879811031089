import type { RouterOutputs } from "@/trpc"

export interface SelfHostedDomainData {
	readonly hostURL: string
	readonly clientId: string
	readonly scope: string
	readonly redirectURI: string
	readonly orgName?: string
}

export interface GetUserDataResponse {
	readonly recently_created: boolean
	readonly user_email: string
	readonly secondary_emails: string[]
	readonly user_name: string
	readonly subscriber_id: string
	readonly provider_user_id: string
}

export interface OrganizationData {
	readonly enableReferral: boolean
	id: string
	readonly lastSubscriptionStatus: string | null
	readonly memberCount: number
	readonly parentId: string | null
	readonly planId: string | null
	readonly organization_name: string
	readonly organization_full_path: string
	readonly provider: string
	readonly provider_organization_id: string
	readonly role: string
	readonly type: string
	readonly overrideRole?: string | null
}

export const IssueIntegrations = {
	JIRA: "JIRA",
	GITHUB: "GITHUB",
	LINEAR: "LINEAR",
	CIRCLECI: "CIRCLECI",
} as const

export const supportedCurrencyCodes = {
	INR: "INR",
	USD: "USD",
} as const

export type SupportedCurrencyCode =
	(typeof supportedCurrencyCodes)[keyof typeof supportedCurrencyCodes]

export const origins = {
	activatePro: "activatePro",
	seatManagement: "seatManagement",
} as const

export type Origin = (typeof origins)[keyof typeof origins]

export type IssueIntegration =
	(typeof IssueIntegrations)[keyof typeof IssueIntegrations]

export interface IntegrationData {
	readonly id: number
	readonly owner_id: string
	readonly service: IssueIntegration
	readonly service_id?: string
	readonly host_url?: string
	readonly access_token_encrypted: string
	readonly access_token_tag: string
	readonly access_token_iv: string
	readonly access_token_validity: string
	readonly refresh_token_encrypted?: string
	readonly refresh_token_tag?: string
	readonly refresh_token_iv?: string
}

export type UserData =
	RouterOutputs["organization_members"]["getAllMembers"]["data"]["usersData"][number]
