import { Button } from "@/components/ui/button"
import { Dialog, DialogContent, DialogTitle } from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { Skeleton } from "@/components/ui/skeleton"
import { logger } from "@/lib/utils"
import type { OrganizationData } from "@/typings"
import type { RepoDetails } from "@/typings/githubActionsHandler"
import type { Provider } from "@/utils/providers"
import { Check, CircleCheck, CopyIcon } from "lucide-react"
import { useEffect, useRef, useState, type FC } from "react"

interface Props {
	repoDetails: RepoDetails & {
		provider: Provider | null
		orgName: OrganizationData["organization_name"]
	}
	setRepoDetails: (
		repoDetails:
			| (RepoDetails & {
					provider: Provider | null
					orgName: OrganizationData["organization_name"]
			  })
			| null,
	) => void
}

export const RepositoryBadge: FC<Props> = ({ repoDetails, setRepoDetails }) => {
	const timerRef = useRef<NodeJS.Timeout | null>(null)
	const [isCopied, setIsCopied] = useState(false)

	const [isPreviewLoading, setIsPreviewLoading] = useState(true)

	useEffect(() => {
		return () => {
			if (timerRef.current) {
				clearTimeout(timerRef.current)
			}
		}
	}, [])

	if (!repoDetails.provider || !repoDetails.orgName || !repoDetails.name) {
		return null
	}

	const queryParams = new URLSearchParams({
		utm_source: "oss",
		utm_medium: repoDetails.provider,
		utm_campaign: `${repoDetails.orgName}/${repoDetails.name}`,
		labelColor: "171717",
		color: "FF570A",
		link: "https://coderabbit.ai",
		label: "CodeRabbit Reviews",
	}).toString()

	const badgeLink = `https://img.shields.io/coderabbit/prs/${repoDetails.provider}/${repoDetails.orgName}/${repoDetails.name}?${queryParams}`
	const markDownText = `![CodeRabbit Pull Request Reviews](${badgeLink})`

	return (
		<Dialog
			open={true}
			onOpenChange={open => {
				if (!open) {
					setRepoDetails(null)
				}
			}}
		>
			<DialogContent>
				<DialogTitle>Generate Badge</DialogTitle>
				<div className="mt-4 flex flex-col gap-4">
					<span className="text-muted-foreground">
						Consider adding the "CodeRabbit Pull Request Reviews" badge to your
						repository to showcase automated pull request reviews. Copy the
						snippet below and paste it into your README.md file.
					</span>
					<div>
						{isPreviewLoading && <Skeleton className="h-5 w-40"></Skeleton>}
						<img
							src={badgeLink}
							alt={`cr-badge-${repoDetails.name}`}
							onLoad={() => {
								setIsPreviewLoading(false)
							}}
						/>
					</div>
					<div className="relative mt-1">
						<Input
							value={`![CodeRabbit Pull Request Reviews](${badgeLink})`}
							disabled
							className="text-muted-foreground !cursor-default border border-gray-300 pr-10 !opacity-100"
						/>
						{isCopied ? (
							<div className="mt-1 flex flex-row justify-end text-sm text-green-600">
								Copied!
							</div>
						) : (
							<div className="mt-1 flex h-5 w-2 flex-row justify-end"></div>
						)}
						{isCopied ? (
							<Button
								className="absolute top-0 right-0"
								size="icon"
								variant="link"
							>
								<Check size={16} className="stroke-green-600 stroke-3" />
							</Button>
						) : (
							<Button
								className="absolute top-0 right-0"
								size="icon"
								variant="link"
								onClick={() => {
									navigator.clipboard
										.writeText(markDownText)
										.then(() => {
											setIsCopied(true)

											timerRef.current = setTimeout(() => {
												setIsCopied(false)
												clearTimeout(timerRef.current!)
											}, 4000)
										})
										.catch(error => {
											logger.error(`Failed to copy badge text`, error)
										})
								}}
							>
								<CopyIcon size={14} />
							</Button>
						)}
					</div>
				</div>
				<div className="flex-end mt-3 flex flex-row items-end justify-end">
					<Button
						onClick={() => {
							setRepoDetails(null)
						}}
						className="gap-2"
					>
						<CircleCheck size={14} />
						Done
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	)
}
