import { Input } from "@/components/ui/input.js"
import React from "react"
import { FaInfoCircle } from "react-icons/fa"
import gmailLogo from "../../../../assets/gmail_logo.png"

interface EmailIntegrationCardProps {
	readonly emails: string[]
	readonly onEmailsChange: (emails: string[]) => void
}

const EmailIntegrationCard: React.FC<EmailIntegrationCardProps> = ({
	emails,
	onEmailsChange,
}) => {
	return (
		<div className="rounded-lg border bg-white p-6">
			<div className="flex items-center justify-between gap-2">
				<div className="flex items-center">
					<div className="flex h-16 shrink-0 items-center justify-center rounded-lg border p-1.5">
						<img width={50} src={gmailLogo} alt="Gmail logo" />
					</div>
					<div className="ml-5">
						<p className="font-weight-600 text-crb-text-primary text-base">
							Email
						</p>
					</div>
				</div>
			</div>
			<p className="text-crb-text-tertiary mt-4 text-sm">
				Configure the email addresses to send the report to
			</p>
			<div className="font-500 font-poppins mt-6 mb-1.5 text-sm">
				Email ID(s)
			</div>
			<Input
				value={emails.join(",")}
				onChange={e => {
					const value = e.target.value.trim()
					onEmailsChange(value ? value.split(",") : [])
				}}
			/>
			<div className="mt-2 flex gap-1 text-xs text-gray-500">
				<FaInfoCircle className="mt-0.5 shrink-0 text-gray-400" />
				Use comma separated email IDs to send the report to multiple email
				addresses
			</div>
		</div>
	)
}

export default EmailIntegrationCard
