type AdmonitionType = "important" | "note" | "tip" | "warning"

interface AdmonitionProps {
	type?: AdmonitionType
	title?: string
	children: React.ReactNode
}

const admonitionStyles: Record<
	AdmonitionType,
	{ bg: string; icon: string; color: string }
> = {
	note: {
		bg: "bg-blue-50 dark:bg-blue-950/50",
		icon: "M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z",
		color: "text-blue-500",
	},
	warning: {
		bg: "bg-yellow-50 dark:bg-yellow-950/50",
		icon: "M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z",
		color: "text-yellow-500",
	},
	tip: {
		bg: "bg-green-50 dark:bg-green-950/50",
		icon: "M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z",
		color: "text-green-500",
	},
	important: {
		bg: "bg-purple-50 dark:bg-purple-950/50",
		icon: "M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z",
		color: "text-purple-500",
	},
}

export const Admonition: React.FC<AdmonitionProps> = ({
	type = "note",
	title,
	children,
}) => {
	const styles = admonitionStyles[type]

	return (
		<div
			className={`flex items-start gap-1 rounded-lg border p-4 ${styles.bg}`}
		>
			<div className={`mt-0.5 flex-shrink-0 ${styles.color}`}>
				<svg
					className="h-4 w-4"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={2}
						d={styles.icon}
					/>
				</svg>
			</div>
			<div className="flex flex-col space-y-1">
				<p className="font-poppins text-sm font-medium">
					{title || type.charAt(0).toUpperCase() + type.slice(1)}
				</p>
				<div className="text-muted-foreground font-poppins text-xs">
					{children}
				</div>
			</div>
		</div>
	)
}
