import ImageButton from "@/components/ImageButton/ImageButton"
import Search from "@/components/Search/Search"
import { DataTable } from "@/components/ui/data-table"
import { useColumnFilter } from "@/components/ui/data-table-utils"
import { trpc } from "@/trpc"
import React, { useEffect, useState } from "react"
import { LuKeyRound } from "react-icons/lu"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import Loader from "../../../components/Loader/Loader"
import DestructiveActionModal from "../../../components/Modal/DestructiveActionModal"
import { columns } from "./Columns"
import CreateApiKeyModal from "./CreateApiKeyModal"

const ApiKeys: React.FC = () => {
	const listApiKeys = trpc.apiKeys.listApiKeys.useQuery()
	const apiKeyList = listApiKeys.data?.apiKeys ?? []

	const { columnFilters, setColumnFilters, setColumnFilter } =
		useColumnFilter<(typeof apiKeyList)[number]>()

	const [createApiKey, setCreateApiKey] = useState<boolean>(false)
	const [deleteApiKey, setDeleteApiKey] =
		useState<(typeof apiKeyList)[number]>()

	const deleteApiKeyHandler = trpc.apiKeys.deleteApiKey.useMutation({
		onSuccess: () => {
			setDeleteApiKey(undefined)
			listApiKeys.remove()
			void listApiKeys.refetch().finally(() => {
				setDeleteApiKey(undefined)
				toast.success("API Key deleted successfully")
			})
		},
	})

	useEffect(() => {
		if (listApiKeys.error) {
			toast.error(
				"Failed to fetch the list of API keys: " + listApiKeys.error.message,
			)
		}
	}, [listApiKeys.error])

	useEffect(() => {
		if (deleteApiKeyHandler.error) {
			toast.error(
				"Failed to delete the API key: " + deleteApiKeyHandler.error.message,
			)
		}
	}, [deleteApiKeyHandler.error])

	const isLoading = listApiKeys.isLoading || deleteApiKeyHandler.isLoading

	return (
		<>
			{isLoading ? (
				<Loader size="small" />
			) : (
				<div className="container mx-auto px-8 pt-7 pb-2">
					<div className="container mx-auto">
						<div>
							<div className="mb-4 flex items-center justify-between">
								<div>
									<div className="font-500 font-inter text-foreground mb-2 text-2xl leading-8">
										API Keys
									</div>
									<div className="font-400 font-inter text-muted-foreground text-sm leading-5 underline">
										<Link
											to={import.meta.env.VITE_API_REFERENCE}
											target="_blank"
											rel="noopener noreferrer"
										>
											API Reference
										</Link>
									</div>
								</div>

								{apiKeyList.length > 0 && (
									<ImageButton
										title="Create API Key"
										onClick={() => {
											setCreateApiKey(true)
										}}
									/>
								)}
							</div>

							{apiKeyList.length === 0 && (
								<div className="flex items-center justify-center rounded-md border">
									<div className="flex flex-col items-center justify-center px-6 py-20 text-center">
										<LuKeyRound size={36} />
										<h2 className="font-500 text-foreground mt-3 text-lg">
											Create an API Key
										</h2>
										<div className="text-muted-foreground">
											Create an API key to access the CodeRabbit API.
										</div>
										<div className="mt-4">
											<ImageButton
												title="Create API Key"
												onClick={() => {
													setCreateApiKey(true)
												}}
											/>
										</div>
									</div>
								</div>
							)}
						</div>

						{apiKeyList.length > 0 && (
							<div>
								<div className="mb-4 flex items-center justify-between gap-4">
									<Search
										onSearch={query => {
											setColumnFilter("api_key_name", query)
										}}
										showLabel={false}
									/>
								</div>
								<div className="block max-w-full overflow-x-auto overflow-y-hidden">
									<DataTable
										data={apiKeyList}
										columns={columns({
											onDeleteApiKey: setDeleteApiKey,
										})}
										tableOptions={{
											onColumnFiltersChange: setColumnFilters,
											state: {
												columnFilters,
											},
										}}
									/>
								</div>

								<DestructiveActionModal
									active={!!deleteApiKey}
									title="Delete API Key"
									description={`Are you sure you want to delete the ${deleteApiKey?.api_key_name || "selected"} API Key?`}
									onCancel={() => {
										setDeleteApiKey(undefined)
									}}
									onConfirm={() => {
										if (deleteApiKey) {
											deleteApiKeyHandler.mutate(deleteApiKey.id)
										}
									}}
								/>
							</div>
						)}

						<CreateApiKeyModal
							open={createApiKey}
							onOpenChange={setCreateApiKey}
							onCreateKey={() => {
								void listApiKeys.refetch()
							}}
						/>
					</div>
				</div>
			)}
		</>
	)
}

export default ApiKeys
