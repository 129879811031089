import { cn } from "@/lib/utils.js"
import React from "react"
import { useLocation } from "react-router"
import "../Referral/GrowSurf.css"
import { Skeleton } from "../ui/skeleton.js"
import DesktopOrgMenu from "./DesktopOrgMenu.js"
import MenuItem from "./MenuItem.js"
import type { SidebarMenuItem } from "./Nav.js"
import { useBottomNavItems, useNavItems } from "./Nav.js"
import UserSidebarItem from "./UserSidebarItem.js"
import { checkIsActive, isNotHidden } from "./nav-utils.js"
import { useSelectedOrg } from "./useSelectedOrg.js"

interface NavSidebarContentsProps {
	isCollapsed: boolean
}

const NAVBAR_SKELETON_COUNT = 5

const NavSidebarContents: React.FC<NavSidebarContentsProps> = ({
	isCollapsed,
}) => {
	const { pathname } = useLocation()
	const selectedOrg = useSelectedOrg()
	const navItems = useNavItems()
	const bottomNavItems = useBottomNavItems(selectedOrg)

	return (
		<>
			<div className="mt-3 hidden w-full px-2 md:block">
				<DesktopOrgMenu isCollapsed={isCollapsed} />
				<div className="my-4 w-full border-b" />
			</div>

			{/* Menu Items */}
			<div
				className={`scrollbar-thin flex h-full flex-col justify-between ${
					isCollapsed ? "items-center" : "items-start"
				} mt-6 px-3 md:mt-0`}
			>
				<nav className={`flex-1 ${isCollapsed ? "" : "w-full"}`}>
					{selectedOrg !== null &&
						navItems
							.filter(isNotHidden)
							.map(item => (
								<MenuItem
									key={item.name}
									name={item.name}
									Icon={item.Icon}
									link={item.link}
									newTab={!!item.newTab}
									items={item.items}
									isActive={checkIsActive(item, pathname)}
									isCollapsed={isCollapsed}
									proPlanLock={!!item.proPlanLock}
								/>
							))}

					{selectedOrg === null && (
						<div className="flex flex-col gap-y-2">
							{Array.from({ length: NAVBAR_SKELETON_COUNT }).map((_, index) => (
								<Skeleton
									key={index}
									className={cn(isCollapsed ? "h-8 w-10" : "h-10 w-full")}
								/>
							))}
						</div>
					)}
				</nav>

				{/* Bottom Nav Items */}
				<nav className={`mb-4 ${isCollapsed ? "" : "w-full"}`}>
					{bottomNavItems.filter(isNotHidden).map((item: SidebarMenuItem) => (
						<MenuItem
							key={item.name}
							name={item.name}
							Icon={item.Icon}
							link={item.link}
							newTab={!!item.newTab}
							items={item.items}
							isActive={checkIsActive(item, pathname)}
							isCollapsed={isCollapsed}
						/>
					))}
				</nav>

				<UserSidebarItem isCollapsed={isCollapsed} />
			</div>
		</>
	)
}

export default NavSidebarContents
