import type { WidgetProps } from "@rjsf/utils"
import { FaRegCircleQuestion } from "react-icons/fa6"
import { SelectInputWidget } from "./SelectInputWidget"

import { SelectItem } from "@/components/ui/select"
import { Divider } from "@mui/material"
import { LANGUAGES } from "../../languages.json"

export function LanguageWidget(
	props: WidgetProps & { widgetTitle: string; widgetDescription: string },
) {
	const { widgetTitle, widgetDescription } = props
	return (
		<>
			<SelectInputWidget
				{...{
					props,
					texts: {
						title: widgetTitle,
						description: widgetDescription,
					},
					selectOptionsElement: (
						<>
							{LANGUAGES.map(lang => {
								return (
									<SelectItem value={lang.longCode} key={lang.longCode}>
										{lang.name}
									</SelectItem>
								)
							})}
						</>
					),
				}}
			>
				<div className="mt-2 flex flex-row items-center gap-1">
					<FaRegCircleQuestion className="text-gray-500" />
					<p className="font-poppins text-xs leading-4 font-normal text-gray-500 not-italic">
						Default language is English
					</p>
				</div>
			</SelectInputWidget>
			<Divider sx={{ my: 2 }} />
		</>
	)
}
