import { useSelectedOrg } from "@/components/Nav/useSelectedOrg"
import { Button } from "@/components/ui/button"
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog"
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select"
import { trpc } from "@/trpc"
import { formatErrorDetails } from "@/utils/error-logs"
import { useProvider } from "@/utils/providers"
import { sentryCaptureException } from "@/utils/utils"
import { useMutation } from "@tanstack/react-query"
import { useContext, useState } from "react"
import { LuPlusCircle } from "react-icons/lu"
import { toast } from "react-toastify"
import { SubscriptionContext } from "../SeatManagement"

const SupportedCurrency = {
	USD: "USD",
	INR: "INR",
} as const

export const StartFreeTrial: React.FC = () => {
	const checkoutFreeTrial = trpc.billing.freeTrialCheckout.useMutation()
	const selectedOrg = useSelectedOrg()
	const { provider } = useProvider()
	const { isAdmin } = useContext(SubscriptionContext)

	const [currency, setCurrency] = useState<keyof typeof SupportedCurrency>(
		SupportedCurrency.USD,
	)

	async function checkOut() {
		const quantity = 1

		const orgId = sessionStorage.getItem("org_id")

		if (!isAdmin) {
			toast.error(
				"Action denied: Only organization admins can buy subscriptions.",
			)
			return
		}

		const memberCount = selectedOrg?.memberCount ?? 1
		if (!orgId || !provider || !memberCount) {
			toast.error("Failed to initiate checkout. Please logout and try again.")
			return
		}

		await checkoutFreeTrial.mutateAsync(
			{
				quantity,
				memberCount,
				currency: currency,
			},
			{
				onSuccess: data => {
					if (!data.isSuccess) {
						toast.error("Failed to initiate checkout. Please try again.")
						return
					}
					if (data.data.url) {
						window.location.assign(data.data.url)
					}
				},
				onError: error => {
					if (error.data?.httpStatus == 409) {
						toast.error("Subscription already exists. Please contact support.")
						return
					}
					toast.error("Failed to initiate checkout. Please try again.")
					const errorDetails = formatErrorDetails(error)
					sentryCaptureException("checkOut: API failed: ", errorDetails)
				},
			},
		)
	}

	const checkoutMutation = useMutation({
		mutationFn: checkOut,
	})

	return (
		<div className="mt-8 mb-8 flex flex-col items-center justify-center gap-8 rounded-2xl border bg-white p-6 shadow-md">
			<span className="font-inter text-sm leading-5 font-normal not-italic">
				Get started now for free, no credit card required! Just choose a monthly
				or annual plan below, select the number of seats you need and click
				'Subscribe'. You can adjust or cancel your subscription anytime. Ready
				to get started?
			</span>
			<Dialog>
				<DialogTrigger asChild>
					<Button
						className="flex items-center gap-2"
						// onClick={checkOut}
					>
						<LuPlusCircle className="h-4 w-4" />
						Start Free Trial
					</Button>
				</DialogTrigger>
				<DialogContent>
					<DialogHeader>
						<DialogTitle>
							Select your currency to start your free trial.
						</DialogTitle>
						<DialogDescription className="flex items-center gap-2">
							<Select
								value={currency}
								onValueChange={(value: keyof typeof SupportedCurrency) => {
									setCurrency(value)
								}}
								defaultValue={SupportedCurrency.USD}
							>
								<SelectTrigger className="mt-4">
									<SelectValue
										defaultValue="usd"
										placeholder="Select your currency"
									/>
								</SelectTrigger>
								<SelectContent>
									<SelectItem value={SupportedCurrency.USD}>
										USD (US Dollars)
									</SelectItem>
									<SelectItem value={SupportedCurrency.INR}>
										INR (Indian Rupees)
									</SelectItem>
								</SelectContent>
							</Select>
						</DialogDescription>
					</DialogHeader>
					<DialogFooter className="mt-4">
						<DialogClose asChild>
							<Button variant="outline">Cancel</Button>
						</DialogClose>
						<Button
							onClick={() => {
								checkoutMutation.mutate()
							}}
							disabled={checkoutMutation.isLoading}
						>
							{checkoutMutation.isLoading ? "Proceeding..." : "Proceed"}
						</Button>
					</DialogFooter>
				</DialogContent>
			</Dialog>
		</div>
	)
}
