import React from "react"

interface InputBoxProps {
	readonly id?: string
	readonly title?: string
	readonly description?: string
	readonly placeholder?: string
	readonly value?: string
	readonly onChange?: (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => void
	readonly disabled?: boolean
	readonly textarea?: boolean
}

const InputBox: React.FC<InputBoxProps> = ({
	id,
	title,
	description,
	placeholder,
	value,
	onChange,
	disabled,
	textarea,
}) => {
	return (
		<>
			<h3 className="font-500 text-left text-sm">{title}</h3>
			<p className="text-muted-foreground mb-3 text-left text-xs">
				{description}
			</p>
			{textarea ? (
				<textarea
					id={id}
					placeholder={placeholder}
					value={value}
					onChange={onChange}
					disabled={disabled}
					rows={5}
					className="font-figtreeRegular mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 font-normal shadow-xs transition placeholder:text-gray-400 focus:ring-4 focus:ring-gray-300 focus:outline-hidden disabled:bg-gray-100"
				/>
			) : (
				<input
					type="text"
					id={id}
					placeholder={placeholder}
					value={value}
					onChange={onChange}
					disabled={disabled}
					className="font-figtreeRegular mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 font-normal shadow-xs transition placeholder:text-gray-400 focus:ring-4 focus:ring-gray-300 focus:outline-hidden disabled:bg-gray-100"
				/>
			)}
		</>
	)
}

export default InputBox
