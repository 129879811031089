import { DataTable } from "@/components/ui/data-table"
import type { OrganizationData } from "@/typings"
import type { RepoDetails } from "@/typings/githubActionsHandler"
import { useProvider, type Provider } from "@/utils/providers"
import type { PaginationState, RowSelectionState } from "@tanstack/react-table"
import { useState, type FC } from "react"
import { columns } from "../Columns"
import type { SelectedGitlabGroup } from "../RepositoryList"
import { RepositoryBadge } from "./RepositoryBadge"

interface Props {
	repos: {
		readonly id: string
		readonly name: string
		readonly private: boolean
		readonly isEnabled: boolean
		readonly orgId: string
	}[]
	isLoading: boolean
	selectedOrg: OrganizationData | null
	selectedGitlabGroup: SelectedGitlabGroup
	page: number
	pagination: PaginationState
	rowSelection: RowSelectionState
	provider: Provider | null
	setPagination: React.Dispatch<React.SetStateAction<PaginationState>>
	setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>
}

export const RepositoriesTable: FC<Props> = ({
	selectedOrg,
	repos,
	isLoading,
	selectedGitlabGroup,
	page,
	pagination,
	rowSelection,
	setPagination,
	setRowSelection,
	provider,
}) => {
	const { isGitlab, isAzureDevops, isGitHub, isBitbucket } = useProvider()

	const [repoDetails, setRepoDetails] = useState<
		| (RepoDetails & {
				provider: Provider | null
				orgName: OrganizationData["organization_name"]
		  })
		| null
	>(null)

	return (
		<>
			{repoDetails && (
				<RepositoryBadge
					repoDetails={repoDetails}
					setRepoDetails={repoDetails => {
						setRepoDetails(repoDetails)
					}}
				/>
			)}
			<DataTable
				data={repos}
				isLoading={isLoading}
				columns={columns({
					isGitLab: isGitlab,
					isGithub: isGitHub,
					isAzureDevops: isAzureDevops,
					isBitbucket: isBitbucket,
					role: selectedOrg?.role,
					subgroupRole: selectedGitlabGroup?.role,
					onBadgeDetailsOpenChange: (repoDetails: RepoDetails) => {
						setRepoDetails({
							...repoDetails,
							provider,
							orgName: selectedOrg?.organization_name || "",
						})
					},
				})}
				tableOptions={{
					manualPagination: true,
					pageCount: page,
					state: {
						pagination,
						rowSelection,
					},
					onPaginationChange: setPagination,
					onRowSelectionChange: setRowSelection,
					getRowId: originalRow => String(originalRow.id),
				}}
			/>
		</>
	)
}
