import { useContext, useEffect, useRef, useState, type FC } from "react"
import { UNSAFE_NavigationContext, useLocation } from "react-router-dom"

import ErrorLayout from "@/components/Loader/ErrorLayout"
import Loader from "@/components/Loader/Loader"
import { logger } from "@/lib/utils"
import { trpc } from "@/trpc"
import { GRAFANA_APP_NAME, GRAFANA_PORTAL_DIV } from "../consts"
import { useGrafanaContext, type GrafanaPortal } from "../grafana-context"
import { useRootAppStatus } from "../hooks"

export interface LoadDashboardPortalProps extends GrafanaPortal {
	readonly codeRabbitLocalFilters?: Record<string, string>
}

const DEFAULT_ERROR_MESSAGE = `Requested dashboard is not found. Try refreshing page, if issue persists contact support.`

export const LoadDashboardPortal: FC<LoadDashboardPortalProps> = ({
	uid,
	slug,
	queryParams,
	metadata,
	hiddenVariables = [],
	version = undefined,
	controlsContainer = null,
	codeRabbitLocalFilters,
}) => {
	const { action, isGrafanaBroken } = useGrafanaContext()

	const { navigator } = useContext(UNSAFE_NavigationContext)
	/**
	 * There are scenarios in ui where pathname get changed but uid of dashboard remains same.
	 * In such case, grafana get confused and dashboard doesn't get updated. Pathname is added as a key which unmount and mount portal
	 * with updated dashboard.
	 */
	const { pathname } = useLocation()
	const { isError } = trpc.grafana.validateDashboardUID.useQuery(uid)

	const { checkRootAppMountStatus } = useRootAppStatus()
	const [isRootAppError, setIsRootAppError] = useState(false)
	const [isRootAppLoading, setIsRootAppLoading] = useState(false)

	const { current: currentVersion } = useRef(version)

	useEffect(
		() => () => {
			if (codeRabbitLocalFilters) {
				navigator.replace({
					search: createSearchParams(codeRabbitLocalFilters).toString(),
				})
			}

			if (!action) return

			action({
				type: "RESET_GRAFANA_PORTAL",
			})
		},
		[navigator, action, controlsContainer],
	)

	useEffect(() => {
		if (!action || !uid.length || !slug.length) {
			logger.warn("Missing uid | slug | action")

			return
		}

		checkRootAppMountStatus({
			onSuccess: () => {
				setIsRootAppError(false)
				setIsRootAppLoading(false)

				action({
					type: "SET_GRAFANA_PORTAL",
					payload: {
						uid,
						slug,
						queryParams,
						hiddenVariables: sendableHiddenVariables(hiddenVariables),
						version: currentVersion ?? 1.0,
						controlsContainer,
						metadata,
					},
				})
			},
			onError: () => {
				setIsRootAppError(true)
				setIsRootAppLoading(false)
			},
			isLoading: () => {
				setIsRootAppError(false)
				setIsRootAppLoading(true)
			},
		})
	}, [
		action,
		checkRootAppMountStatus,
		controlsContainer,
		currentVersion,
		hiddenVariables,
		queryParams,
		slug,
		uid,
	])

	if (isRootAppLoading && !isRootAppError && !isGrafanaBroken) {
		return <Loader message="Setting up dashboard" />
	}

	return (
		<>
			{isError || !uid || isRootAppError || isGrafanaBroken ? (
				<ErrorLayout message={DEFAULT_ERROR_MESSAGE} isContained />
			) : (
				<div
					data-qiankun={GRAFANA_APP_NAME}
					id={GRAFANA_PORTAL_DIV}
					key={[pathname, uid].join("-")}
					style={{ position: "relative" }}
				/>
			)}
		</>
	)
}

export const sendableHiddenVariables = (variables: string[]) =>
	variables.map(hv => hv.replace("var-", ""))

export const createSearchParams = (
	loadableSearchParams: Record<string, string>,
) => new URLSearchParams(loadableSearchParams)
