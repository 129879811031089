import { useFirebaseCtx } from "@/firebase/provider.js"
import { logout } from "@/utils/logout.js"
import { capitalizeFirstLetter } from "@/utils/utils"
import React from "react"
import { LuLogOut, LuUser } from "react-icons/lu"
import { useNavigate } from "react-router"
import "../Referral/GrowSurf.css"
import { Button } from "../ui/button.js"
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover.js"
import { Skeleton } from "../ui/skeleton.js"
import { Notifications } from "./Notifications.js"
import { useSelectedOrg } from "./useSelectedOrg.js"

interface UserSidebarItemProps {
	isCollapsed: boolean
}

const UserSidebarItem: React.FC<UserSidebarItemProps> = ({ isCollapsed }) => {
	const avatarUrl = sessionStorage.getItem("avatar_url")
	const username = sessionStorage.getItem("login")
	const selectedOrg = useSelectedOrg()
	const { firebaseUser } = useFirebaseCtx()

	const navigate = useNavigate()

	function avatarURL() {
		if (avatarUrl) {
			return avatarUrl
		}

		if (firebaseUser) {
			return firebaseUser.photoURL ?? ""
		}

		return ""
	}

	function profile() {
		navigate("/profile")
	}

	return (
		<Popover>
			<PopoverTrigger className="mb-4 w-full" asChild={!isCollapsed}>
				<div
					className={`flex w-full items-center rounded-md border py-2 ${isCollapsed ? "" : "px-2"}`}
				>
					<div
						className={`flex flex-1 items-center ${isCollapsed ? "justify-center" : ""}`}
					>
						<img
							className="h-10 w-10 shrink-0 rounded-full"
							src={avatarURL()}
							alt=""
						/>
						{!isCollapsed && (
							<div className="ml-2">
								<div className="max-w-[125px] truncate font-medium">
									{username || firebaseUser?.displayName}
								</div>
								{(selectedOrg?.overrideRole || selectedOrg?.role) && (
									<span className="font-400 text-muted-foreground text-xs">
										{capitalizeFirstLetter(
											selectedOrg.overrideRole
												? selectedOrg.overrideRole.toLowerCase()
												: selectedOrg.role.toLowerCase(),
										)}
									</span>
								)}
								{!selectedOrg && <Skeleton className="h-4 w-20 rounded-md" />}
							</div>
						)}
					</div>

					{!isCollapsed && (
						<>
							<Notifications collapsed={isCollapsed} />
							<Button
								variant="ghost"
								onClick={profile}
								title="Profile"
								className="px-3"
							>
								<LuUser />
							</Button>
							<Button
								variant="ghost"
								onClick={logout}
								title="Logout"
								className="px-3"
							>
								<LuLogOut />
							</Button>
						</>
					)}
				</div>
			</PopoverTrigger>
			{isCollapsed && (
				<PopoverContent
					side="right"
					sideOffset={24}
					align="start"
					className="z-20"
				>
					<div className="ml-2 line-clamp-2 flex items-center gap-x-2 font-medium break-all">
						<div className="max-w-[150px] truncate font-medium">
							{username || firebaseUser?.displayName}
						</div>
						{(selectedOrg?.overrideRole || selectedOrg?.role) && (
							<span className="font-400 text-muted-foreground text-xs">
								{capitalizeFirstLetter(
									selectedOrg.overrideRole
										? selectedOrg.overrideRole.toLowerCase()
										: selectedOrg.role.toLowerCase(),
								)}
							</span>
						)}
						{!selectedOrg && <Skeleton className="h-4 w-20 rounded-md" />}
					</div>
					<Notifications collapsed={isCollapsed} />
					<Button variant="outline" className="mt-2 w-full" onClick={profile}>
						<LuUser className="mr-2" />
						Profile
					</Button>
					<Button variant="outline" className="mt-2 w-full" onClick={logout}>
						<LuLogOut className="mr-2" />
						Logout
					</Button>
				</PopoverContent>
			)}
		</Popover>
	)
}

export default UserSidebarItem
