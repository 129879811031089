import Loader from "@/components/Loader/Loader"
import { TabsContent } from "@/components/ui/tabs"
import { useFirebaseCtx } from "@/firebase"
import { LoadDashboardPortal } from "@/grafana"
import { logger } from "@/lib/utils"
import { trpc } from "@/trpc"
import { getSelectedOrg } from "@/utils/utils"
import { isEqual } from "lodash"
import { useEffect, useState, type FC } from "react"
import { useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"

export const OVERVIEW_DASHBOARD_UID = "ai-impact-dashboard"
export const TOOLS_DASHBOARD_UID = "d51c3b56-600d-4f25-80e7-cdc1726d13881"
export const IMPACT_DASHBOARD_UID = "impact"
export const ADOPTION_DASHBOARD_UID = "adoption"
export const SUGGESTION_BREAKDOWN_DASHBOARD_UID = "suggestion-breakdown"

const TEAM_VARIABLE = "var-team"
const TEAM_USERS_VARIABLE = "var-team_users"
const ORG_ID_VARIABLE = "var-org_id"

interface RenderDashboardProps {
	uid: string
	queryParams?: Record<string, unknown> | undefined
	hiddenVariables?: string[]
	metadata?: {
		teams: string[]
	}
}
export const RenderDashboard: FC<RenderDashboardProps> = ({
	uid,
	queryParams,
	hiddenVariables,
	metadata,
}) => (
	<LoadDashboardPortal
		{...{
			uid,
			slug: "code-rabbit",
			queryParams: {
				"var-org_id": getSelectedOrg()?.id ?? "",
				...queryParams,
			},
			hiddenVariables: [
				ORG_ID_VARIABLE,
				TEAM_USERS_VARIABLE,
				...(hiddenVariables ?? []),
			],
			metadata: {
				teams: [],
				...metadata,
			},
		}}
	/>
)

const useTeamUsersFilter = () => {
	const [search, setSearch] = useSearchParams()
	const [queryParams, setQueryParams] = useState<Record<string, unknown>>({})
	const utils = trpc.useUtils()
	const team = search.get(TEAM_VARIABLE)
	const { isAuthenticated: isBillingAdminLogin } = useFirebaseCtx()

	useEffect(() => {
		if (!team || isBillingAdminLogin) {
			return
		}

		const updatedSearch = new URLSearchParams(search)
		if (updatedSearch.has(TEAM_USERS_VARIABLE)) {
			updatedSearch.delete(TEAM_USERS_VARIABLE)
		}

		if (team === "All") {
			if (!isEqual(Object.fromEntries(updatedSearch), queryParams)) {
				setQueryParams(Object.fromEntries(updatedSearch))
				setSearch(updatedSearch)
			}
			return
		}

		void utils.grafana.getTeamMembersInGitProvider
			.fetch({
				teamName: team,
			})
			.then(({ data }) => {
				setQueryParams({
					...Object.fromEntries(updatedSearch),
					[TEAM_USERS_VARIABLE]: data.map(({ userName }) => userName),
				})
				setSearch(updatedSearch)
			})
			.catch(err => {
				logger.debug("Error fetching team members: ", err)
				toast.error("Error applying team filter")
			})
	}, [team, isBillingAdminLogin])

	return { queryParams, isBillingAdminLogin }
}

export const Dashboards: FC = () => {
	const { data, isLoading } = trpc.reporting.getTeams.useQuery({
		repo: undefined,
	})

	const { queryParams, isBillingAdminLogin } = useTeamUsersFilter()

	if (isLoading) {
		return <Loader />
	}

	const teamsMetadataAndQuery = !isBillingAdminLogin
		? {
				metadata: {
					teams: data?.data.teams.map(({ name }) => name) ?? [],
				},
				queryParams,
			}
		: {
				hiddenVariables: [TEAM_VARIABLE],
			}

	return (
		<>
			<TabsContent value="impact" className="m-[-12px] w-full">
				<RenderDashboard
					uid={IMPACT_DASHBOARD_UID}
					{...teamsMetadataAndQuery}
				/>
			</TabsContent>
			<TabsContent value="adoption" className="m-[-12px] w-full">
				<RenderDashboard
					uid={ADOPTION_DASHBOARD_UID}
					{...teamsMetadataAndQuery}
				/>
			</TabsContent>
			<TabsContent value="suggestion-breakdown" className="m-[-12px] w-full">
				<RenderDashboard
					uid={SUGGESTION_BREAKDOWN_DASHBOARD_UID}
					{...teamsMetadataAndQuery}
				/>
			</TabsContent>
		</>
	)
}
