import * as Sentry from "@sentry/react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import React, { createContext } from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import App from "./App"
import Loader from "./components/Loader/Loader"
import {
	useCheckProPlanLock,
	type CheckProPlanLockOutput,
} from "./components/Nav/nav-utils"
import ErrorBoundary from "./components/Sentry/Sentry"
import { TooltipProvider } from "./components/ui/tooltip"
import { FirebaseProvider } from "./firebase"
import "./index.css"
import { trpc, trpcClient } from "./trpc"
import { logout } from "./utils/logout"

export const PlanContext = createContext<{
	isLoading: boolean
	proPlanLock: boolean
	isError: boolean
	refetch: () => Promise<CheckProPlanLockOutput>
}>({
	refetch: () => Promise.resolve({ isLock: true }),
	isLoading: false,
	isError: false,
	proPlanLock: false,
})

const sampleRate = import.meta.env.MODE === "production" ? 0.2 : 1.0
Sentry.init({
	dsn: import.meta.env.VITE_SENTRY_DSN,
	replaysSessionSampleRate: 0.2,
	replaysOnErrorSampleRate: 1.0,
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration({
			maskAllInputs: true,
			maskAllText: false,
			blockAllMedia: false,
		}),
	],
	environment: import.meta.env.MODE || "production",
	// Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
	tracesSampleRate: sampleRate,
	tracePropagationTargets: [
		import.meta.env.VITE_API_URL,
		import.meta.env.VITE_BILLING_FUNC_URL,
		import.meta.env.VITE_CODERABBIT_FUNC_URL,
		import.meta.env.VITE_GRAFANA_API_URL,
	].filter(Boolean),
	ignoreErrors: [
		"Non-Error exception captured",
		"Non-Error promise rejection captured",
	],
	beforeSend: event => {
		if (window.location.hostname === "localhost") {
			return null
		}
		return event
	},
})

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: false,
			refetchOnWindowFocus: false,
		},
		mutations: {
			retry: false,
		},
	},
})

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.getElementById("root")!).render(
	<React.StrictMode>
		<ErrorBoundary>
			<ToastContainer />
			<trpc.Provider client={trpcClient} queryClient={queryClient}>
				<QueryClientProvider client={queryClient}>
					<BrowserRouter>
						<TooltipProvider delayDuration={0}>
							<FirebaseProvider>
								<AppWrapper />
							</FirebaseProvider>
						</TooltipProvider>
					</BrowserRouter>
					{import.meta.env.DEV && <ReactQueryDevtools initialIsOpen={false} />}
				</QueryClientProvider>
			</trpc.Provider>
		</ErrorBoundary>
	</React.StrictMode>,
)

function AppWrapper() {
	const { isLoading, proPlanLock, isError, refetch } = useCheckProPlanLock()

	if (isError) {
		// we can't figure out subscription details or what your plan
		// currently is.
		void logout()
	}

	if (isLoading) {
		return <Loader />
	}

	return (
		<PlanContext.Provider
			value={{
				isLoading,
				isError,
				refetch,
				proPlanLock,
			}}
		>
			<App />
		</PlanContext.Provider>
	)
}
