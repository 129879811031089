import React from "react"
import { Button } from "@/components/ui/button.tsx"
import { trpc } from "@/trpc.ts"
import Loader from "../../../components/Loader/Loader"
import { Link } from "react-router-dom"
import { Book } from "lucide-react"
import { Label } from "@/components/ui/label.tsx"
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form.tsx"
import { Input } from "@/components/ui/input.tsx"
import { useForm } from "react-hook-form"
import z from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { toast } from "react-toastify"

const formSchema = z.object({
	userToken: z.string().min(1, {
		message: "User token is required.",
	}),
	username: z.string().min(1, {
		message: "Username is required.",
	}),
})

const BitbucketUser: React.FC = () => {
	const bitbucketUserQuery = trpc.providers.getOrgUser.useQuery()
	const bitbucketUser = bitbucketUserQuery.data

	const form = useForm<z.infer>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			userToken: "",
			username: "",
		},
	})

	const saveBitbucketUserQuery = trpc.providers.saveOrgUser.useMutation({
		onSuccess: resp => {
			if (resp.success) {
				form.reset({
					userToken: "",
					username: "",
				})
				bitbucketUserQuery.remove()
				void bitbucketUserQuery.refetch()
			} else {
				toast.error("Failed to save Bitbucket user details")
			}
		},
		onError: () => {
			toast.error("Failed to save Bitbucket user details")
		},
	})

	const onSubmit = (values: z.infer) => {
		saveBitbucketUserQuery.mutate({
			userToken: values.userToken,
			username: values.username,
		})
	}

	return (
		<>
			{bitbucketUserQuery.isLoading && <Loader />}
			<div className="container mx-auto px-8 pt-7 pb-2">
				<div className="container mx-auto">
					<div>
						<div className="flex justify-between">
							<div>
								<div className="font-500 font-inter text-foreground mb-2 text-xl leading-8">
									Bitbucket user
								</div>
								<div className="w-full">
									<div className="flex w-full flex-row justify-between">
										<div className="w-1/2">
											<p className="text-muted-foreground mb-3 text-sm font-light">
												This integration enables CodeRabbit to access the
												Bitbucket Cloud API of the specified Bitbucket
												organization using an authenticated App Password. This
												token can be provided by a user from your organization.
											</p>
										</div>
									</div>
								</div>
							</div>
							<Button variant="outline" size="sm" asChild>
								<Link
									to="https://docs.coderabbit.ai/platforms/bitbucket-cloud"
									target="_blank"
								>
									<Book size={16} className="mr-2" />
									Documentation
								</Link>
							</Button>
						</div>

						<div className="mt-3 flex justify-between rounded-md border p-4">
							<div>
								<div className="font-500 font-inter text-foreground leading-5">
									Settings
								</div>
								<p className="text-muted-foreground text-sm font-light">
									Configure Bitbucket user
								</p>
							</div>
							<div className="w-1/2">
								<Form {...form}>
									<form
										onSubmit={e => {
											e.preventDefault()
										}}
										className="space-y-4"
									>
										<div className="space-x-2 p-2">
											<div className="flex items-center">
												<Label htmlFor="organization-user" className={"ml-6"}>
													Organization user (User ID:{" "}
													{bitbucketUser ? (
														<span className="text-crb-primary">
															{bitbucketUser}
														</span>
													) : (
														"None"
													)}
													)
												</Label>
											</div>
											<p className="text-muted-foreground mt-2 pl-4 text-xs">
												To interact with the bitbucketUser API, an App Password
												is required. This token can be generated by creating an
												App Password from one of your existing users.
											</p>
										</div>
										<div className="m-0 pl-8">
											<FormField
												control={form.control}
												name="username"
												render={({ field }) => (
													<FormItem>
														<FormLabel className="text-sm">Username</FormLabel>
														<FormControl>
															<Input
																placeholder="Enter username..."
																{...field}
															/>
														</FormControl>
														<FormMessage />
													</FormItem>
												)}
											/>
											<FormField
												control={form.control}
												name="userToken"
												render={({ field }) => (
													<FormItem>
														<FormLabel className="text-sm">
															App Password
														</FormLabel>
														<FormControl>
															<Input placeholder="Enter token..." {...field} />
														</FormControl>
														<FormMessage />
													</FormItem>
												)}
											/>
											<Button
												type="submit"
												className="mt-3"
												size="sm"
												onClick={async () => {
													await form.handleSubmit(onSubmit)()
												}}
												disabled={bitbucketUserQuery.isLoading}
											>
												Save
											</Button>
										</div>
									</form>
								</Form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default BitbucketUser
