import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import { getSelectedOrg } from "@/utils/utils"
import React, { useState } from "react"

interface PopupFormProps {
	readonly onClose: () => void
	readonly onSubmit: (data: FormData) => void
	readonly email: string
	readonly work: string | undefined
	readonly step: number
}

export interface FormData {
	readonly hearAbout: string
	readonly email: string
	readonly work?: string
	readonly specificSource?: string
}

const PopupForm: React.FC<PopupFormProps> = ({
	onClose,
	onSubmit,
	email: initialEmail,
	work: initialWork,
}) => {
	const account = getSelectedOrg()
	const isOrg = account?.type === "Organization"

	const [formData, setFormData] = useState<FormData>({
		hearAbout: "",
		specificSource: "",
		email: initialEmail,
		work: isOrg ? account.organization_name : initialWork || "",
	})

	const [hearAboutError, setHearAboutError] = useState<string | null>(null)
	const [emailError, setEmailError] = useState("")

	const handleChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
		field: keyof FormData,
	) => {
		const value = e.target.value
		if (field === "hearAbout") {
			if (value !== "Other") {
				setFormData({ ...formData, hearAbout: value, specificSource: "" })
			} else {
				setFormData({ ...formData, hearAbout: "Other", specificSource: "" })
			}
		} else {
			setFormData({ ...formData, [field]: value })
		}
	}

	const handleSubmit = () => {
		let isValid = true

		const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
		if (emailRegex.test(formData.email)) {
			setEmailError("")
		} else {
			setEmailError("Invalid email format")
			isValid = false
		}

		if (
			formData.hearAbout === "Other" &&
			(!formData.specificSource ||
				!/^[a-zA-Z\s]+$/.test(formData.specificSource))
		) {
			setHearAboutError("Please enter a valid source (e.g., Facebook, Google)")
			isValid = false
		} else if (formData.hearAbout === "") {
			setHearAboutError("Please select an option")
			isValid = false
		} else {
			setHearAboutError(null)
		}

		if (!isValid) {
			return
		}

		onSubmit(formData)
		onClose()
	}

	return (
		<Dialog open>
			<DialogContent className="min-h-[300px] w-full max-w-lg [&>button>svg]:hidden">
				<DialogHeader>
					<DialogTitle className="text-center text-xl font-semibold">
						Complete your signup
					</DialogTitle>
				</DialogHeader>
				<DialogDescription>
					<div className="space-y-6">
						{/* Email Section */}
						<div className="space-y-2">
							<label
								htmlFor="user-email"
								className="block font-medium text-gray-700"
							>
								Primary Email <span className="text-red-500">*</span>
							</label>
							<input
								id="user-email"
								className="w-full rounded-md border p-2 text-center focus:outline-hidden"
								type="email"
								value={formData.email}
								onChange={e => {
									handleChange(e, "email")
								}}
								required
								aria-label="Email input"
							/>
							{emailError && (
								<p className="text-sm text-red-500">{emailError}</p>
							)}
							<p className="text-sm text-gray-600">
								Please <strong>confirm</strong> the email address we should use
								for subscription-related communication. We{" "}
								<strong>recommend</strong> using a work email.
							</p>
						</div>

						<hr className="border-gray-200" />

						{/* Work Section */}
						<div className="space-y-2">
							<label htmlFor="work" className="block font-medium text-gray-700">
								{isOrg ? "Company Name" : "Where do you work?"}{" "}
								<span className="text-red-500">*</span>
							</label>
							<input
								id="work"
								className="w-full rounded-md border p-2 text-center focus:outline-hidden"
								type="text"
								value={formData.work || ""}
								onChange={e => {
									handleChange(e, "work")
								}}
								aria-label="Work input"
							/>
							<p className="text-sm text-gray-600">
								{isOrg
									? "Let us know your company (if applicable) to tailor our services for you."
									: "Share where you work (if applicable) to help us understand your background."}
							</p>
						</div>

						<hr className="border-gray-200" />

						{/* How did you hear about us Section */}
						<div className="space-y-2">
							<label
								htmlFor="hear-about"
								className="block font-medium text-gray-700"
							>
								How did you hear about us?{" "}
								<span className="text-red-500">*</span>
							</label>
							<select
								id="hear-about"
								className="w-full rounded-md border p-2 text-center focus:outline-hidden"
								value={formData.hearAbout}
								onChange={e => {
									handleChange(e, "hearAbout")
								}}
								required
							>
								<option value="">-- Select --</option>
								<option value="Open Source Project">Open Source Project</option>
								<option value="Stack Overflow">Stack Overflow</option>
								<option value="Referred">Referred by someone</option>
								<option value="Google Search">Google Search</option>
								<option value="Bing Search">Bing Search</option>
								<option value="Twitter">Twitter</option>
								<option value="LinkedIn">LinkedIn</option>
								<option value="Instagram">Instagram</option>
								<option value="Facebook">Facebook</option>
								<option value="Reddit">Reddit</option>
								<option value="YouTube">YouTube</option>
								<option value="Other">Other</option>
							</select>

							{formData.hearAbout === "Other" && (
								<div className="space-y-2">
									<label
										htmlFor="other-source"
										className="block font-medium text-gray-700"
									>
										Specific Source <span className="text-red-500">*</span>
									</label>
									<input
										id="other-source"
										className="w-full rounded-md border p-2 focus:outline-hidden"
										type="text"
										placeholder="Please specify"
										onChange={e => {
											handleChange(e, "specificSource")
										}}
										maxLength={25}
										required
										value={formData.specificSource}
										aria-label="Specific source input"
									/>
								</div>
							)}
							{hearAboutError && (
								<p className="text-sm text-red-500">{hearAboutError}</p>
							)}
						</div>

						<button
							className="bg-crb-primary w-full rounded px-4 py-2 text-white"
							onClick={handleSubmit}
						>
							Submit
						</button>
					</div>
				</DialogDescription>
			</DialogContent>
		</Dialog>
	)
}

export default PopupForm
