import { logger } from "@/lib/utils"
import { useCallback, useEffect, useState } from "react"

export function useGrowSurf() {
	const [isReady, setIsReady] = useState(false)

	useEffect(() => {
		if (window.growsurf) {
			setIsReady(true)
			return
		}

		function handleGrsfReady() {
			setIsReady(true)
		}
		window.addEventListener("grsfReady", handleGrsfReady)

		return () => {
			window.removeEventListener("grsfReady", handleGrsfReady)
		}
	}, [])

	const openGrowSurf = useCallback(() => {
		if (window.growsurf) {
			try {
				window.growsurf.open()
			} catch (error) {
				logger.error("Error opening GrowSurf:", error)
			}
		}
	}, [])

	return { isReady, openGrowSurf }
}
