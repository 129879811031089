import type { UserData } from "@/typings"
import { Box, Modal } from "@mui/material"

interface DeleteUserModalProps {
	open: boolean
	onOpenChange: (open: boolean) => void
	user: UserData | null
	onConfirm: () => void
	isLoading: boolean
}

export const DeleteUserModal: React.FC<DeleteUserModalProps> = ({
	open,
	onOpenChange,
	user,
	onConfirm,
	isLoading,
}) => {
	if (!user) return null

	return (
		<Modal
			open={open}
			onClose={() => {
				onOpenChange(false)
			}}
		>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					padding: 24,
					background: "white",
					borderRadius: 8,
					border: "1px solid white",
					boxShadow: 24,
					p: 4,
				}}
			>
				<div className="flex flex-col gap-4">
					<span className="text-xl leading-7 font-semibold">Delete User</span>
					<span className="font-poppins">
						Are you sure you want to delete {user.user_name} from the
						subscription? The user will be removed, but all associated product
						data will remain intact.
					</span>

					<div className="flex flex-row justify-end gap-3">
						<button
							className="rounded-[20px] border border-solid border-[#D1D1D1] px-4 py-2"
							disabled={isLoading}
							onClick={() => {
								onOpenChange(false)
							}}
						>
							Cancel
						</button>
						<button
							className="bg-destructive hover:bg-destructive/90 flex items-center justify-center gap-2 self-stretch rounded-2xl p-2 px-5 text-white"
							onClick={onConfirm}
							disabled={isLoading}
						>
							{isLoading ? "Deleting..." : "Delete"}
						</button>
					</div>
				</div>
			</Box>
		</Modal>
	)
}
