import { useFirebaseCtx } from "@/firebase/provider.js"
import { useProvider } from "@/utils/providers.js"
import React from "react"
import { LuArrowLeft } from "react-icons/lu"
import { useLocation } from "react-router"
import "../Referral/GrowSurf.css"
import MenuItem from "./MenuItem.js"
import { useAccountNavItems } from "./Nav.js"
import UserSidebarItem from "./UserSidebarItem.js"
import { checkIsActive, isNotHidden } from "./nav-utils.js"

interface NavSidebarAccountProps {
	isCollapsed: boolean
}

const NavSidebarAccount: React.FC<NavSidebarAccountProps> = ({
	isCollapsed,
}) => {
	const { pathname } = useLocation()
	const { isCRSelfHosted } = useProvider()
	const accountNavItems = useAccountNavItems()
	const { isAuthenticated: isFirebaseLogin } = useFirebaseCtx()

	return (
		<>
			{!isCRSelfHosted && (
				<div className="mt-3 w-full px-3.5">
					<MenuItem
						name="Back to organizations"
						Icon={({ className }) => <LuArrowLeft className={className} />}
						link={isFirebaseLogin ? "/learnings" : "/settings/repositories"}
						isCollapsed={isCollapsed}
					/>
					<div className="my-4 w-full border-b" />
				</div>
			)}

			{isCRSelfHosted && <div className="mt-4" />}

			{!isCollapsed && (
				<div className="font-500 mx-7 mt-1 mb-4 text-lg">Account Settings</div>
			)}

			{/* Menu Items */}
			<div
				className={`scrollbar-thin flex h-full flex-col justify-between ${
					isCollapsed ? "items-center" : "items-start"
				} mt-6 px-3 md:mt-0`}
			>
				<nav className={`flex-1 ${isCollapsed ? "" : "w-full"}`}>
					{accountNavItems.filter(isNotHidden).map(item => (
						<MenuItem
							key={item.name}
							name={item.name}
							Icon={item.Icon}
							link={item.link}
							newTab={!!item.newTab}
							items={item.items}
							isActive={checkIsActive(item, pathname)}
							isCollapsed={isCollapsed}
						/>
					))}
				</nav>

				{isCRSelfHosted && <UserSidebarItem isCollapsed={isCollapsed} />}
			</div>
		</>
	)
}

export default NavSidebarAccount
