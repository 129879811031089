import { Button } from "@/components/ui/button"
import {
	DropdownMenu,
	DropdownMenuCheckboxItem,
	DropdownMenuContent,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Skeleton } from "@/components/ui/skeleton"
import { trpc } from "@/trpc"
import type {
	PromptTemplate,
	ScheduleGroup,
} from "@/typings/githubActionsHandler"
import { ScheduleGroups } from "@/typings/githubActionsHandler"
import type { DataSources } from "@/utils/constant"
import { useProvider } from "@/utils/providers"
import { capitalizeFirstLetter } from "@/utils/utils"
import { ChevronDown } from "lucide-react"
import React, { useEffect, useMemo } from "react"
import { FaInfoCircle } from "react-icons/fa"
import { useNavigate } from "react-router"
import { toast } from "react-toastify"
import type { PromptTemplates } from "./prompt"
import { PROMPT_MAX_LENGTH } from "./prompt"

interface ReportingPromptProps {
	promptTemplate: PromptTemplate
	setPromptTemplate: React.Dispatch<React.SetStateAction<PromptTemplate>>
	prompt: string
	setPrompt: React.Dispatch<React.SetStateAction<string>>
	group: ScheduleGroup
	setGroup: React.Dispatch<React.SetStateAction<ScheduleGroup>>
	subgroup: ScheduleGroup
	setSubgroup: React.Dispatch<React.SetStateAction<ScheduleGroup>>
	dataSources: DataSources
	setDataSources: React.Dispatch<React.SetStateAction<DataSources>>
	disabled?: boolean
}

const ReportingPrompt: React.FC<ReportingPromptProps> = ({
	promptTemplate,
	setPromptTemplate,
	prompt,
	setPrompt,
	group,
	setGroup,
	subgroup,
	setSubgroup,
	dataSources,
	setDataSources,
	disabled,
}) => {
	const navigate = useNavigate()
	const { isGitlab } = useProvider()

	const scheduleGroups = useMemo(
		() => ScheduleGroups.filter(group => !(isGitlab && group === "TEAM")),
		[isGitlab],
	)

	const [promptTemplates, setPromptTemplates] = React.useState<PromptTemplates>(
		{},
	)

	function isPromptTemplate(
		value: string,
	): value is NonNullable<PromptTemplate> {
		return Object.keys(promptTemplates).includes(value)
	}

	const getReportingPrompts = trpc.reporting.getReportingPrompts.useQuery()

	useEffect(() => {
		if (getReportingPrompts.data) {
			setPromptTemplates(getReportingPrompts.data.data.promptTemplates)
		}
	}, [getReportingPrompts.data])

	useEffect(() => {
		if (getReportingPrompts.error) {
			if (getReportingPrompts.error.data?.httpStatus === 401) {
				// User does not have a subscription and hence cannot create/edit reports
				navigate("/reports")
				return
			}

			toast.error(
				"Failed to fetch prompt templates: " +
					getReportingPrompts.error.message,
			)
		}
	}, [getReportingPrompts.error])

	useEffect(() => {
		if (!prompt && promptTemplate && promptTemplate in promptTemplates) {
			setPrompt(promptTemplates[promptTemplate] || "")
		}
	}, [promptTemplates, promptTemplate])

	// Update effect to sync prompt text with both checkboxes
	useEffect(() => {
		const hasBotCommentsTag = prompt.includes("<include_bot_comments>")
		const hasIssuesAndTicketsTag = prompt.includes(
			"<include_issues_and_tickets>",
		)

		if (
			hasBotCommentsTag !== dataSources.botComments ||
			hasIssuesAndTicketsTag !== dataSources.issuesAndTickets
		) {
			setDataSources(prev => ({
				...prev,
				botComments: hasBotCommentsTag,
				issuesAndTickets: hasIssuesAndTicketsTag,
			}))
		}
	}, [prompt])

	const isLoading = getReportingPrompts.isLoading

	return (
		<div>
			<div>
				<div className="font-500 font-poppins mt-2">Prompt</div>
				<div className="font-poppins text-muted-foreground max-w-lg text-sm">
					Adjust the AI prompt to your liking for personalizing the report.
				</div>
			</div>

			<div className="my-4">
				<div>
					<div className="font-500 font-poppins text-sm">Prompt template</div>
					{isLoading && <Skeleton className="mt-1 h-8 w-1/2" />}
					{!isLoading && (
						<select
							className="mt-1 w-full rounded-lg border border-gray-300 bg-white p-2 sm:w-1/2"
							value={promptTemplate}
							onChange={event => {
								const value = event.target.value
								if (isPromptTemplate(value)) {
									setPromptTemplate(value)
									const promptTemplateText = promptTemplates[value]
									if (promptTemplateText != null) {
										setPrompt(promptTemplateText)
									}
								}
							}}
							disabled={disabled}
						>
							{Object.keys(promptTemplates).map(prompt => (
								<option key={prompt} value={prompt}>
									{prompt}
								</option>
							))}
						</select>
					)}
				</div>
				<div className="mt-5">
					<div className="font-500 font-poppins text-sm">Prompt</div>
					{isLoading && <Skeleton className="mt-1 h-32 w-full" />}
					{!isLoading && (
						<textarea
							disabled={disabled}
							className="mt-1 w-full rounded-lg border border-gray-300 p-2"
							rows={7}
							value={prompt}
							onChange={event => {
								if (promptTemplates["Custom"] != null) {
									setPromptTemplate("Custom")
								}
								setPrompt(event.target.value)
							}}
							maxLength={PROMPT_MAX_LENGTH}
						/>
					)}
					{!isLoading && (
						<div className="mx-1 flex gap-1 text-xs text-gray-500">
							<FaInfoCircle className="mt-0.5 shrink-0 text-gray-400" />
							{
								"{{today}} in the prompt will be replaced by the date the report is generated at"
							}
						</div>
					)}
				</div>
				<div className="mt-5">
					<div className="font-500 font-poppins text-sm">Group by</div>
					<select
						className="mt-1 w-full rounded-lg border border-gray-300 bg-white p-2 sm:w-1/2"
						value={group}
						onChange={event => {
							setGroup(event.target.value as typeof group)
						}}
					>
						{scheduleGroups.map(group => (
							<option key={group} value={group}>
								{capitalizeFirstLetter(group.toLowerCase())}
							</option>
						))}
					</select>
				</div>
				{group !== "NONE" && (
					<div className="mt-5">
						<div className="font-500 font-poppins text-sm">Subgroup</div>
						<select
							className="mt-1 w-full rounded-lg border border-gray-300 bg-white p-2 sm:w-1/2"
							value={subgroup}
							onChange={event => {
								setSubgroup(event.target.value as typeof subgroup)
							}}
						>
							{scheduleGroups
								.filter(g => g !== group)
								.map(group => (
									<option key={group} value={group}>
										{capitalizeFirstLetter(group.toLowerCase())}
									</option>
								))}
						</select>
					</div>
				)}
				{promptTemplate === "Custom" && (
					<div className="mt-5">
						<div className="font-500 font-poppins text-sm">Data Sources</div>
						<div className="font-poppins text-muted-foreground mb-2 max-w-lg text-sm">
							Include or exclude different data sources to control what
							information the reporting AI uses. See the{" "}
							<a
								href="https://docs.coderabbit.ai/guides/custom-reports#available-data-points"
								target="_blank"
								rel="noopener noreferrer"
								className="text-primary hover:underline"
							>
								available data points
							</a>
							.
						</div>
						<DropdownMenu>
							<DropdownMenuTrigger asChild disabled={disabled}>
								<Button variant="outline" className="mt-1 w-full sm:w-1/2">
									Select Optional Data Sources
									<ChevronDown className="ml-2 h-4 w-4" />
								</Button>
							</DropdownMenuTrigger>
							<DropdownMenuContent className="w-56">
								<DropdownMenuCheckboxItem
									checked={dataSources.botComments}
									onCheckedChange={checked => {
										setDataSources(prev => ({ ...prev, botComments: checked }))
										if (checked && !prompt.includes("<include_bot_comments>")) {
											setPrompt(prev => `${prev}\n<include_bot_comments>`)
										} else if (!checked) {
											setPrompt(prev =>
												prev.replace(/<include_bot_comments>/g, "").trim(),
											)
										}
									}}
								>
									Bot Comments
								</DropdownMenuCheckboxItem>
								<DropdownMenuCheckboxItem
									checked={dataSources.issuesAndTickets}
									onCheckedChange={checked => {
										setDataSources(prev => ({
											...prev,
											issuesAndTickets: checked,
										}))
										if (
											checked &&
											!prompt.includes("<include_issues_and_tickets>")
										) {
											setPrompt(prev => `${prev}\n<include_issues_and_tickets>`)
										} else if (!checked) {
											setPrompt(prev =>
												prev
													.replace(/<include_issues_and_tickets>/g, "")
													.trim(),
											)
										}
									}}
								>
									Issues and Tickets
								</DropdownMenuCheckboxItem>
							</DropdownMenuContent>
						</DropdownMenu>
					</div>
				)}
			</div>
		</div>
	)
}

export default ReportingPrompt
