import { Button } from "@/components/ui/button"
import { Separator } from "@/components/ui/separator.js"
import { trpc, type RouterInputs } from "@/trpc.js"
import type { SelfHostedDomainData } from "@/typings"
import { formatErrorDetails } from "@/utils/error-logs.js"
import { PROVIDERS } from "@/utils/providers"
import { sentryCaptureException } from "@/utils/utils.ts"
import { useState } from "react"
import { FaTimes } from "react-icons/fa"
import { SiGithub } from "react-icons/si"
import { useNavigate } from "react-router"
import InputBox from "../../components/InputBox/InputBox.js"
import Loader from "../../components/Loader/Loader.js"
import LogoFull from "../../svg/logo-full/index.js"
import { FooterText } from "./FooterText"
import { GITHUB_SELF_HOSTED_LS_KEY, getSelfHostedData } from "./selfhosted.js"

const GitHubSelfHostedLogin: React.FC = () => {
	const selfHostedData = getSelfHostedData(PROVIDERS.GITHUB_SELF_HOSTED)
	const navigate = useNavigate()
	const queryParams = new URLSearchParams(location.search)
	const configureInstance =
		queryParams.has("configure") && selfHostedData?.hostURL

	const [loader, setLoader] = useState<boolean>(false)
	const [loaderMessage, setLoaderMessage] = useState<string>("")
	const [message, setMessage] = useState<string>()
	const [askForToken, setAskForToken] = useState<boolean>(!!configureInstance)
	const [hostURL, setHostURL] = useState<string>(selfHostedData?.hostURL ?? "")
	const [oauthClientId, setOauthClientId] = useState<string>("")
	const [oauthClientSecret, setOauthClientSecret] = useState<string>("")
	const [ghAppId, setGhAppId] = useState<string>("")
	const [ghAppClientId, setGhAppClientId] = useState<string>("")
	const [ghAppClientSecret, setGhAppClientSecret] = useState<string>("")
	const [ghAppWebhookSecret, setGhAppWebhookSecret] = useState<string>("")
	const [ghAppPrivateKey, setGhAppPrivateKey] = useState<string>("")

	const trpcUtils = trpc.useUtils()

	function processUrl(url: string) {
		const withoutProtocol = url.replace(/^https?:\/\//, "")
		const withoutWww = withoutProtocol.replace(/^www\./, "")
		const withoutCom = withoutWww.replace(/\.com$/, "")
		return withoutCom
	}

	async function getSelfHostedInstance(hostURL: string) {
		return trpcUtils.selfhosted.getSelfHostedInstance.fetch({
			hostURL,
			provider: PROVIDERS.GITHUB_SELF_HOSTED,
		})
	}

	async function addSelfHostedGitHub() {
		const body: RouterInputs["selfhosted"]["addSelfHostedInstance"] =
			configureInstance
				? {
						updateInstance: true,
						hostURL,
						provider: PROVIDERS.GITHUB_SELF_HOSTED,
						clientId: oauthClientId,
						clientSecret: oauthClientSecret,
					}
				: {
						hostURL,
						clientId: oauthClientId,
						clientSecret: oauthClientSecret,
						provider: PROVIDERS.GITHUB_SELF_HOSTED,
						ghAppId,
						ghAppClientId,
						ghAppClientSecret,
						ghAppPrivateKey,
						ghAppWebhookSecret,
					}

		setLoaderMessage("Setting up your organization...")

		return trpcUtils.client.selfhosted.addSelfHostedInstance
			.mutate(body)
			.catch(error => {
				const errorDetails = formatErrorDetails(error)
				sentryCaptureException("addSelfHostedGitHub API failed: ", errorDetails)
				return {
					isSuccess: false,
					data: null,
					message: `Something went wrong: ${error}`,
				}
			})
	}

	function processSelfHostedData(selfHostedGitHubData: SelfHostedDomainData) {
		// save in local storage
		const data = {
			hostURL: selfHostedGitHubData.hostURL,
			clientId: selfHostedGitHubData.clientId,
			scope: selfHostedGitHubData.scope,
			redirectURI: selfHostedGitHubData.redirectURI,
			orgName: processUrl(selfHostedGitHubData.hostURL),
		}
		localStorage.setItem(GITHUB_SELF_HOSTED_LS_KEY, JSON.stringify(data))
		window.location.assign(
			`${data.hostURL}/login/oauth/authorize?client_id=${data.clientId}&scope=${data.scope}&response_type=code&redirect_uri=${data.redirectURI}&state=${PROVIDERS.GITHUB_SELF_HOSTED}`,
		)
	}

	function loginWithSelfHostedGitHub() {
		const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i
		const isValidUrl = urlRegex.test(hostURL)

		if (!isValidUrl) {
			setMessage("Invalid URL, please enter a valid URL with http/https")
			return
		}

		if (askForToken && !configureInstance) {
			// Validate input values
			if (!oauthClientId || !oauthClientSecret) {
				setMessage(
					"Please enter the OAuth Client ID and the OAuth Client Secret",
				)
				return
			}

			if (!ghAppId) {
				setMessage("Please enter the GitHub App ID")
				return
			}

			if (!ghAppClientId || !ghAppClientSecret) {
				setMessage(
					"Please enter the GitHub App Client ID and the GitHub App Client Secret",
				)
				return
			}

			if (!ghAppPrivateKey) {
				setMessage("Please enter the GitHub App Private Key")
				return
			}

			if (!ghAppWebhookSecret) {
				setMessage("Please enter the GitHub App Webhook Secret")
				return
			}
		}

		setLoader(true)
		setLoaderMessage("Checking for your organization in our database... 🔍")
		setMessage(undefined)
		getSelfHostedInstance(hostURL)
			.then(async response => {
				if (response.isSuccess && response.data) {
					// We already have the self hosted instance in our DB

					if (!configureInstance) {
						// Proceed with usual login if the user isn't configuring the instance
						processSelfHostedData(response.data)
						return
					}

					// Update the self hosted instance with the new client id and secret
					const selfHostedGitHubData = await addSelfHostedGitHub()
					if (!selfHostedGitHubData.isSuccess) {
						setMessage(
							"message" in selfHostedGitHubData
								? selfHostedGitHubData.message
								: "Something went wrong, please try again!",
						)
						setLoader(false)
						return
					}

					if ("data" in selfHostedGitHubData && selfHostedGitHubData.data) {
						// Proceed with login using the updated instance credentials
						processSelfHostedData(selfHostedGitHubData.data)
						return
					}

					setLoader(false)
					setMessage("")
					return
				}

				// If we are here, the host url of the self-hosted instace is not in our DB

				setAskForToken(true)

				if (askForToken) {
					const selfHostedGitHubData = await addSelfHostedGitHub()
					if (!selfHostedGitHubData.data || !selfHostedGitHubData.isSuccess) {
						setMessage(
							"message" in selfHostedGitHubData
								? selfHostedGitHubData.message
								: "Something went wrong, please try again!",
						)
						setLoader(false)
					} else if (
						"data" in selfHostedGitHubData &&
						selfHostedGitHubData.data
					) {
						processSelfHostedData(selfHostedGitHubData.data)
					}
				} else {
					setMessage(
						"Your organization is not registered with us. Please provide the necessary details to proceed with the installation.",
					)
					setLoader(false)
				}
			})
			.catch(error => {
				setMessage(`Something went wrong, please try again! ${error}`)
				setLoader(false)
			})
	}

	function clearGitHubSelfHostedData() {
		localStorage.removeItem(GITHUB_SELF_HOSTED_LS_KEY)
		navigate("/login/github-self-hosted", { replace: true })
		setAskForToken(false)
		setHostURL("")
		setMessage("")
		setOauthClientId("")
		setOauthClientSecret("")
		setGhAppId("")
		setGhAppClientId("")
		setGhAppClientSecret("")
		setGhAppPrivateKey("")
		setGhAppWebhookSecret("")
	}

	return (
		<>
			{loader ? (
				<Loader message={loaderMessage} />
			) : (
				<div className="bg-secondary flex min-h-screen flex-col items-center justify-center">
					<div className="relative mx-4 mt-4 flex max-w-xl flex-col items-center rounded-md bg-white text-center shadow-lg sm:mx-auto">
						<div className="px-6 py-16 sm:px-12">
							<div
								className="flex items-center justify-center pb-5 hover:cursor-pointer"
								onClick={() => {
									navigate("/login")
								}}
							>
								<LogoFull width={171} />
							</div>
							<div className="font-figtreeRegular mb-12">
								Connect your Self Hosted GitHub Enterprise with CodeRabbit and
								get code reviews right away. Refer to the step by step guide
								here{" "}
								<u>
									<a
										href="https://docs.coderabbit.ai/integrations/self-hosted-github"
										target="_blank"
										className="text-crb-primary-dark"
										rel="noreferrer"
									>
										CodeRabbit in Self Managed GitHub
									</a>
								</u>{" "}
							</div>
							<div>
								<div className="relative">
									<InputBox
										title="Hosted GitHub URL"
										value={hostURL}
										disabled={!!configureInstance}
										description="Domain url of your self hosted GitHub instance. Example: https://example.com"
										onChange={event => {
											setHostURL(event.target.value)
										}}
									/>
									{configureInstance && (
										<FaTimes
											className="text-muted-foreground absolute right-3 bottom-[14px] text-xs transition-colors hover:cursor-pointer hover:text-black"
											title="Clear"
											onClick={clearGitHubSelfHostedData}
										/>
									)}
								</div>

								{message ? (
									<div
										className="font-figtreeRegular mt-4 mb-4 w-full rounded-lg bg-red-50 p-4 text-sm [word-break:break-word] text-red-800"
										role="alert"
									>
										{message}
									</div>
								) : null}

								{askForToken ? (
									<div className="mt-4">
										<div className="mt-8">
											<h4 className="text-md my-4 scroll-m-20 text-left font-semibold tracking-tight">
												OAuth App
											</h4>

											<div className="mb-5">
												<InputBox
													title="Client ID"
													value={oauthClientId}
													placeholder={
														configureInstance
															? "Enter the new Client ID"
															: "Client ID"
													}
													description="OAuth client ID, used for the user login process."
													onChange={event => {
														setOauthClientId(event.target.value)
													}}
													disabled={!askForToken}
												/>
											</div>
											<div className="mb-5">
												<InputBox
													title="Client secret"
													value={oauthClientSecret}
													placeholder={
														configureInstance
															? "Enter the new Client Secret"
															: "Client Secret"
													}
													description="OAuth client secret, used for the user login process."
													onChange={event => {
														setOauthClientSecret(event.target.value)
													}}
													disabled={!askForToken}
												/>
											</div>
										</div>

										{!configureInstance && (
											<>
												<Separator className="my-4" />

												<div className="mt-8">
													<h4 className="text-md my-4 scroll-m-20 text-left font-semibold tracking-tight">
														GitHub App
													</h4>
													<div className="mb-5">
														<InputBox
															title="App ID"
															value={ghAppId}
															placeholder="App ID"
															description="GitHub App ID, used for authenticating with octokit."
															onChange={event => {
																setGhAppId(event.target.value)
															}}
															disabled={!askForToken}
														/>
													</div>
													<div className="mb-5">
														<InputBox
															title="Client ID"
															value={ghAppClientId}
															placeholder="Client ID"
															description="GitHub App Client ID, not to be confused with OAuth client ID."
															onChange={event => {
																setGhAppClientId(event.target.value)
															}}
															disabled={!askForToken}
														/>
													</div>
													<div className="mb-5">
														<InputBox
															title="Client secret"
															value={ghAppClientSecret}
															placeholder="Client Secret"
															description="GitHub App Client Secret, not to be confused with OAuth client secret."
															onChange={event => {
																setGhAppClientSecret(event.target.value)
															}}
															disabled={!askForToken}
														/>
													</div>
													<div className="mb-5">
														<InputBox
															title="Webhook Secret"
															value={ghAppWebhookSecret}
															placeholder="Webhook Secret"
															description="GitHub App Webhook Secret, used for authenticating webhook requests."
															onChange={event => {
																setGhAppWebhookSecret(event.target.value)
															}}
															disabled={!askForToken}
														/>
													</div>
													<div className="mb-5">
														<InputBox
															title="Private Key"
															value={ghAppPrivateKey}
															placeholder="Private Key"
															description="GitHub App Private Key, used for authenticating with octokit."
															onChange={event => {
																setGhAppPrivateKey(event.target.value)
															}}
															disabled={!askForToken}
															textarea
														/>
													</div>
												</div>
											</>
										)}
									</div>
								) : null}
							</div>

							<Button
								onClick={() => {
									loginWithSelfHostedGitHub()
								}}
								variant="outline"
								className="text-secondary-foreground mt-5 w-full max-w-80"
							>
								<SiGithub className="mr-2 shrink-0" size={20} />
								{configureInstance ? "Update" : "Submit"}
							</Button>
						</div>
					</div>

					<FooterText />
				</div>
			)}
		</>
	)
}

export default GitHubSelfHostedLogin
