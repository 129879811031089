import { Button } from "@/components/ui/button"
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { zodResolver } from "@hookform/resolvers/zod"
import React from "react"
import { useForm } from "react-hook-form"
import z from "zod"

const formSchema = z.object({
	hostURL: z.string().url("Invalid hostURL"),
	pat: z.string().min(2, {
		message: "Invalid Personal Access Token",
	}),
})

interface JiraSelfHostedModalProps {
	readonly open: boolean
	readonly onOpenChange: (open: boolean) => void
	readonly onSaveCreds: (hostURL: string, pat: string) => void
}

const JiraSelfHostedModal: React.FC<JiraSelfHostedModalProps> = ({
	open,
	onOpenChange,
	onSaveCreds,
}) => {
	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			hostURL: "",
			pat: "",
		},
	})

	const handleSubmit = (data: z.infer<typeof formSchema>) => {
		onSaveCreds(data.hostURL, data.pat)
	}

	return (
		<Dialog open={open} onOpenChange={onOpenChange}>
			<DialogContent
				onPointerDownOutside={e => {
					e.preventDefault()
				}}
				className="sm:max-w-lg"
			>
				<DialogHeader>
					<DialogTitle>
						Integrate with Jira Data Center (Self-Hosted)
					</DialogTitle>
				</DialogHeader>

				<form onSubmit={form.handleSubmit(handleSubmit)} className="mt-2">
					<div className="pb-4">
						<Label htmlFor="hostURL" className="font-inter">
							Host URL
						</Label>
						<Input
							id="hostURL"
							placeholder="Jira Data Center Instance URL"
							{...form.register("hostURL")}
							className={`mt-0.5 ${
								form.formState.errors.hostURL
									? "border-red-500"
									: "border-gray-300"
							}`}
							autoComplete="off"
						/>
						{form.formState.errors.hostURL && (
							<p className="font-inter text-destructive mt-1 text-sm">
								{form.formState.errors.hostURL.message}
							</p>
						)}
					</div>
					<div className="pb-4">
						<Label htmlFor="pat" className="font-inter">
							Personal Access Token
						</Label>
						<Input
							id="pat"
							placeholder="Jira Data Center Personal Access Token"
							{...form.register("pat")}
							className={`mt-0.5 ${
								form.formState.errors.pat ? "border-red-500" : "border-gray-300"
							}`}
							autoComplete="off"
						/>
						{form.formState.errors.pat && (
							<p className="font-inter text-destructive mt-1 text-sm">
								{form.formState.errors.pat.message}
							</p>
						)}
						<div className="mt-1 flex gap-1 text-xs text-gray-500">
							Ensure that the Personal Access Token belongs to a user with
							permissions to access tickets and manage webhooks
						</div>
					</div>
					<DialogFooter>
						<DialogClose asChild>
							<Button type="button" variant="secondary">
								Cancel
							</Button>
						</DialogClose>
						<Button
							className="font-inter px-4 py-2 font-semibold"
							type="submit"
						>
							Submit
						</Button>
					</DialogFooter>
				</form>
			</DialogContent>
		</Dialog>
	)
}

export default JiraSelfHostedModal
