import { logger } from "@/lib/utils"
import { trpc } from "@/trpc"
import { formatErrorDetails } from "@/utils/error-logs"
import { sentryCaptureException } from "@/utils/utils"
import { useState } from "react"
import type { SidebarMenuItem } from "./Nav"
import { useSelectedOrg } from "./useSelectedOrg"

export function isNotHidden(
	item: NonNullable<SidebarMenuItem["items"]>[number] | SidebarMenuItem,
): boolean {
	return !item.hide
}

export function checkIsActive(
	item: SidebarMenuItem,
	pathname: string,
): boolean {
	if (typeof item.link === "string" && pathname.startsWith(item.link)) {
		return true
	}

	for (const altItem of item.alt ?? []) {
		if (pathname.startsWith(altItem.link)) {
			return true
		}
	}

	for (const subItem of item.items ?? []) {
		if (pathname.startsWith(subItem.link)) {
			return true
		}
	}

	return false
}

export interface CheckProPlanLockOutput {
	readonly isLock: boolean
}

interface ProPlanLockResult {
	readonly isLoading: boolean
	readonly proPlanLock: boolean
	readonly isError: boolean
	readonly refetch: () => Promise<CheckProPlanLockOutput>
}

interface RetryOptions {
	readonly enabled?: boolean
	readonly maxAttempts?: number
	readonly delayMs?: number
}

/**
 * useCheckProPlanLock accepts an optional retryOptions parameter.
 * When retryOptions.enabled is true, the hook will poll the endpoint
 * with exponential backoff until the lock is removed (or until maxAttempts is reached).
 * Once the lock is removed, it stops further API calls.
 */
export const useCheckProPlanLock = (
	retryOptions?: RetryOptions,
): ProPlanLockResult => {
	const selectedOrg = useSelectedOrg()
	// Count the number of polling attempts
	const [attempt, setAttempt] = useState(0)
	// Controls whether to continue polling
	const [shouldPoll, setShouldPoll] = useState(true)

	const queryResult = trpc.subscriptions.checkProPlanLock.useQuery(undefined, {
		enabled: !!selectedOrg?.id,
		staleTime: 0,
		refetchOnWindowFocus: false,
		refetchInterval:
			retryOptions?.enabled &&
			shouldPoll &&
			attempt < (retryOptions.maxAttempts ?? 5)
				? (retryOptions.delayMs ?? 2000) * Math.pow(2, attempt)
				: false,
		onSuccess: data => {
			if (retryOptions?.enabled) {
				if (data.isLock) {
					setAttempt(prev => prev + 1)
				} else {
					setAttempt(0)
					setShouldPoll(false)
				}
			}
		},
		onError: error => {
			setAttempt(0)
			setShouldPoll(false)

			const errorDetails = formatErrorDetails(error)
			sentryCaptureException(
				`Subscription: checkProPlanLock API failed: ${error.message}`,
				errorDetails,
			)
			logger.error(
				`Error while fetching plan details for orgId: ${selectedOrg?.id}`,
				{ errorDetails },
			)
		},
	})

	const refetch = async (): Promise<CheckProPlanLockOutput> => {
		const result = await queryResult.refetch()
		return { isLock: result.data?.isLock ?? true }
	}

	return {
		isLoading: queryResult.isLoading && !!selectedOrg,
		proPlanLock: queryResult.data?.isLock ?? true,
		isError: queryResult.isError,
		refetch,
	}
}
