import { useCheckProPlanLock } from "@/components/Nav/nav-utils"
import { useSelectedOrg } from "@/components/Nav/useSelectedOrg"
import { trpc } from "@/trpc"
import type { SubscriptionCustomer } from "@/typings/billingHandler.ts"
import { useAllOrgs } from "@/utils/getAllOrgs"
import { useProvider } from "@/utils/providers"
import "material-react-toastify/dist/ReactToastify.css"
import React, { createContext, useMemo } from "react"
import { useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import type { UserData } from "../../../typings"
import { sentryCaptureException } from "../../../utils/utils"
import { SeatManagementDetails } from "./components/SeatManagementDetails"
import { SeatManagementHeader } from "./components/SeatManagementHeader"
import { StartFreeTrial } from "./components/StartFreeTrial"
import { UserList } from "./components/UserList"
import { checkAdmin } from "./helpers"

type SubscriptionHistory = SubscriptionCustomer

interface SubscriptionContextProps {
	subscriptionHistory: SubscriptionHistory | undefined
	seatsInfo: SeatsInfo | null | undefined
	isAdmin: boolean
	proPlanLock: boolean
}

export const SubscriptionContext = createContext<SubscriptionContextProps>({
	subscriptionHistory: undefined,
	seatsInfo: null,
	isAdmin: false,
	proPlanLock: true,
})

// TODO: Replace with types from trpc using RouterOutput
export interface SeatsInfo {
	readonly limit: number
	readonly subscriptionId: number
	readonly usersData: UserData[]
	readonly userId?: number
	readonly joinImmediate: boolean
}

const SeatManagement: React.FC = () => {
	const { provider, isSelfHosted, isCRSelfHosted } = useProvider()

	const selectedOrg = useSelectedOrg()

	const userId = sessionStorage.getItem("user_id")
	const selfHostedDomain = sessionStorage.getItem("selfHostedDomain")

	const { organizations } = useAllOrgs()

	const {
		data: subscriptionHistory,
		isLoading: subscriptionsLoading,
		isError: subscriptionsError,
	} = trpc.billing.fetchSubscriptionHistory.useQuery(undefined, {
		enabled: !!selectedOrg?.id,
		onError: error => {
			if (error.data?.code != "NOT_FOUND") {
				toast.error(
					"Failed to retrieve subscription history. Please try again or Contact support.",
				)
				sentryCaptureException(
					"fetchData: API failed on seat management page: ",
					{ message: error.message, data: error.data },
				)
			}
		},
		select: data => {
			if (!data.data) return undefined
			return data.data
		},
	})

	const isSubscriptionError = subscriptionsError

	const { data: isUserInstanceAdmin } = trpc.users.isUserInstanceAdmin.useQuery(
		{
			provider: provider || "",
			user_id: userId || "",
			selfHostedDomain:
				selfHostedDomain && isSelfHosted ? selfHostedDomain : undefined,
		},
		{
			enabled: !!(selfHostedDomain && isSelfHosted),
			select: data => data.isAdmin,
		},
	)

	const getAllMembers = trpc.organization_members.getAllMembers.useQuery(
		undefined,
		{
			enabled: !isCRSelfHosted || organizations.length > 0,
			select: data => {
				return {
					limit: data.data.num_seats,
					subscriptionId: data.data.subscriptionId,
					usersData: data.data.usersData,
					userId: Number(userId),
					joinImmediate: data.data.joinImmediate,
				} satisfies SeatsInfo
			},
		},
	)

	const isAdminOverride = useMemo(() => {
		const overrideRole = getAllMembers.data?.usersData.find(
			user =>
				user.user_id &&
				(user.user_id.toString() === sessionStorage.getItem("user_id") ||
					user.isCurrentUser),
		)?.override_role

		// If the user has an override role of ADMIN or SUPER_ADMIN, they are an admin
		if (overrideRole === "ADMIN" || overrideRole === "SUPER_ADMIN") {
			return true
		}

		return false
	}, [getAllMembers.data])

	const selfHostedAdminOrOrgRoleAdmin = checkAdmin({
		isAdmin: isUserInstanceAdmin,
		selectedOrg,
	})

	const isAdmin = selfHostedAdminOrOrgRoleAdmin || isAdminOverride

	const isLoading = subscriptionsLoading || getAllMembers.isLoading
	const location = useLocation()
	const searchParams = new URLSearchParams(location.search)
	const trialId = searchParams.get("id")
	const state = searchParams.get("state")
	const retryEnabled = Boolean(trialId && state === "succeeded")

	// Use exponential backoff (via retries) only in this special case.
	// if this fails, main.ts handles the error state, so the app does not re-render
	const { proPlanLock } = useCheckProPlanLock({
		enabled: retryEnabled,
		maxAttempts: 4,
		delayMs: 2000,
	})

	return (
		<SubscriptionContext.Provider
			value={{
				subscriptionHistory,
				seatsInfo: getAllMembers.data,
				isAdmin,
				proPlanLock,
			}}
		>
			<div className="bg-background relative container mx-auto px-8 pt-7 pb-2">
				{isSubscriptionError ? (
					<div className="w-full">
						<h1 className="font-inter text-xl font-semibold text-black">
							Subscription
						</h1>
						<StartFreeTrial />
					</div>
				) : null}

				<div>
					{!isSubscriptionError && (
						<>
							<SeatManagementHeader
								isLoading={isLoading}
								subscriptionHistory={subscriptionHistory}
							/>

							<SeatManagementDetails isLoading={isLoading} />
							<UserList isLoading={isLoading} />
						</>
					)}
				</div>
			</div>
		</SubscriptionContext.Provider>
	)
}
export default SeatManagement
