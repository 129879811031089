import {
	Tabs,
	TabsContent,
	TabsList,
	TabsTrigger,
} from "@/components/ui/tabs.tsx"
import { ENABLED_GRAFANA } from "@/grafana"
import { getSelectedOrg } from "@/utils/utils"
import { useMemo } from "react"
import {
	Dashboards,
	OVERVIEW_DASHBOARD_UID,
	RenderDashboard,
	TOOLS_DASHBOARD_UID,
} from "./Dashboard"
import { DashboardPageWrapper, LegacyDashboard } from "./LegacyDashboard"

export default function Dashboard() {
	const org = getSelectedOrg()
	if (!org) return null

	const isGithub = org.provider.toLowerCase().includes("github")

	const tabList = useMemo(
		() =>
			!isGithub
				? [
						{
							title: "Overview",
							id: "overview",
						},
						{
							title: "Tools",
							id: "tools",
						},
					]
				: [
						{
							title: "PR Activity",
							id: "impact",
						},
						{
							title: "CodeRabbit Adoption",
							id: "adoption",
						},
						{
							title: "CodeRabbit Findings",
							id: "suggestion-breakdown",
						},
					],
		[isGithub],
	)

	const tabs = useMemo(
		() =>
			!isGithub ? (
				<>
					<TabsContent value="overview" className="m-[-12px] w-full">
						<RenderDashboard uid={OVERVIEW_DASHBOARD_UID} />
					</TabsContent>
					<TabsContent value="tools" className="m-[-12px] w-full">
						<RenderDashboard uid={TOOLS_DASHBOARD_UID} />
					</TabsContent>
				</>
			) : (
				<Dashboards />
			),
		[isGithub],
	)

	return (
		<>
			{ENABLED_GRAFANA ? (
				<DashboardPageWrapper>
					<Tabs
						defaultValue={isGithub ? "impact" : "overview"}
						className="w-full"
					>
						<div className="flex w-full items-center justify-between">
							<TabsList className="mb-3">
								{tabList.map(tab => (
									<TabsTrigger key={tab.id} value={tab.id}>
										{tab.title}
									</TabsTrigger>
								))}
							</TabsList>
						</div>
						{tabs}
					</Tabs>
				</DashboardPageWrapper>
			) : (
				<LegacyDashboard />
			)}
		</>
	)
}
