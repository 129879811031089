import { useEffect, useRef, useState } from "react"

export const LazyImage = ({
	src,
	alt,
	className,
}: {
	src: HTMLImageElement["src"]
	alt: HTMLImageElement["alt"]
	className?: HTMLImageElement["className"]
}) => {
	const [isLoaded, setIsLoaded] = useState(false)
	const [isVisible, setIsVisible] = useState(false)
	const imgRef = useRef<HTMLDivElement | null>(null)

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry?.isIntersecting) {
					setIsVisible(true)
					observer.disconnect()
				}
			},
			{ threshold: 0.1 },
		)

		if (imgRef.current) {
			observer.observe(imgRef.current)
		}

		return () => {
			observer.disconnect()
		}
	}, [])

	return (
		<div ref={imgRef} className={`relative w-full ${className}`}>
			{!isLoaded && (
				<div className="absolute inset-0 flex animate-pulse items-center justify-center bg-gray-300"></div>
			)}
			{isVisible && (
				<img
					src={src}
					alt={alt}
					className={`w-full transition-opacity duration-500 ease-in-out ${
						isLoaded ? "opacity-100" : "opacity-0"
					}`}
					onLoad={() => {
						setIsLoaded(true)
					}}
				/>
			)}
		</div>
	)
}
