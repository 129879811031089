import type { Table } from "@tanstack/react-table"

import { Button } from "@/components/ui/button"
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select"
import { useMemo } from "react"
import {
	HiChevronDoubleLeft,
	HiChevronDoubleRight,
	HiChevronLeft,
	HiChevronRight,
} from "react-icons/hi"

interface DataTablePaginationProps<TData> {
	table: Table<TData>
}

export function DataTablePagination<TData>({
	table,
}: DataTablePaginationProps<TData>) {
	const currentPageSize = table.getState().pagination.pageSize
	const pageSizeList = useMemo(
		() =>
			Array.from(new Set([currentPageSize, 10, 20, 30, 40, 50])).sort(
				(a, b) => a - b,
			),
		[currentPageSize],
	)

	return (
		<div className="flex items-center justify-between px-2">
			<div className="text-muted-foreground flex-1 text-sm">
				{table.getSelectedRowModel().rows.length > 0 && (
					<>{table.getSelectedRowModel().rows.length} row(s) selected.</>
				)}
			</div>
			<div className="flex flex-row flex-wrap-reverse justify-end gap-x-6 gap-y-2">
				<div className="flex items-center gap-2">
					<p className="text-sm">Rows per page</p>
					<Select
						value={`${table.getState().pagination.pageSize}`}
						onValueChange={value => {
							table.setPageSize(Number(value))
						}}
					>
						<SelectTrigger className="h-8 w-[70px]">
							<SelectValue placeholder={table.getState().pagination.pageSize} />
						</SelectTrigger>
						<SelectContent side="top">
							{pageSizeList.map(pageSize => (
								<SelectItem key={pageSize} value={`${pageSize}`}>
									{pageSize}
								</SelectItem>
							))}
						</SelectContent>
					</Select>
				</div>
				<div className="flex gap-2">
					<div className="flex items-center text-sm sm:justify-center">
						Page {table.getState().pagination.pageIndex + 1} of{" "}
						{table.getPageCount()}
					</div>
					<div className="flex items-center gap-2">
						<Button
							variant="outline"
							className="hidden h-8 w-8 p-0 lg:flex"
							onClick={() => {
								table.setPageIndex(0)
							}}
							disabled={!table.getCanPreviousPage()}
						>
							<HiChevronDoubleLeft className="h-4 w-4" />
						</Button>
						<Button
							variant="outline"
							className="h-8 w-8 p-0"
							onClick={() => {
								table.previousPage()
							}}
							disabled={!table.getCanPreviousPage()}
						>
							<HiChevronLeft className="h-4 w-4" />
						</Button>
						<Button
							variant="outline"
							className="h-8 w-8 p-0"
							onClick={() => {
								table.nextPage()
							}}
							disabled={!table.getCanNextPage()}
						>
							<HiChevronRight className="h-4 w-4" />
						</Button>
						<Button
							variant="outline"
							className="hidden h-8 w-8 p-0 lg:flex"
							onClick={() => {
								table.setPageIndex(table.getPageCount() - 1)
							}}
							disabled={!table.getCanNextPage()}
						>
							<HiChevronDoubleRight className="h-4 w-4" />
						</Button>
					</div>
				</div>
			</div>
		</div>
	)
}
