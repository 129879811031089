import type { SupportedProviders } from "../types"
import { bitBucketConfig } from "./bitbucket"

export const config = {
	bitbucket: bitBucketConfig,
	// add more services here.
}

export const getConfig = ({ service }: { service: SupportedProviders }) => {
	return config[service]
}
