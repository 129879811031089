import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert.tsx"
import { Button } from "@/components/ui/button"
import { Separator } from "@/components/ui/separator.tsx"
import {
	Tabs,
	TabsContent,
	TabsList,
	TabsTrigger,
} from "@/components/ui/tabs.tsx"
import { trpc, type RouterInputs } from "@/trpc.js"
import type { SelfHostedDomainData } from "@/typings"
import { formatErrorDetails } from "@/utils/error-logs.js"
import { PROVIDERS } from "@/utils/providers"
import { sentryCaptureException } from "@/utils/utils.ts"
import { useState } from "react"
import { FaTimes } from "react-icons/fa"
import { SiGitlab } from "react-icons/si"
import { useNavigate } from "react-router"
import InputBox from "../../components/InputBox/InputBox.js"
import Loader from "../../components/Loader/Loader.js"
import LogoFull from "../../svg/logo-full/index.js"
import { FooterText } from "./FooterText"
import { GITLAB_SELF_HOSTED_LS_KEY, getSelfHostedData } from "./selfhosted.js"

const GitLabSelfHostedLogin: React.FC = () => {
	const selfHostedData = getSelfHostedData(PROVIDERS.GITLAB_SELF_HOSTED)
	const navigate = useNavigate()
	const queryParams = new URLSearchParams(location.search)
	const configureInstance =
		queryParams.has("configure") && selfHostedData?.hostURL

	const [loader, setLoader] = useState<boolean>(false)
	const [loaderMessage, setLoaderMessage] = useState<string>("")
	const [askForToken, setAskForToken] = useState<boolean>(!!configureInstance)
	const [hostURL, setHostURL] = useState<string>(selfHostedData?.hostURL ?? "")
	const [message, setMessage] = useState<string>()
	const [adminToken, setAdminToken] = useState<string>("")
	const [userToken, setUserToken] = useState<string>("")
	const [clientId, setClientId] = useState<string>("")
	const [clientSecret, setClientSecret] = useState<string>("")

	const trpcUtils = trpc.useUtils()

	function processUrl(url: string) {
		const withoutProtocol = url.replace(/^https?:\/\//, "")
		const withoutWww = withoutProtocol.replace(/^www\./, "")
		const withoutCom = withoutWww.replace(/\.com$/, "")
		return withoutCom
	}

	async function getSelfHostedInstance(hostURL: string) {
		return trpcUtils.selfhosted.getSelfHostedInstance.fetch({
			hostURL,
			provider: PROVIDERS.GITLAB_SELF_HOSTED,
		})
	}

	async function addSelfHostedGitlab() {
		const body: RouterInputs["selfhosted"]["addSelfHostedInstance"] =
			configureInstance
				? {
						updateInstance: true,
						hostURL,
						provider: PROVIDERS.GITLAB_SELF_HOSTED,
						clientId,
						clientSecret,
					}
				: {
						hostURL,
						adminToken,
						userToken,
						clientId,
						clientSecret,
						provider: PROVIDERS.GITLAB_SELF_HOSTED,
					}

		setLoaderMessage("Setting up your organization...")

		return trpcUtils.client.selfhosted.addSelfHostedInstance
			.mutate(body)
			.catch(error => {
				const errorDetails = formatErrorDetails(error)
				sentryCaptureException("addSelfHostedGitlab API failed: ", errorDetails)
				return {
					isSuccess: false,
					data: null,
					message: `Something went wrong: ${error}`,
				}
			})
	}

	function processSelfHostedData(selfHostedGitlabData: SelfHostedDomainData) {
		// save in local storage
		const data = {
			hostURL: selfHostedGitlabData.hostURL,
			clientId: selfHostedGitlabData.clientId,
			scope: selfHostedGitlabData.scope,
			redirectURI: selfHostedGitlabData.redirectURI,
			orgName: processUrl(selfHostedGitlabData.hostURL),
		}
		localStorage.setItem(GITLAB_SELF_HOSTED_LS_KEY, JSON.stringify(data))
		window.location.assign(
			`${data.hostURL}/oauth/authorize?client_id=${data.clientId}&scope=${data.scope}&response_type=code&redirect_uri=${data.redirectURI}&state=${PROVIDERS.GITLAB_SELF_HOSTED}`,
		)
	}

	function loginWithSelfHostedGitlab() {
		const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i
		const isValidUrl = urlRegex.test(hostURL)

		if (isValidUrl) {
			setLoader(true)
			setLoaderMessage("Checking for your organization in our database... 🔍")
			setMessage(undefined)
			getSelfHostedInstance(hostURL)
				.then(async response => {
					if (response.isSuccess && response.data) {
						// We already have the self hosted instance in our DB

						if (!configureInstance) {
							// Proceed with usual login if the user isn't configuring the instance
							processSelfHostedData(response.data)
							return
						}

						// Update the self hosted instance with the new client id and secret
						const selfHostedGitlabData = await addSelfHostedGitlab()
						if (!selfHostedGitlabData.isSuccess) {
							setMessage(
								"message" in selfHostedGitlabData
									? selfHostedGitlabData.message
									: "Something went wrong, please try again!",
							)
							setLoader(false)
							return
						}

						if ("data" in selfHostedGitlabData && selfHostedGitlabData.data) {
							// Proceed with login using the updated instance credentials
							processSelfHostedData(selfHostedGitlabData.data)
							return
						}

						setLoader(false)
						setMessage("")
						return
					}

					// If we are here, the host url of the self-hosted instace is not in our DB

					setAskForToken(true)
					if (
						!configureInstance &&
						// Case 1: userToken is present but any of userId, clientId, or clientSecret is missing
						((userToken && (!clientId || !clientSecret)) ||
							// Case 2: clientId is present but any of userId, userToken, or clientSecret is missing
							(clientId && (!userToken || !clientSecret)) ||
							// Case 3: clientSecret is present but any of userId, userToken, or clientId is missing
							(clientSecret && (!userToken || !clientId)) ||
							// Case 4: All four (userId, userToken, clientId, and clientSecret) are missing and adminToken is also missing
							(!userToken && !clientId && !clientSecret && !adminToken))
					) {
						setLoader(false)
						setMessage(() => {
							return "Your organization is not registered with us. Please provide the necessary details to proceed with the installation."
						})
					} else {
						const selfHostedGitlabData = await addSelfHostedGitlab()
						if (!selfHostedGitlabData.data || !selfHostedGitlabData.isSuccess) {
							setMessage(
								"message" in selfHostedGitlabData
									? selfHostedGitlabData.message
									: "Something went wrong, please try again!",
							)
							setLoader(false)
						} else if (
							"data" in selfHostedGitlabData &&
							selfHostedGitlabData.data
						) {
							processSelfHostedData(selfHostedGitlabData.data)
						}
					}
				})
				.catch(error => {
					setMessage(`Something went wrong, please try again! ${error}`)
				})
		} else {
			// Display an error message for an invalid URL
			setMessage("Invalid URL, please enter a valid URL with http/https")
		}
		return
	}

	function clearGitLabSelfHostedData() {
		localStorage.removeItem(GITLAB_SELF_HOSTED_LS_KEY)
		navigate("/login/gitlab-self-hosted", { replace: true })
		setAskForToken(false)
		setHostURL("")
		setMessage("")
		setAdminToken("")
		setUserToken("")
		setClientId("")
		setClientSecret("")
	}

	return (
		<>
			{loader ? (
				<Loader message={loaderMessage} />
			) : (
				<div className="bg-secondary flex min-h-screen flex-col items-center justify-center">
					<div className="relative mx-4 mt-4 flex max-w-xl flex-col items-center rounded-md bg-white text-center shadow-lg sm:mx-auto">
						<div className="px-6 py-16 sm:px-12">
							<div
								className="flex items-center justify-center pb-5 hover:cursor-pointer"
								onClick={() => {
									navigate("/login")
								}}
							>
								<LogoFull width={171} />
							</div>
							<div className="font-figtreeRegular mb-12">
								Connect your Self Managed GitLab with CodeRabbit and get code
								reviews right away. Refer to step by step guide here{" "}
								<u>
									<a
										href="https://docs.coderabbit.ai/integrations/self-hosted-gitlab"
										target="_blank"
										className="text-crb-primary-dark"
										rel="noreferrer"
									>
										CodeRabbit in Self Managed GitLab
									</a>
								</u>{" "}
							</div>
							<div>
								<div className="relative">
									<InputBox
										title="Hosted GitLab URL"
										value={hostURL}
										disabled={!!configureInstance}
										description="Domain url of your self hosted GitLab instance. Example: https://example.com"
										onChange={event => {
											setHostURL(event.target.value)
										}}
									/>
									{configureInstance && (
										<FaTimes
											className="text-muted-foreground absolute right-3 bottom-[14px] text-xs transition-colors hover:cursor-pointer hover:text-black"
											title="Clear"
											onClick={clearGitLabSelfHostedData}
										/>
									)}
								</div>

								{message ? (
									<div
										className="font-figtreeRegular mt-4 mb-4 w-full rounded-lg bg-red-50 p-4 text-sm [word-break:break-word] text-red-800"
										role="alert"
									>
										{message}
									</div>
								) : null}

								{askForToken ? (
									<div className="mt-4">
										<Tabs defaultValue={adminToken ? "automated" : "manual"}>
											{!configureInstance && (
												<TabsList>
													<TabsTrigger value="manual" disabled={!!adminToken}>
														Manual
													</TabsTrigger>
													<TabsTrigger
														value="automated"
														disabled={!!(userToken || clientSecret || clientId)}
													>
														Automated
													</TabsTrigger>
												</TabsList>
											)}
											<TabsContent value="manual">
												<div className="mt-8">
													{!configureInstance && (
														<>
															<h4 className="text-md mt-8 mb-4 scroll-m-20 text-left font-semibold tracking-tight">
																CodeRabbit User
															</h4>
															<div className="mb-4">
																<InputBox
																	title="Access token"
																	value={userToken}
																	description="Personal access token of the CodeRabbit user in self-managed GitLab instance. Example: glpat-sddf-00xx_xxxxxxx"
																	onChange={event => {
																		setUserToken(event.target.value)
																	}}
																	disabled={!askForToken}
																/>
															</div>
															<Separator className="my-4" />
														</>
													)}
													<h4 className="text-md my-4 scroll-m-20 text-left font-semibold tracking-tight">
														OAuth App
													</h4>
													<div className="mb-4">
														<InputBox
															title="Client ID"
															value={clientId}
															description="OAuth application ID, used for the user login process."
															placeholder={
																configureInstance
																	? "Enter the new Client ID"
																	: "OAuth Application Client ID"
															}
															onChange={event => {
																setClientId(event.target.value)
															}}
															disabled={!askForToken}
														/>
													</div>
													<div className="mb-4">
														<InputBox
															title="Client secret"
															value={clientSecret}
															description="OAuth application client secret used for the user login process."
															placeholder={
																configureInstance
																	? "Enter the new Client Secret"
																	: "OAuth Application Client Secret"
															}
															onChange={event => {
																setClientSecret(event.target.value)
															}}
															disabled={!askForToken}
														/>
													</div>
												</div>
											</TabsContent>
											<TabsContent value="automated">
												<div className="mb-4">
													<InputBox
														title="Admin Access token"
														value={adminToken}
														description="Personal access token of the admin of the self-managed GitLab instance. Example: glpat-sddf-00xx_xxxxxxx"
														onChange={event => {
															setAdminToken(event.target.value)
														}}
														disabled={!askForToken}
													/>
												</div>
												<div className="text-left">
													<Alert>
														<AlertTitle>Note</AlertTitle>
														<AlertDescription>
															An admin access token is used to create the
															CodeRabbit user and the OAuth application within
															the self-hosted instance. After the installation
															process, the token is no longer needed. We
															recommend setting a short expiration time for the
															token, as it is only needed for a few minutes.
														</AlertDescription>
													</Alert>
												</div>
											</TabsContent>
										</Tabs>
									</div>
								) : (
									""
								)}
							</div>

							<Button
								onClick={() => {
									loginWithSelfHostedGitlab()
								}}
								variant="outline"
								className="text-secondary-foreground mt-5 w-full max-w-80"
							>
								<SiGitlab className="mr-2 shrink-0" size={20} />
								{configureInstance ? "Update" : "Submit"}
							</Button>
						</div>
					</div>

					<FooterText />
				</div>
			)}
		</>
	)
}

export default GitLabSelfHostedLogin
