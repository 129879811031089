import { trpc, type RouterOutputs } from "@/trpc"
import { useState } from "react"
import { useNavigate } from "react-router"
import { toast } from "react-toastify"
import { formatErrorDetails } from "./error-logs"
import { logout } from "./logout"
import { navigateToLastRoute } from "./navigate-to-last-route"
import { useProvider } from "./providers"
import { handleSessionExpiration } from "./session"
import {
	getSelectedOrg,
	isTRPCClientError,
	sentryCaptureException,
} from "./utils"
type Organization = RouterOutputs["organizations"]["getAllOrgs"]["data"][number]

/**
 * Get all orgs
 */
export function useAllOrgs() {
	const navigate = useNavigate()
	const trpcUtils = trpc.useUtils()
	const { provider, isSelfHosted, isGitHub } = useProvider()
	const [organizations, setOrganizations] = useState<Organization[]>([])

	async function fetchOrganizations(trigger?: boolean) {
		const user_id = sessionStorage.getItem("user_id")
		const user_name = sessionStorage.getItem("login")
		const org_id = sessionStorage.getItem("org_id")
		const selfHostedDomain = sessionStorage.getItem("selfHostedDomain")
		const selected_org = getSelectedOrg()

		if (!user_name || !user_id || !provider) {
			await logout()
			return
		}

		// if we have the org_id and selected_org in session storage and trigger is not true then skip
		if (org_id && selected_org && !trigger) return

		try {
			const result = await trpcUtils.organizations.getAllOrgs.fetch({
				user_name,
				user_id,
				provider,
				selfHostedDomain:
					selfHostedDomain && isSelfHosted ? selfHostedDomain : undefined,
				provider_org_id: selected_org?.provider_organization_id,
			})

			if (!result.isSuccess) {
				throw new Error("Failed to fetch orgs")
			}

			const orgs = result.data
			setOrganizations(orgs)
			sessionStorage.setItem("org_info", JSON.stringify(orgs))
			updateSessionStorageWithSelectedOrg(orgs)
			return organizations
		} catch (e) {
			if (isTRPCClientError(e) && e.data?.code === "UNAUTHORIZED") {
				handleSessionExpiration(navigate)
				return
			}
			const errorDetails = formatErrorDetails(e)
			sentryCaptureException(
				"Integrations: getAllOrgs API failed: ",
				errorDetails,
			)
			toast.error("Something went wrong, Please try again")
			return
		}
	}

	function updateSessionStorageWithSelectedOrg(organizations: Organization[]) {
		const selectedOrg = organizations.find(item => {
			if (isGitHub) {
				return item.hasAccess && !item.parentId
			}
			return !item.parentId
		})

		if (!sessionStorage.getItem("org_id") && selectedOrg) {
			sessionStorage.setItem("org_id", selectedOrg.id)
		}
		if (!sessionStorage.getItem("selected_org") && selectedOrg) {
			sessionStorage.setItem("selected_org", JSON.stringify(selectedOrg))
			window.dispatchEvent(new CustomEvent("selected_org_changed"))
		}
		if (sessionStorage.getItem("selected_org")) {
			const selectedOrg = getSelectedOrg()

			const foundOrg = organizations.find(
				item =>
					item.provider_organization_id ==
					selectedOrg?.provider_organization_id,
			)
			if (foundOrg) {
				sessionStorage.setItem("org_id", foundOrg.id)
				sessionStorage.setItem("selected_org", JSON.stringify(foundOrg))
				window.dispatchEvent(new CustomEvent("selected_org_changed"))
			}
			navigateToLastRoute()
		}
	}

	return {
		organizations,
		fetchOrganizations,
	}
}
