import { SiAzuredevops, SiGithub, SiGitlab } from "react-icons/si"
import { useSessionStorage } from "usehooks-ts"

export const PROVIDERS = {
	GITHUB: "github",
	GITLAB: "gitlab",
	GITHUB_SELF_HOSTED: "github-self-hosted",
	GITLAB_SELF_HOSTED: "gitlab-self-hosted",
	AZURE_DEVOPS: "azure-devops",
	BITBUCKET: "bitbucket",
} as const

export type Provider = (typeof PROVIDERS)[keyof typeof PROVIDERS]

export const useProvider = () => {
	const [provider, setProvider] = useSessionStorage<Provider | null>(
		"provider",
		null,
	)

	const isGitHubCloud = provider === PROVIDERS.GITHUB
	const isGitHubSelfHosted = provider === PROVIDERS.GITHUB_SELF_HOSTED
	const isGitHub = isGitHubCloud || isGitHubSelfHosted

	const isGitlabSelfHosted = provider === PROVIDERS.GITLAB_SELF_HOSTED
	const isGitlabCloud = provider === PROVIDERS.GITLAB
	const isGitlab = isGitlabCloud || isGitlabSelfHosted

	const isAzureDevops = provider === PROVIDERS.AZURE_DEVOPS
	const isBitbucket = provider === PROVIDERS.BITBUCKET
	const isSelfHosted = isGitHubSelfHosted || isGitlabSelfHosted // GitHub/GitLab Self Hosted
	const isCRSelfHosted = sessionStorage.getItem("isCRSelfHosted") === "true" // CodeRabbit Self Hosted

	const getProviderIcon = ({
		size = 36,
		className = "rounded-full shrink-0",
	} = {}) => {
		let ProviderIcon

		if (isGitHub) ProviderIcon = SiGithub
		if (isGitlab) ProviderIcon = SiGitlab
		if (isAzureDevops) ProviderIcon = SiAzuredevops

		if (!ProviderIcon) return null

		return <ProviderIcon size={size} className={className} />
	}

	return {
		provider,
		setProvider,
		isGitHub,
		isGitHubCloud,
		isGitHubSelfHosted,
		isGitlab,
		isGitlabCloud,
		isGitlabSelfHosted,
		isAzureDevops,
		isBitbucket,
		isSelfHosted,
		isCRSelfHosted,
		getProviderIcon,
	}
}
