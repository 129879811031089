import { Input } from "@/components/ui/input.tsx"
import { Textarea } from "@/components/ui/textarea.tsx"
import { useState, type FC, type ReactNode } from "react"

interface TextInputSectionProps {
	readonly label: string
	readonly id: string
	readonly name: string
	readonly value: string
	readonly onChange: (
		e:
			| React.ChangeEvent<HTMLInputElement>
			| React.ChangeEvent<HTMLTextAreaElement>,
	) => void
	readonly text: ReactNode
	readonly isTextArea?: boolean
	readonly editable?: boolean
	readonly maxLength?: number
	readonly placeholder?: string
	readonly required?: boolean
	readonly disabled?: boolean
}

const TextInputSection: FC<TextInputSectionProps> = ({
	label,
	id,
	name,
	text,
	value,
	onChange,
	maxLength,
	isTextArea = false,
	placeholder,
	required,
	disabled,
}) => {
	const [error, setError] = useState(false)

	const handleChange = (
		e:
			| React.ChangeEvent<HTMLInputElement>
			| React.ChangeEvent<HTMLTextAreaElement>,
	) => {
		if (maxLength && e.target.value.length > maxLength) {
			setError(true)
		} else {
			setError(false)
			onChange(e)
		}
	}

	return (
		<div
			className={`grid gap-4 pt-2 pb-2 sm:flex sm:flex-col lg:grid lg:grid-cols-2`}
		>
			<div>
				<div
					className="font-500 font-inter flex gap-3 leading-5 not-italic"
					id="label-email"
				>
					<div className="font-inter flex-1">
						{label}
						{required && <span className="text-red-500">*</span>}
						{text && (
							<p className="text-muted-foreground max-w-3xl pt-1 text-sm leading-[20px] font-light not-italic">
								{text}
							</p>
						)}
					</div>
				</div>

				{error && (
					<p className="pt-1 text-xs leading-4 font-normal text-red-500 not-italic">
						Input exceeds maximum length of {maxLength} characters
					</p>
				)}
			</div>
			<div className="mt-4 sm:mt-0">
				<div className="flex w-full flex-col">
					<div className="relative flex flex-col items-start">
						{!isTextArea ? (
							<Input
								onChange={handleChange}
								id={id}
								name={name}
								type="text"
								value={value}
								disabled={disabled}
								placeholder={placeholder}
							/>
						) : (
							<Textarea
								onChange={handleChange}
								id={id}
								name={name}
								rows={5}
								value={value}
								disabled={disabled}
								placeholder={placeholder}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default TextInputSection
