import { cn } from "@/lib/utils"
import { omit } from "lodash"
import debounce from "lodash.debounce"
import { SearchIcon } from "lucide-react"
import { useCallback, useState, type InputHTMLAttributes } from "react"
import { CgSpinner } from "react-icons/cg"

interface SearchProps {
	defaultValue?: string
	onSearch?: (query: string) => Promise<void> | void
	debounce?: boolean
	placeholder?: string
	showLabel?: boolean
	fullWidth?: boolean
	inputProps?: InputHTMLAttributes<HTMLInputElement>
	label?: string
	disabled?: boolean
}

const Search: React.FC<SearchProps> = ({
	defaultValue = "",
	onSearch,
	debounce: useDebounce = false,
	placeholder = "Search...",
	showLabel = true,
	fullWidth = false,
	inputProps,
	label = "Search",
	disabled = false,
}) => {
	const [searchQuery, setSearchQuery] = useState<string>(defaultValue)
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const performSearch = (query: string) => {
		if (onSearch) {
			setIsLoading(true)

			const result = onSearch(query)
			if (result instanceof Promise) {
				void result.finally(() => {
					setIsLoading(false)
				})
			} else {
				setIsLoading(false)
			}
		}
	}

	const debounceSearch = useCallback(
		useDebounce ? debounce(performSearch, 1000) : performSearch,
		[useDebounce, onSearch],
	)

	const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const query = e.target.value
		setSearchQuery(query)
		debounceSearch(query)
	}

	return (
		<div
			className={cn("relative w-full max-w-80", fullWidth ? "max-w-full" : "")}
		>
			{showLabel && (
				<div className="font-poppins text-crb-text-secondary text-sm">
					{label}
				</div>
			)}
			<div
				className={cn("relative flex items-center", fullWidth ? "w-full" : "")}
			>
				<SearchIcon
					className="text-muted-foreground pointer-events-none absolute top-0 left-2.5 h-full"
					size={16}
				/>

				<input
					type="text"
					placeholder={placeholder}
					value={searchQuery}
					onChange={handleSearchChange}
					aria-label="Search"
					disabled={disabled}
					className={cn(
						`font-figtreeRegular flex-1 rounded-md border border-gray-300 bg-white py-2 pr-3 pl-9 shadow-sm transition placeholder:text-gray-400 focus:ring-4 focus:ring-gray-300 focus:outline-hidden`,
						inputProps?.className,
						disabled && "cursor-not-allowed",
					)}
					{...omit(inputProps, "className")}
				/>

				{isLoading && (
					<CgSpinner
						className="text-muted-foreground pointer-events-none absolute top-0 right-2 h-full animate-spin items-center"
						size={20}
					/>
				)}
			</div>
		</div>
	)
}

export default Search
