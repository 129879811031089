export const FooterText: React.FC = () => {
	return (
		<p className="font-400 text-muted-foreground relative z-30 m-3 mb-5 text-center text-xs">
			By continuing, you agree to the{" "}
			<a
				href="https://coderabbit.ai/terms-of-service"
				target="_blank"
				className="text-crb-primary-dark hover:underline"
				rel="noreferrer"
				aria-label="Terms of Use (opens in new tab)"
			>
				Terms of Use
			</a>{" "}
			and{" "}
			<a
				href="https://coderabbit.ai/privacy-policy"
				target="_blank"
				className="text-crb-primary-dark hover:underline"
				rel="noreferrer"
				aria-label="Privacy Policy (opens in new tab)"
			>
				Privacy Policy
			</a>{" "}
			applicable to CodeRabbit
		</p>
	)
}
