import { OnboardingModal } from "@/components/OnboardingWizard/components/Modal"
import { Button } from "@/components/ui/button"
import { Book } from "lucide-react"
import { forwardRef, useImperativeHandle, useState } from "react"

interface RefActions {
	setOpen: (isOpen: boolean) => void
}

interface BitbucketInstallationHelpProps {
	onComplete?: (isComplete: boolean) => void
}

// TODO: Make it a generic modal (OnboardingInstructionsModal).
export const BitbucketInstallationHelp = forwardRef<
	RefActions,
	BitbucketInstallationHelpProps
>(({ onComplete }, ref) => {
	const [open, setOpen] = useState(true)

	useImperativeHandle(
		ref,
		() => ({
			setOpen: (isOpen: boolean) => {
				setOpen(isOpen)
			},
		}),
		[],
	)

	return (
		<>
			<OnboardingModal
				open={open}
				onOpenChange={setOpen}
				onComplete={() => {
					setOpen(false)
					if (onComplete) {
						onComplete(true)
					}
				}}
				service={"bitbucket"}
				trigger={
					<Button variant="outline" className="bg-background gap-2 px-4">
						<Book size={14} />
						Installation Guide
					</Button>
				}
			/>
		</>
	)
})
