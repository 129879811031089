import { Button } from "@/components/ui/button"
import { trpc } from "@/trpc"
import type { Dispatch, FC, SetStateAction } from "react"
import { toast } from "react-toastify"

interface DeleteLearningProps {
	id: string
	setShowDeleteLearning: Dispatch<SetStateAction<boolean>>
}

export const DeleteLearning: FC<DeleteLearningProps> = ({
	setShowDeleteLearning,
	id,
}) => {
	const { mutate: deleteLearnings, isLoading } =
		trpc.learnings.deleteLearnings.useMutation()

	const utils = trpc.useUtils()

	const deleteLearning = () => {
		deleteLearnings(
			{ ids: [id] },
			{
				onSuccess: async () => {
					toast.success("Learning deleted successfully")
					await utils.learnings.getAllLearnings.invalidate().catch(e => {
						console.error(e)
					})
					setShowDeleteLearning(false)
				},
				onError: () => {
					toast.error("Failed to delete learning")
				},
			},
		)
	}

	return (
		<div className="relative flex w-full flex-col justify-between">
			<div>
				<p className="pb-2 text-lg font-semibold">Delete Learning ?</p>
				<p className="text-muted-foreground text-sm">
					Are you sure you want to delete this learning?
				</p>
			</div>
			<div className="mt-3 flex items-center justify-end gap-3">
				<Button
					type="button"
					variant="outline"
					disabled={isLoading}
					color="error"
					onClick={() => {
						setShowDeleteLearning(false)
					}}
				>
					Cancel
				</Button>
				<Button disabled={isLoading} onClick={deleteLearning}>
					Delete
				</Button>
			</div>
		</div>
	)
}
