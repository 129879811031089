import { firebaseAuth, SESSION_STORAGE_TENANT_ID } from "@/firebase"
import { logger } from "@/lib/utils"

export async function logout(): Promise<void> {
	const isFirebase = !!sessionStorage.getItem(SESSION_STORAGE_TENANT_ID)

	try {
		if (typeof window.ko?.reset === "function") {
			window.ko.reset()
		}

		if (typeof window.posthog?.reset === "function") {
			window.posthog.reset()
		}
	} catch (error) {
		logger.error("Error calling window.ko.reset:", error)
	}

	sessionStorage.clear()
	window.localStorage.clear()

	window.localStorage.setItem("last_route", window.location.pathname)
	logger.info("logging out....")

	if (isFirebase) {
		await firebaseAuth.signOut().catch(() => {
			logger.error("Failed to sign out firebase, redirecting to login")
		})

		logger.info("Signed out firebase")
		window.location.assign("/sign-in-with-email")

		return
	}

	window.history.replaceState(null, "", "/login")
	window.location.href = "/login"
}
