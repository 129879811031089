import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	BreadcrumbList,
	BreadcrumbPage,
	BreadcrumbSeparator,
} from "@/components/ui/breadcrumb.tsx"
import { Button } from "@/components/ui/button.tsx"
import { Card, CardContent } from "@/components/ui/card.tsx"
import { Form as FormComponent } from "@/components/ui/form.tsx"
import {
	Tabs,
	TabsContent,
	TabsList,
	TabsTrigger,
} from "@/components/ui/tabs.tsx"
import type {
	CRHandlerUpdateOrgLevelSettingsBody,
	CRHandlerUpdateOrgLevelSettingsResp,
	CRHandlerUpdateReposSettingsBody,
} from "@/typings/coderabbitHandler.ts"
import { sentryCaptureException } from "@/utils/utils.ts"
import type Form from "@rjsf/core"
import type { IChangeEvent } from "@rjsf/core"
import type { AxiosResponse } from "axios"
import axios from "axios"
import { createRef, useCallback, useMemo, useState, type FC } from "react"
import { useNavigate } from "react-router-dom"
import RightIcon from "../../../assets/right-icon.svg"
import SettingsToggleSwitch from "../../../components/ToggleSwitch/SettingsToggleSwitch"
import { ConfigProvider, useConfigCtx } from "./context"
import {
	AutoReviewSettingsForm,
	ChatSettingsForm,
	CodeGenerationForm,
	FinishingTouchesSettingsForm,
	GeneralSettingsForm,
	KnowledgeBaseSettingsForm,
	ReviewSettingsForm,
	ToolsSettingsForm,
} from "./schemaForm/SchemaForm"

import Loader from "@/components/Loader/Loader"
import { cn } from "@/lib/utils"
import { formatErrorDetails } from "@/utils/error-logs"
import type { ConfigSettings } from "@coderabbitai/schemas"
import { CircularProgress } from "@mui/material"
import type { ErrorObject } from "ajv"
import Ajv from "ajv"
import { toast } from "react-toastify"
import { trpc } from "../../../trpc"
import { SaveConfigMessage } from "./SaveConfigMessage"
import { SchemaErrors } from "./SchemaErrors"
import schemaV2 from "./schema/schema.v2.json"
import { useTabChange } from "./useTabChange"

type HandleReviewFormsChange = <
	T extends "auto_review" | "finishing_touches" | "tools",
>(
	key: T,
	data: ConfigSettings["reviews"][T],
) => void

const ajv = new Ajv()
const validateWithJsonSchema = ajv.compile(schemaV2)

const tabList = [
	{
		title: "General",
		id: "generalSettings",
	},
	{
		title: "Review",
		id: "reviewSettings",
	},
	{
		title: "Chat",
		id: "chatSettings",
	},
	{
		title: "Knowledge Base",
		id: "knowledgeBaseSettings",
	},
	{
		title: "Code Generation",
		id: "codeGenSettings",
	},
] as const

const reviewsSubTabsList = [
	{
		title: "Settings",
		id: "reviewSettingsInner",
	},
	{
		title: "Auto Review",
		id: "autoReview",
	},
	{
		title: "Tools",
		id: "tools",
	},
	{
		title: "Finishing Touches",
		id: "finishingTouches",
	},
] as const

// Repo/Org settings page

const ConfigWithoutCtx: FC = () => {
	const {
		setConfig,
		config: savedConfig,
		initialConfig,
		selectedOrg,
		isRepoSettings,
		isDefaultSettings,
		repo,
		id: repoID,
		doesOrgSettingsExist,
		setRepoSettingsEnabled,
		form,
		repoSettingsQuery,
	} = useConfigCtx()

	const navigate = useNavigate()
	const watch = form.watch()

	// Group form refs into a single object for easier management
	const formRefs = {
		general: createRef<Form>(),
		review: createRef<Form>(),
		tools: createRef<Form>(),
		autoReview: createRef<Form>(),
		finishingTouches: createRef<Form>(),
		knowledgeBase: createRef<Form>(),
		chat: createRef<Form>(),
		codegen: createRef<Form>(),
	}

	const [isOrgSettingsSaving, setIsOrgSettingsSaving] = useState(false)
	const [schemaError, setSchemaError] = useState<
		ErrorObject[] | null | undefined
	>(null)

	const submitAllForms = useCallback(() => {
		Object.values(formRefs).forEach(ref => ref.current?.submit())
	}, [formRefs])

	// Do not render until the configuration has been fetched
	if (!savedConfig) {
		return <Loader />
	}

	const getRepoSettingsSaveBody = (): CRHandlerUpdateReposSettingsBody => {
		if (!repoID) {
			throw new Error("Repository ID is required")
		}
		return {
			repo_id: repoID,
			settings: savedConfig,
			enabled: !watch.useOrgSettings,
		}
	}

	const getOrgSettingSaveBody = (): CRHandlerUpdateOrgLevelSettingsBody => {
		return {
			dataOptOut: !watch.dataOptOut,
			settings: savedConfig,
		}
	}

	const updateSettingsMutation =
		trpc.repositorySettings.updateSettings.useMutation()

	const saveOrgSettings = async (
		authToken: string,
		body: CRHandlerUpdateOrgLevelSettingsBody,
	): Promise<void> => {
		await axios.put<
			CRHandlerUpdateOrgLevelSettingsResp,
			AxiosResponse<CRHandlerUpdateOrgLevelSettingsResp>,
			CRHandlerUpdateOrgLevelSettingsBody
		>(
			`${import.meta.env.VITE_CODERABBIT_FUNC_URL}/updateOrgLevelSettings`,
			body,
			{
				headers: { Authorization: `Bearer ${authToken}` },
				params: { organization_id: selectedOrg?.id },
			},
		)
	}

	const handleSaveSettings = async () => {
		const authToken = sessionStorage.getItem("accessToken")

		if (!authToken) {
			sessionStorage.clear()
			navigate("/login")
			return
		}

		setSchemaError(null)

		try {
			const body = isRepoSettings
				? getRepoSettingsSaveBody()
				: getOrgSettingSaveBody()
			const isValid = validateWithJsonSchema(body.settings)

			if (!isValid) {
				setSchemaError(validateWithJsonSchema.errors)
				toast.error("Schema validation failed")
				return
			}

			if (isRepoSettings) {
				const repoBody = body as CRHandlerUpdateReposSettingsBody
				if (!repoBody.repo_id) return

				await updateSettingsMutation.mutateAsync({
					repo_id: repoBody.repo_id,
					settings: repoBody.settings,
					enabled: repoBody.enabled,
				})

				await repoSettingsQuery.refetch()
				toast.success("Repository settings updated!")
			} else {
				setIsOrgSettingsSaving(true)
				await saveOrgSettings(
					authToken,
					body as CRHandlerUpdateOrgLevelSettingsBody,
				)
				toast.success("Organization settings updated!")
			}

			initialConfig.current = savedConfig
		} catch (err) {
			toast.error("Failed to update settings. Please try again.")
			sentryCaptureException(
				`Failed to update ${isRepoSettings ? "repository" : "organization"} settings`,
				formatErrorDetails(err),
			)
		} finally {
			if (!isRepoSettings) {
				setIsOrgSettingsSaving(false)
			}
		}
	}

	const handleFormSubmit = useCallback(async () => {
		submitAllForms()
		await handleSaveSettings()
	}, [submitAllForms, handleSaveSettings])

	const onChangeForm = useCallback(
		(e: IChangeEvent) => {
			// Preserve the current useOrgSettings state
			const currentUseOrgSettings = form.getValues().useOrgSettings

			// Update config without affecting the useOrgSettings state
			setConfig(prev => {
				return prev ? { ...prev, ...e.formData } : e.formData
			})

			// Ensure useOrgSettings state stays the same
			form.setValue("useOrgSettings", currentUseOrgSettings, {
				shouldTouch: false,
				shouldDirty: false,
			})
		},
		[setConfig, form],
	)

	const handleReviewFormsChange = useCallback<HandleReviewFormsChange>(
		(key, data) => {
			setConfig(prev => {
				if (!prev) return prev
				return {
					...prev,
					reviews: {
						...prev.reviews,
						[key]: data,
					},
				}
			})
		},
		[setConfig, form],
	)

	const {
		reviews: { tools, auto_review, finishing_touches, ...reviews },
		knowledge_base,
		chat,
		code_generation,
		...generalDefault
	} = useMemo(() => savedConfig, [savedConfig])

	const { tabID, onChangeTab } = useTabChange("generalSettings")
	const { tabID: reviewTabId, onChangeTab: onChangeReviewTabs } = useTabChange(
		"reviewSettingsInner",
		"reviewTab",
	)

	// Update the disabled condition to properly handle initial state
	const isSettingsDisabled = useMemo(() => {
		if (!isRepoSettings) return false
		return watch.useOrgSettings || isDefaultSettings
	}, [isRepoSettings, watch.useOrgSettings, isDefaultSettings])

	const isSaving = isRepoSettings
		? updateSettingsMutation.isLoading
		: isOrgSettingsSaving

	return (
		<FormComponent {...form}>
			<div className="relative w-full px-2 py-10 sm:px-16">
				{isRepoSettings && (
					<Breadcrumb className="mb-2">
						<BreadcrumbList>
							<BreadcrumbItem>
								<BreadcrumbLink href="/settings/repositories">
									Repositories
								</BreadcrumbLink>
							</BreadcrumbItem>
							<BreadcrumbSeparator>
								<img src={RightIcon} />
							</BreadcrumbSeparator>
							<BreadcrumbItem>
								<BreadcrumbPage>{repo}</BreadcrumbPage>
							</BreadcrumbItem>
						</BreadcrumbList>
					</Breadcrumb>
				)}
				<>
					<div className="flex w-full flex-col items-start gap-5">
						<div className="flex w-full flex-col flex-wrap justify-between gap-4 sm:flex-row">
							<div className="flex flex-1 items-center gap-2 lg:gap-5">
								<span className="font-500 font-inter mb-2 flex flex-col text-2xl leading-7 text-[#242424] not-italic lg:text-2xl">
									{isRepoSettings ? repo : "Organization Settings"}

									{!isRepoSettings && (
										<span className="font-400 font-inter text-muted-foreground mt-4 mr-4 flex w-fit items-center justify-center text-sm leading-5">
											You can configure settings applicable to the entire
											organization. Settings configured at the repository level
											will override these.
										</span>
									)}

									{isRepoSettings &&
										isDefaultSettings &&
										doesOrgSettingsExist && (
											<span className="font-400 font-inter text-muted-foreground mt-4 mr-4 flex w-fit items-center justify-center text-sm leading-5">
												Repository settings are not configured. Organization
												level settings are being used. You can configure custom
												settings for this repository below.
											</span>
										)}

									{isRepoSettings &&
										!isDefaultSettings &&
										!doesOrgSettingsExist && (
											<span className="font-400 font-inter text-muted-foreground mt-4 mr-4 flex w-fit items-center justify-center text-sm leading-5">
												Repository settings are configured and active.
											</span>
										)}

									{isRepoSettings &&
										isDefaultSettings &&
										!doesOrgSettingsExist && (
											<span className="font-400 font-inter text-muted-foreground mt-4 mr-4 flex w-fit items-center justify-center text-sm leading-5">
												Repository settings are not configured. If you have a
												'coderabbit.yaml' file in your repository, its settings
												will take precedence and be used.
											</span>
										)}
								</span>
							</div>

							<div className="flex flex-col justify-center sm:grow sm:flex-row">
								<div className="ml-auto shrink-0">
									<Button
										className="relative min-w-28"
										size="sm"
										onClick={handleFormSubmit}
										disabled={isSaving}
									>
										{isSaving ? (
											<CircularProgress
												size={20}
												className="color-secondary mr-2"
											/>
										) : (
											"Apply Changes"
										)}
									</Button>
								</div>
							</div>
						</div>

						{isRepoSettings && (
							<div className="flex w-full flex-col">
								{doesOrgSettingsExist && (
									<SettingsToggleSwitch
										label="Use Organization Settings"
										name="useOrgSettings"
										text="Organization settings will be applied. If disabled, the repository-specific settings configured below will be used."
										control={form.control}
										onChange={(newState: boolean) => {
											// Update both states together to ensure consistency
											setRepoSettingsEnabled(!newState)
											form.setValue("useOrgSettings", newState, {
												shouldTouch: true,
												shouldDirty: true,
											})

											// If enabling org settings, reset any local changes
											if (newState) {
												form.reset({
													useOrgSettings: true,
													dataOptOut: form.getValues().dataOptOut,
												})
											}
										}}
										disabled={isDefaultSettings}
									/>
								)}
							</div>
						)}

						{schemaError ? (
							<div className="space-y-4">
								<SchemaErrors errors={schemaError} />
							</div>
						) : (
							<div className="space-y-4">
								{initialConfig.current && (
									<SaveConfigMessage
										initialConfig={initialConfig.current}
										config={savedConfig}
									/>
								)}
							</div>
						)}

						<Tabs defaultValue={tabID ?? "generalSettings"} className="w-full">
							<TabsList className="mb-3">
								{tabList.map(tab => (
									<TabsTrigger
										key={tab.id}
										value={tab.id}
										onClick={() => {
											onChangeTab(tab)
										}}
									>
										{tab.title}
									</TabsTrigger>
								))}
							</TabsList>
							<TabsContent value="generalSettings" className="w-full">
								<Card>
									<CardContent className="pt-3">
										<GeneralSettingsForm
											{...{
												formData: generalDefault,
												formRef: formRefs.general,
												onChange: onChangeForm,
												disabled:
													isRepoSettings &&
													(watch.useOrgSettings || isDefaultSettings),
											}}
										/>

										{!isRepoSettings && (
											<div
												className={cn(
													selectedOrg?.role !== "admin"
														? "pointer-events-none"
														: undefined,
													"max-w-(--breakpoint-md)",
												)}
											>
												<SettingsToggleSwitch
													control={form.control}
													label="Fine-tune Your Reviews"
													name="dataOptOut"
													text="CodeRabbit will learn from your usage and get better over time. Your data remains secure, isolated, and is used only for fine-tuning your reviews. We recommend opting in. If you decide to opt out, your data will not be stored. If you opt out after opting in, all existing learnings will be removed from the system."
													onChange={(newState: boolean) => {
														form.setValue("dataOptOut", newState)
													}}
												/>
											</div>
										)}
									</CardContent>
								</Card>
							</TabsContent>
							<TabsContent value="reviewSettings" className="w-full">
								<Card>
									<CardContent className="pt-3">
										<Tabs
											defaultValue={reviewTabId ?? "reviewSettingsInner"}
											className="w-full"
										>
											<TabsList className="mb-3">
												{reviewsSubTabsList.map(tab => (
													<TabsTrigger
														key={tab.id}
														value={tab.id}
														onClick={() => {
															onChangeReviewTabs(tab)
														}}
													>
														{tab.title}
													</TabsTrigger>
												))}
											</TabsList>
											<TabsContent
												value="reviewSettingsInner"
												className="w-full"
											>
												<ReviewSettingsForm
													{...{
														formData: { reviews },
														formRef: formRefs.review,
														onChange: onChangeForm,
														disabled: isSettingsDisabled,
													}}
												/>
											</TabsContent>
											<TabsContent value="autoReview" className="w-full">
												<AutoReviewSettingsForm
													{...{
														formData: { auto_review },
														formRef: formRefs.autoReview,
														onChange: e => {
															handleReviewFormsChange(
																"auto_review",
																e.formData.auto_review,
															)
														},
														disabled: isSettingsDisabled,
													}}
												/>
											</TabsContent>
											<TabsContent value="tools" className="w-full">
												<ToolsSettingsForm
													{...{
														formData: { tools },
														formRef: formRefs.tools,
														onChange: e => {
															handleReviewFormsChange("tools", e.formData.tools)
														},
														disabled: isSettingsDisabled,
													}}
												/>
											</TabsContent>
											<TabsContent value="finishingTouches" className="w-full">
												<FinishingTouchesSettingsForm
													{...{
														formData: { finishing_touches },
														formRef: formRefs.finishingTouches,
														onChange: e => {
															handleReviewFormsChange(
																"finishing_touches",
																e.formData.finishing_touches,
															)
														},
														disabled: isSettingsDisabled,
													}}
												/>
											</TabsContent>
										</Tabs>
									</CardContent>
								</Card>
							</TabsContent>
							<TabsContent value="knowledgeBaseSettings" className="w-full">
								<Card>
									<CardContent className="pt-3">
										<KnowledgeBaseSettingsForm
											{...{
												formData: { knowledge_base },
												formRef: formRefs.knowledgeBase,
												onChange: onChangeForm,
												disabled: isSettingsDisabled,
											}}
										/>
									</CardContent>
								</Card>
							</TabsContent>
							<TabsContent value="chatSettings" className="w-full">
								<Card>
									<CardContent className="pt-3">
										<ChatSettingsForm
											{...{
												formData: { chat },
												formRef: formRefs.chat,
												onChange: onChangeForm,
												disabled: isSettingsDisabled,
											}}
										/>
									</CardContent>
								</Card>
							</TabsContent>
							<TabsContent value="codeGenSettings" className="w-full">
								<Card>
									<CardContent className="pt-3">
										<CodeGenerationForm
											{...{
												formData: { code_generation },
												formRef: formRefs.codegen,
												onChange: onChangeForm,
												disabled: false,
											}}
										/>
									</CardContent>
								</Card>
							</TabsContent>
						</Tabs>
					</div>
				</>
			</div>
		</FormComponent>
	)
}

// Main Configure component without redundant Suspense
const Configure: FC = () => {
	return (
		<ConfigProvider>
			<ConfigWithoutCtx />
		</ConfigProvider>
	)
}

export default Configure
