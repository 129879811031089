import bitbucketAppPassword from "@/assets/onboarding/bitbucket/bitbucket-app-password-page.png"
import installCr from "@/assets/onboarding/bitbucket/install-coderabbit.png"
import verifyAccess from "@/assets/onboarding/bitbucket/verify-access.png"
import { Button } from "@/components/ui/button"
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { LazyImage } from "@/components/ui/lazy-image"
import { trpc } from "@/trpc"
import { zodResolver } from "@hookform/resolvers/zod"
import { CircleCheck, Loader } from "lucide-react"
import { useContext, useEffect, type FC, type PropsWithChildren } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { z } from "zod"
import { OnboardingWizardCtx } from "../ctx"
import type { Config } from "../types"

const StrongTextBlock: FC<PropsWithChildren> = ({ children }) => {
	return <span className="font-semibold">{children}</span>
}

export const bitBucketConfig: Config = {
	title: "Bitbucket Cloud",
	description: `CodeRabbit integrates with Bitbucket cloud to enhance code review and collaboration`,
	steps: [
		{
			id: "bitbucket-app-password",
			title: "Create a CodeRabbit Account and App password",
			description: (
				<p>
					In your Bitbucket workspace, go to{" "}
					<StrongTextBlock>
						Settings {`>`} Personal Bitbucket Settings {`>`} App passwords
					</StrongTextBlock>{" "}
					and click on <StrongTextBlock>Create app password</StrongTextBlock>.
					Generate an app password with specified permissions and store it
					securely.
				</p>
			),
			renderElement: () => (
				<LazyImage
					src={bitbucketAppPassword}
					alt="bitbucket-app-password"
					className="[&>img]:rounded-lg"
				/>
			),
			isComplete: false,
			optional: true,
		},
		{
			id: "authenticate",
			title: "Connect CodeRabbit to your Bitbucket workspace",
			description: (
				<p>
					Set up a Bitbucket user named "CodeRabbit" with a dedicated email.
					Generate an App Password with the required permissions and store it
					securely.
				</p>
			),
			renderElement: () => <BitbucketAuthenticationForm />,
			optional: false,
			isComplete: false,
		},
		{
			id: "install-coderabbit",
			title: `Install CodeRabbit`,
			description: (
				<p>
					In the CodeRabbit dashboard, go to the{" "}
					<StrongTextBlock>Repositories page</StrongTextBlock>. Select the
					repositories you want to integrate and click{" "}
					<StrongTextBlock>Install Repositories</StrongTextBlock> to set up the
					webhook seamlessly.
				</p>
			),
			renderElement: () => (
				<LazyImage src={installCr} alt="install-coderabbit" />
			),
			optional: true,
			isComplete: false,
		},
		{
			id: "verify-access",
			title: `Verify Access`,
			description: (
				<div>
					<p>
						In your Bitbucket workspace, navigate to{" "}
						<StrongTextBlock>Repository Settings</StrongTextBlock> and select{" "}
						<StrongTextBlock>Webhooks</StrongTextBlock>. Under the{" "}
						<StrongTextBlock>Repository hooks</StrongTextBlock> section, verify
						that the <StrongTextBlock>CodeRabbitAI webhook</StrongTextBlock> is
						properly configured.
					</p>
				</div>
			),
			renderElement: () => (
				<LazyImage src={verifyAccess} alt="install-coderabbit" />
			),
			optional: true,
			isComplete: false,
		},
	],
}

const formSchema = z.object({
	userToken: z.string().min(1, {
		message: "User token is required.",
	}),
	username: z.string().min(1, {
		message: "Username is required.",
	}),
})

const BitbucketAuthenticationForm = () => {
	const form = useForm({
		resolver: zodResolver(formSchema),
		defaultValues: {
			userToken: "",
			username: "",
		},
	})

	const { config, setConfig } = useContext(OnboardingWizardCtx)

	const {
		data: bitbucketUser,
		isLoading: isLoadingBitbucketUser,
		refetch: refetchBitbucketUser,
		isRefetching: isRefetchingBitbucketUser,
	} = trpc.providers.getOrgUser.useQuery(undefined)

	useEffect(() => {
		if (bitbucketUser && config) {
			setConfig({
				...config,
				steps: config.steps.map(step => {
					if (step.id === "authenticate") {
						return {
							...step,
							isComplete: true,
						}
					}

					return step
				}),
			})
		}
	}, [bitbucketUser])

	const saveBitbucketUserQuery = trpc.providers.saveOrgUser.useMutation({
		onSuccess: resp => {
			if (resp.success) {
				form.reset({
					userToken: "",
					username: "",
				})
			} else {
				toast.error("Failed to save Bitbucket user details")
			}
		},
		onError: () => {
			toast.error("Failed to save Bitbucket user details")
		},
	})

	const onSubmit = async (values: z.infer<typeof formSchema>) => {
		await saveBitbucketUserQuery.mutateAsync({
			userToken: values.userToken,
			username: values.username,
		})

		await refetchBitbucketUser()
	}

	if (isLoadingBitbucketUser || isRefetchingBitbucketUser) {
		return (
			<div className="flex h-auto flex-col items-center gap-4 rounded-lg border bg-[#EEEEE3] p-6 pb-8">
				<div className="grid h-68 place-items-center">
					<div className="flex flex-col items-center gap-4">
						<div className="bg-primary grid size-10 place-items-center rounded-lg">
							<Loader className="animate-spin stroke-white" />
						</div>
						<div className="flex flex-col items-center gap-1">
							<p className="text-lg font-semibold">Verifying connection ...</p>
							<span className="h-5 w-[80%] text-center text-sm text-balance"></span>
						</div>
					</div>
				</div>
			</div>
		)
	}

	if (bitbucketUser) {
		return (
			<div className="flex h-full flex-col items-center gap-4 rounded-lg border bg-[#EEEEE3] p-6 pb-8">
				<div className="grid h-68 place-items-center">
					<div className="flex flex-col items-center gap-4">
						<div className="bg-primary grid size-10 place-items-center rounded-lg">
							<CircleCheck className="stroke-white" />
						</div>
						<div className="flex flex-col items-center gap-1 transition-all duration-700">
							<p className="text-lg font-semibold">Successfully connected</p>
							<span className="text-sm">
								Your account has been successfully connected!
							</span>
						</div>
					</div>
				</div>
			</div>
		)
	}

	return (
		<Form {...form}>
			<form
				onSubmit={form.handleSubmit(onSubmit)}
				className="flex h-auto flex-col items-center gap-4 rounded-lg border bg-[#EEEEE3] p-6 pt-8 pb-16"
			>
				<div className="w-6/12">
					<FormField
						control={form.control}
						name="userToken"
						render={({ field }) => (
							<FormItem>
								<FormLabel className="text-sm">App Password</FormLabel>
								<FormControl>
									<Input
										className="mt-2 bg-white"
										placeholder="Enter token..."
										type="password"
										{...field}
									/>
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
				</div>
				<div className="w-6/12">
					<FormField
						control={form.control}
						name="username"
						render={({ field }) => (
							<FormItem>
								<FormLabel className="text-sm">Username</FormLabel>
								<FormControl>
									<Input
										className="mt-2 bg-white"
										placeholder="Enter username..."
										{...field}
									/>
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
				</div>
				<Button
					className="mt-4 w-6/12"
					type="submit"
					disabled={saveBitbucketUserQuery.isLoading || isLoadingBitbucketUser}
				>
					Submit
				</Button>
			</form>
		</Form>
	)
}
