import { createContext } from "react"
import type { Config } from "./types"

interface Ctx {
	config: Config | null
	setConfig: (config: Config) => void
}

export const OnboardingWizardCtx = createContext<Ctx>({
	config: null,
	setConfig: () => null,
})
