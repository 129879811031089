import TextInputSection from "@/components/InputSelection/SettingsInputSelection"
import { Divider } from "@mui/material"
import type { ArrayFieldTemplateProps } from "@rjsf/utils"
import type { FC } from "react"
import { useEffect, useState } from "react"
import { useConfigCtx } from "../../context"
import { changeToTitleCase, getNiceNameAndDescription } from "../utils"
import { LabelAndDescription, RemoveFieldsButton } from "./ArrayFieldTemplate"

interface PathInstructions {
	path: string
	instructions: string
}

export const PathInstructionsTemplate: FC<ArrayFieldTemplateProps> = props => {
	const { items, canAdd, onAddClick, title, schema, formData } = props

	const [state, setPathInstructions] = useState<PathInstructions[]>(formData)

	const { setConfig, checkIfRepoSettings } = useConfigCtx()

	useEffect(() => {
		setConfig(prev => ({
			...prev,
			reviews: {
				...prev.reviews,
				path_instructions: state,
			},
		}))
	}, [state])

	const { niceDescription, niceName } = getNiceNameAndDescription(
		schema.description,
	)

	return (
		<>
			<LabelAndDescription
				{...{
					title: niceName || changeToTitleCase(title.replace(/_/g, " ")),
					description: niceDescription || "",
					canAdd: !!canAdd,
					onAddClick: e => {
						setPathInstructions(prev => {
							return [
								...prev,
								{
									path: "",
									instructions: "",
								},
							]
						})

						onAddClick(e)
					},
				}}
			>
				{items.map(({ key, ...el }) => {
					return (
						<div
							className="relative grid w-full grid-cols-1 gap-2 lg:grid-cols-[1fr_auto]"
							key={key}
						>
							<div>
								{Object.keys(el.schema.properties ?? {}).map((key, i) => {
									return (
										<TextInputSection
											key={i}
											id="paths"
											label={changeToTitleCase(key)}
											name={`${key}-${el.index}`}
											value={
												state[el.index]?.[key as keyof PathInstructions] ?? ""
											}
											disabled={checkIfRepoSettings()}
											onChange={e => {
												setPathInstructions(prev => {
													return prev.map((item, i) =>
														i === el.index
															? { ...item, [key]: e.target.value }
															: item,
													)
												})
											}}
											isTextArea={key === "instructions"}
											text={
												(el.schema.properties?.[key] as { description: string })
													.description || ""
											}
										/>
									)
								})}
							</div>
							{el.hasRemove && !checkIfRepoSettings() && (
								<RemoveFieldsButton
									{...el}
									onClickDelete={() => {
										setPathInstructions(prev => {
											prev.splice(el.index, 1)
											return [...prev]
										})
									}}
								/>
							)}
						</div>
					)
				})}
			</LabelAndDescription>
			<Divider sx={{ my: 2 }} />
		</>
	)
}
