import Loader from "@/components/Loader/Loader"
import { Button } from "@/components/ui/button"
import { trpc } from "@/trpc"
import type {
	BaseSchedule,
	FrequencyOption,
	PlatformSelection,
	PromptTemplate,
	ScheduleGroup,
	ScheduleMonthDay,
	ScheduleWeekDay,
	ScheduleWeekFrequency,
	ScheduleWeekFrequencyInt,
} from "@/typings/githubActionsHandler.ts"
import {
	frequencyOptions,
	SchedulePlatforms,
} from "@/typings/githubActionsHandler.ts"
import { TRPCClientError } from "@trpc/client"
import { LoaderIcon } from "lucide-react"
import moment from "moment-timezone"
import { useEffect, useState } from "react"
import type { DateRange } from "react-day-picker"
import { FaRegCheckCircle } from "react-icons/fa"
import { LuAlarmClock, LuAlarmClockOff } from "react-icons/lu"
import { Navigate, useNavigate, useParams } from "react-router"
import { toast } from "react-toastify"
import validator from "validator"
import { reportTypes } from "./RecurringReports"
import { ReportRangeSelector } from "./ReportRangeSelector"
import ReportingName from "./ReportingComponents/ReportingName"
import ReportingParameters from "./ReportingComponents/ReportingParameters"
import ReportingPlatforms from "./ReportingComponents/ReportingPlatforms"
import ReportingPrompt from "./ReportingComponents/ReportingPrompt"
import ReportingSchedule from "./ReportingComponents/ReportingSchedule"
import {
	defaultPromptTemplate,
	PROMPT_MAX_LENGTH,
} from "./ReportingComponents/prompt"
import TriggerReportPreview from "./TriggerReportPreviewModal"
import { convertDaysToNames, convertDaysToNumbers } from "./report-utils"
import type { DataSources } from "@/utils/constant"
import { DEFAULT_DATA_SOURCES } from "@/utils/constant"

export const scheduleTypes = {
	ONDEMAND: "ONDEMAND",
	WEEKLY: "WEEKLY",
	MONTHLY: "MONTHLY",
} as const

const NewRecurringReport: React.FC = () => {
	const navigate = useNavigate()
	const { reportID, reportType } = useParams<{
		reportID?: string
		reportType: keyof typeof reportTypes
	}>()

	const trpcUtils = trpc.useUtils()

	const [name, setName] = useState<string>("")
	const [scheduleStatus, setScheduleStatus] = useState<boolean>(false)
	const [frequency, setFrequency] = useState<FrequencyOption>(
		frequencyOptions[0],
	)
	const [weekFrequency, setWeekFrequency] =
		useState<ScheduleWeekFrequencyInt>(1)
	const [selectedDays, setSelectedDays] = useState<string[]>([])
	const [selectedTime, setSelectedTime] = useState<string>("")
	const [selectedTimezone, setSelectedTimezone] = useState<string>(
		moment.tz.guess(),
	)
	const [selectedCalendarDates, setSelectedCalendarDates] = useState<
		ScheduleMonthDay[]
	>([])
	const [selectedParameters, setSelectedParameters] = useState<
		BaseSchedule["parameters"]
	>([])
	const [selectedGroupby, setSelectedGroupBy] = useState<ScheduleGroup>("NONE")
	const [selectedSubgroupby, setSelectedSubgroupBy] =
		useState<ScheduleGroup>("NONE")
	const [promptTemplate, setPromptTemplate] = useState<PromptTemplate>(
		!reportID ? defaultPromptTemplate : "Daily Standup Report",
	)
	const [prompt, setPrompt] = useState<string>("")
	const [platforms, setPlatforms] = useState<PlatformSelection[]>(
		SchedulePlatforms.map(platform => ({
			platform,
			channel: "",
			selected: false,
		})),
	)
	const [emails, setEmails] = useState<string[]>([])
	const [selectedRange, setSelectedRange] = useState<DateRange | undefined>({
		from: moment().subtract(7, "day").toDate(),
		to: moment().toDate(),
	})
	const [dataSources, setDataSources] =
		useState<DataSources>(DEFAULT_DATA_SOURCES)

	const saveReportingSettingsReq =
		trpc.reporting.saveReportingSettings.useMutation()

	const saveOndemandReport = trpc.reporting.saveOndemandReport.useMutation()

	const { data: reportData, isLoading: reportDataLoading } =
		trpc.reporting.getReportingSettings.useQuery(Number(reportID), {
			enabled: !!reportID,
			onError: error => {
				toast.error("Failed to fetch report data")
				if (error.data?.code === "UNAUTHORIZED") {
					navigate("/reports")
				}
			},
		})

	const isViewOnlyMode = Boolean(reportID && reportType === "ondemand")

	useEffect(() => {
		if (reportData?.isSuccess) {
			const { data } = reportData
			setName(data.name)
			setSelectedTime(data.time)
			setSelectedTimezone(data.timezone)
			setFrequency(data.type === "WEEKLY" ? "Days of Week" : "Days of Month")
			setWeekFrequency(wf =>
				data.frequency ? (Number(data.frequency) as typeof wf) : wf,
			)
			if (data.type === "WEEKLY") {
				setSelectedDays(convertDaysToNames(data.days as ScheduleWeekDay[]))
			} else {
				setSelectedCalendarDates(data.days as ScheduleMonthDay[])
			}
			setSelectedParameters(data.parameters as BaseSchedule["parameters"])
			setSelectedGroupBy(data.group as ScheduleGroup)
			setSelectedSubgroupBy(data.subgroup as ScheduleGroup)
			setPlatforms(
				data.platforms.map(platform => ({
					...platform,
					selected: !!platform.channel,
				})),
			)
			setEmails(data.emails)
			if (data.prompt) {
				setPrompt(data.prompt)
			}
			setPromptTemplate(
				(data.promptTemplate as PromptTemplate) ?? defaultPromptTemplate,
			)
			setScheduleStatus(data.scheduleStatus)
			setDataSources(data.dataSources ?? DEFAULT_DATA_SOURCES)

			if (data.type === scheduleTypes.ONDEMAND && data.from && data.to) {
				setSelectedRange({
					from: new Date(data.from),
					to: new Date(data.to),
				})
			}
		}
	}, [reportData])

	const getCommonErrorMsg = ({
		name,
		parameters,
		platforms,
		emails,
		prompt,
	}: {
		name: string
		parameters: BaseSchedule["parameters"]
		platforms: PlatformSelection[]
		emails: string[]
		prompt: string
	}) => {
		if (!name.trim()) {
			return "Please enter a name for the report"
		}

		if (parameters.some(param => param.values.length === 0)) {
			return "Please select at least one value for each parameter"
		}

		if (platforms.filter(p => p.selected).length === 0 && emails.length === 0) {
			return `Please enable ${SchedulePlatforms.join("/")} or configure email(s) for the report`
		}

		if (
			platforms.find(p => p.selected) &&
			!platforms.find(p => p.selected)?.channel
		) {
			return `Please select a channel for ${platforms.find(p => p.selected)?.platform}`
		}

		if (
			emails.length &&
			!emails.every(email => validator.isEmail(email.trim()))
		) {
			return `Please enter valid email IDs`
		}

		if (!prompt.trim()) {
			return "Please enter a prompt"
		}

		if (prompt.trim().length > PROMPT_MAX_LENGTH) {
			return `Prompt should be not be more than ${PROMPT_MAX_LENGTH} characters`
		}

		return null
	}

	const getRecurringErrorMsg = ({
		selectedTime,
		selectedTimezone,
		frequency,
		selectedDays,
		selectedCalendarDates,
		weekFrequency,
	}: {
		selectedTime: string
		selectedTimezone: string
		frequency: FrequencyOption
		selectedDays: string[]
		selectedCalendarDates: ScheduleMonthDay[]
		weekFrequency: ScheduleWeekFrequencyInt
	}) => {
		if (!selectedTime) {
			return "Please select a time for the report"
		} else if (!selectedTimezone) {
			return "Please select a timezone for the report"
		} else if (frequency === "Days of Week" && selectedDays.length === 0) {
			return "Please select at least one day for the report"
		} else if (
			frequency === "Days of Month" &&
			selectedCalendarDates.length === 0
		) {
			return "Please select at least one date for the report"
		} else if (
			frequency === "Days of Week" &&
			(weekFrequency < 1 || weekFrequency > 8)
		) {
			return "Report week frequency should be between 1 and 8"
		}

		return null
	}

	const handleSaveOndemandReport = async () => {
		const errorMsg = getCommonErrorMsg({
			name,
			parameters: selectedParameters,
			platforms,
			emails,
			prompt,
		})

		if (errorMsg) {
			toast.error(errorMsg)
			return
		}

		if (!selectedRange?.from || !selectedRange.to) {
			toast.error("Please select a date range for the report")
			return
		}

		if (
			moment(selectedRange.from).format("YYYY-MM-DD") ===
			moment(selectedRange.to).format("YYYY-MM-DD")
		) {
			toast.error("Date range cannot have the same start and end date")
			return
		}

		await saveOndemandReport.mutateAsync(
			{
				type: "ONDEMAND",
				name: name,
				time: selectedTime,
				timezone: selectedTimezone,
				parameters: selectedParameters,
				emails: emails,
				platform: platforms.find(p => p.selected)?.platform,
				channel: platforms.find(p => p.selected)?.channel,
				promptTemplate: promptTemplate,
				prompt: prompt,
				dataSources,
				from: selectedRange.from.toISOString(),
				to: selectedRange.to.toISOString(),
			},
			{
				onSuccess: async () => {
					await trpcUtils.reporting.listReports.reset()
					toast.success("On demand report created, you will receive it shortly")
					navigate(`/reports`)
				},
				onError: error => {
					toast.error(`Failed to save report: ${error.message}`)
				},
			},
		)
	}

	const saveRecurringReport = async (scheduleStatus?: boolean) => {
		const updateStatusOnly = scheduleStatus !== undefined

		const errorMsg = getCommonErrorMsg({
			name,
			parameters: selectedParameters,
			platforms,
			emails,
			prompt,
		})

		const recurringErrorMsg = getRecurringErrorMsg({
			selectedTime,
			selectedTimezone,
			frequency,
			selectedDays,
			selectedCalendarDates,
			weekFrequency,
		})

		if (!updateStatusOnly && (errorMsg || recurringErrorMsg)) {
			toast.error(errorMsg || recurringErrorMsg)
			return
		}

		await saveReportingSettingsReq.mutateAsync(
			//@ts-expect-error Types are a mess here
			updateStatusOnly
				? { id: reportID || "", scheduleStatus }
				: {
						id: reportID || "",
						name,
						type: frequency === "Days of Week" ? "WEEKLY" : "MONTHLY",
						frequency:
							frequency === "Days of Week"
								? (weekFrequency.toString() as ScheduleWeekFrequency)
								: undefined,
						days:
							frequency === "Days of Week"
								? [...convertDaysToNumbers(selectedDays)].sort((a, b) => a - b)
								: [...selectedCalendarDates].sort((a, b) => a - b),
						time: selectedTime,
						timezone: selectedTimezone,
						parameters: selectedParameters,
						group: selectedGroupby,
						subgroup:
							selectedGroupby !== "NONE" ? selectedSubgroupby : undefined,
						promptTemplate,
						prompt,
						platform: platforms.find(p => p.selected)?.platform,
						channel: platforms.find(p => p.selected)?.channel,
						emails,
						dataSources,
					},
			{
				onSuccess: async () => {
					await trpcUtils.reporting.listReports.reset()
					if (updateStatusOnly) {
						setScheduleStatus(scheduleStatus)
						toast.success(
							`Report ${scheduleStatus ? "enabled" : "disabled"} successfully`,
						)
					} else {
						if (!reportID) {
							toast.success("Report saved successfully")
							navigate("/reports")
						} else {
							toast.success("Report edited successfully")
						}
					}
				},
				onError: error => {
					if (updateStatusOnly) {
						toast.error(
							`Failed to ${scheduleStatus ? "enable" : "disable"} report: ${
								error instanceof Error ? error.message : String(error)
							}`,
						)
					} else {
						toast.error(
							`Failed to save report: ${
								error instanceof TRPCClientError ? error.message : String(error)
							}`,
						)
					}
				},
			},
		)
	}

	if (reportType === undefined || !(reportType in reportTypes)) {
		return <Navigate to="/reports" />
	}

	return (
		<>
			{reportID && reportDataLoading ? (
				<Loader size="small" />
			) : (
				<div className="relative container mx-auto px-8 pt-7 pb-2">
					<div className="relative h-full">
						<div className="container mx-auto">
							<div className="flex items-center justify-between">
								<div className="font-500 font-inter text-foreground mb-2 flex-1 text-2xl leading-8">
									{reportID && reportType === "ondemand" && "View"}
									{reportID && reportType === "recurring" && "Edit"}{" "}
									{reportTypes[reportType]} Report
								</div>
								<div className="flex flex-wrap items-center gap-4">
									<ReportActions
										reportID={reportID}
										reportEnabled={scheduleStatus}
										onSave={async () => {
											if (reportType === "ondemand") {
												await handleSaveOndemandReport()
											} else {
												await saveRecurringReport()
											}
										}}
										onToggleStatus={() => {
											void saveRecurringReport(!scheduleStatus)
										}}
										isLoading={
											saveReportingSettingsReq.isLoading ||
											saveOndemandReport.isLoading
										}
									/>
								</div>
							</div>
							<div className="mt-4 mb-4 rounded-lg border bg-white px-5 py-6">
								<ReportingName
									name={name}
									setName={setName}
									disabled={isViewOnlyMode}
								/>

								<hr className="my-6" />

								{reportType === "recurring" && (
									<ReportingSchedule
										frequency={frequency}
										setFrequency={setFrequency}
										weekFrequency={weekFrequency}
										setWeekFrequency={setWeekFrequency}
										selectedDays={selectedDays}
										setSelectedDays={setSelectedDays}
										selectedCalendarDates={selectedCalendarDates}
										setSelectedCalendarDates={setSelectedCalendarDates}
										selectedTime={selectedTime}
										setSelectedTime={setSelectedTime}
										selectedTimezone={selectedTimezone}
										setSelectedTimezone={setSelectedTimezone}
									/>
								)}

								{reportType === "ondemand" && (
									<ReportRangeSelector
										range={selectedRange}
										setRange={setSelectedRange}
										disabled={isViewOnlyMode}
									/>
								)}

								<hr className="my-6" />

								<ReportingParameters
									selectedParameters={selectedParameters}
									setSelectedParameters={setSelectedParameters}
									disabled={isViewOnlyMode}
								/>

								<hr className="my-6" />

								<ReportingPrompt
									promptTemplate={promptTemplate}
									setPromptTemplate={setPromptTemplate}
									prompt={prompt}
									setPrompt={setPrompt}
									group={selectedGroupby}
									setGroup={setSelectedGroupBy}
									subgroup={selectedSubgroupby}
									setSubgroup={setSelectedSubgroupBy}
									dataSources={dataSources}
									setDataSources={setDataSources}
									disabled={isViewOnlyMode}
								/>

								<hr className="my-6" />

								<ReportingPlatforms
									platforms={platforms}
									setPlatforms={setPlatforms}
									emails={emails}
									onEmailsChange={setEmails}
								/>
							</div>

							<div className="mt-0 mb-4 flex flex-wrap items-center justify-between gap-4">
								<ReportActions
									reportID={reportID}
									reportEnabled={scheduleStatus}
									onSave={async () => {
										if (reportType === "ondemand") {
											await handleSaveOndemandReport()
										} else {
											await saveRecurringReport()
										}
									}}
									onToggleStatus={() => {
										void saveRecurringReport(!scheduleStatus)
									}}
									enablePreview
									isLoading={
										saveReportingSettingsReq.isLoading ||
										saveOndemandReport.isLoading
									}
								/>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

interface ReportActionsProps {
	readonly reportID: string | undefined
	readonly onSave: () => void
	readonly reportEnabled?: boolean
	readonly onToggleStatus: () => void
	readonly enablePreview?: boolean
	readonly isLoading: boolean
}

const ReportActions: React.FC<ReportActionsProps> = ({
	reportID,
	onSave,
	reportEnabled,
	onToggleStatus,
	enablePreview,
	isLoading,
}) => {
	const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false)

	const { reportType } = useParams<{
		reportType: keyof typeof reportTypes
	}>()

	return (
		<>
			{reportType === "recurring" && reportID && enablePreview && (
				<>
					<Button
						variant="secondary"
						onClick={() => {
							setOpenPreviewModal(true)
						}}
					>
						Preview Report
					</Button>
					<TriggerReportPreview
						open={openPreviewModal}
						onOpenChange={setOpenPreviewModal}
						reportID={reportID}
					/>
				</>
			)}
			{reportType === "recurring" && (
				<div className="ml-auto flex flex-wrap items-center gap-4">
					{reportID && (
						<Button
							variant="secondary"
							onClick={() => {
								onToggleStatus()
							}}
						>
							{reportEnabled ? (
								<>
									<LuAlarmClockOff className="mr-2" /> Disable Report
								</>
							) : (
								<>
									<LuAlarmClock className="mr-2" /> Enable Report
								</>
							)}
						</Button>
					)}
					<Button
						className="font-500 flex items-center gap-2"
						onClick={() => {
							onSave()
						}}
					>
						{isLoading && <LoaderIcon className="animate-spin" size={16} />}
						{!isLoading && <FaRegCheckCircle />}

						{isLoading && (reportID ? "Saving..." : "Creating...")}
						{!isLoading && (reportID ? "Save" : "Create") + " Report"}
					</Button>
				</div>
			)}
			{reportType === "ondemand" && !reportID && (
				<div className="ml-auto flex flex-wrap items-center gap-4">
					<Button
						onClick={() => {
							onSave()
						}}
						className="font-500 flex items-center gap-2"
					>
						{isLoading && <LoaderIcon className="animate-spin" size={16} />}
						{!isLoading && <FaRegCheckCircle />}

						{isLoading && "Saving..."}
						{!isLoading && "Save Report"}
					</Button>
				</div>
			)}
		</>
	)
}

export default NewRecurringReport
