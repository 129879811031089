import { Button } from "@/components/ui/button"
import { DataTableSort } from "@/components/ui/data-table-sort"
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Switch } from "@/components/ui/switch"
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip"
import type { UserData } from "@/typings"
import { isOrgAdmin } from "@/utils/orgUtils"
import { type Provider, PROVIDERS, useProvider } from "@/utils/providers"
import { getSelectedOrg } from "@/utils/utils"
import type { ColumnDef } from "@tanstack/react-table"
import { capitalize } from "lodash"
import moment from "moment"
import type React from "react"
import { useMemo } from "react"
import { LuMoreVertical, LuTrash2, LuUserCog2 } from "react-icons/lu"

interface ColumnProps {
	onSeatAssignmentChange: (userData: SeatManagementTableRow) => void
	seatDisableTooltip: string
	seatAssignmentDisabled: boolean
	isNewAssignSeat: boolean
	isTrial: boolean
	onOverrideRole: (userData: SeatManagementTableRow) => void
	onDeleteUser: (userData: SeatManagementTableRow) => void
	provider?: Provider
}

export type SeatManagementTableRow = UserData

export const columns = ({
	onSeatAssignmentChange,
	seatDisableTooltip,
	seatAssignmentDisabled,
	isNewAssignSeat,
	isTrial,
	onOverrideRole,
	onDeleteUser,
	provider,
}: ColumnProps): ColumnDef<SeatManagementTableRow>[] => [
	{
		accessorKey: "user_name",
		header: ({ column }) => {
			return (
				<DataTableSort column={column}>
					<span className="text-muted-foreground">User Name</span>
				</DataTableSort>
			)
		},
		cell: ({ row }) => (
			<div className="flex max-w-[250px] min-w-[200px] items-center gap-3 py-2">
				{provider !== PROVIDERS.AZURE_DEVOPS ? (
					<img
						src={row.original.avatar_url}
						className="h-10 min-w-10 rounded-full"
						alt="avatar"
					/>
				) : null}
				<div className="flex flex-col">
					<div className="font-500 break-all whitespace-nowrap">
						{row.original.user_name}
					</div>
					{(row.original.status === "NOT_ON_GITHUB" ||
						row.original.status === "NOT_ON_GITLAB" ||
						row.original.status === "NOT_ON_BITBUCKET") && (
						<div className="font-400 text-muted-foreground">Non-Org Member</div>
					)}
				</div>
			</div>
		),
	},
	{
		// Enables column filtering for is_bot
		accessorKey: "is_bot",
		header: () => null,
		cell: () => null,
	},
	{
		// Enables column filtering for isBillingAdmin
		accessorKey: "isBillingAdmin",
		header: () => null,
		cell: () => null,
	},
	{
		accessorKey: "pr_last_created",
		header: ({ column }) => {
			return (
				<DataTableSort column={column}>
					<span className="text-muted-foreground">Latest CodeRabbit PR</span>
				</DataTableSort>
			)
		},
		cell: ({ row }) => (
			<div className="font-400 px-12 text-center md:pr-24">
				{row.original.pr_last_created
					? moment(row.original.pr_last_created).fromNow()
					: "Never"}
			</div>
		),
	},
	{
		id: "role",
		accessorFn: ({ role, override_role }) => getRole(role, override_role),
		header: ({ column }) => {
			return (
				<DataTableSort column={column}>
					<span className="text-muted-foreground">Role</span>
				</DataTableSort>
			)
		},
		cell: ({ row }) => {
			const { provider } = useProvider()
			const providerName = useMemo(() => {
				if (provider) {
					const hyphenIndex = provider.indexOf("-")
					if (hyphenIndex !== -1) {
						return capitalize(provider.slice(0, hyphenIndex))
					}

					return capitalize(provider)
				}

				return "provider"
			}, [provider])

			return (
				<div className="font-400 px-4 text-center md:pr-8">
					<Tooltip>
						<TooltipTrigger asChild>
							<div>
								{getRole(row.original.role, row.original.override_role)}
							</div>
						</TooltipTrigger>
						{(row.original.override_role || row.original.role !== "N/A") && (
							<TooltipContent align="start" alignOffset={-20}>
								{!row.original.override_role
									? `Inherited from ${providerName}`
									: "Overriden from CodeRabbit"}
							</TooltipContent>
						)}
					</Tooltip>
				</div>
			)
		},
	},
	{
		accessorKey: "on_seat",
		header: ({ column }) => {
			return (
				<div className="text-right">
					<DataTableSort column={column}>Seat Status</DataTableSort>
				</div>
			)
		},
		cell: ({ row }) => {
			const getTooltipMessage = () => {
				if (seatDisableTooltip) {
					return seatDisableTooltip
				}

				if (isNewAssignSeat && !row.original.is_bot) {
					return "Seat assigned as the developer creates a PR"
				}

				if (isTrial && !row.original.is_bot) {
					return "Seat assignment is disabled during the trial"
				}

				return null
			}

			const tooltipMessage = getTooltipMessage()
			const isDisabled =
				seatAssignmentDisabled ||
				(isNewAssignSeat && !row.original.is_bot) ||
				(isTrial && !row.original.is_bot) ||
				row.original.isBillingAdmin

			return (
				<div className="mr-14 flex justify-end">
					<Tooltip>
						<TooltipTrigger asChild>
							<div className="w-fit">
								<Switch
									checked={row.original.on_seat}
									onCheckedChange={() => {
										onSeatAssignmentChange(row.original)
									}}
									disabled={isDisabled}
								/>
							</div>
						</TooltipTrigger>
						{tooltipMessage && (
							<TooltipContent>{tooltipMessage}</TooltipContent>
						)}
					</Tooltip>
				</div>
			)
		},
	},
	{
		accessorKey: "actions",
		header: () => null,
		cell: ({ row }) => {
			const selectedOrg = getSelectedOrg()

			// Only users with admin privileges can toggle admin status
			// This includes both git provider admins and users with override_role set to ADMIN
			const isAdmin = isOrgAdmin(selectedOrg)

			const isSelf =
				sessionStorage.getItem("user_id") === row.original.user_id.toString()

			const getTooltipContent = () => {
				let message: React.ReactNode

				if (isSelf) {
					message = "You cannot change your own role"
				} else if (!isAdmin) {
					message = "You need admin rights to perform this action"
				} else if (
					row.original.role === "admin" ||
					row.original.override_role === "ADMIN"
				) {
					message = "User is already an admin."
				} else if (!row.original.override_role) {
					message = (
						<>
							<div className="text-xs">
								Promoting a user to an admin allows them to manage the
								subscription and the seats.
							</div>
						</>
					)
				}

				if (message) {
					return <TooltipContent>{message}</TooltipContent>
				}

				return null
			}

			if (row.original.is_bot) {
				return null
			}

			return (
				<div className="flex justify-end">
					<DropdownMenu>
						<Tooltip>
							<TooltipTrigger asChild>
								<DropdownMenuTrigger asChild>
									<Button
										variant="ghost"
										className="h-8 w-8 p-0"
										disabled={!isAdmin}
									>
										<span className="sr-only">Open menu</span>
										<LuMoreVertical className="h-4 w-4" />
									</Button>
								</DropdownMenuTrigger>
							</TooltipTrigger>
							{!isAdmin && (
								<TooltipContent>
									You need admin rights to perform this action
								</TooltipContent>
							)}
						</Tooltip>
						<DropdownMenuContent align="end">
							<Tooltip>
								<TooltipTrigger asChild>
									<DropdownMenuItem
										disabled={!isAdmin}
										onClick={() => {
											onOverrideRole(row.original)
										}}
									>
										<LuUserCog2 className="mr-2 h-4 w-4" />
										<span>
											{row.original.override_role === "ADMIN"
												? "Revoke Admin"
												: "Make Admin"}
										</span>
									</DropdownMenuItem>
								</TooltipTrigger>
								{getTooltipContent()}
							</Tooltip>

							{(row.original.status === "NOT_ON_GITHUB" ||
								row.original.status === "NOT_ON_GITLAB" ||
								row.original.status === "NOT_ON_AZURE_DEVOPS" ||
								row.original.status === "NOT_ON_BITBUCKET" ||
								row.original.isBillingAdmin) && (
								<Tooltip>
									<TooltipTrigger asChild>
										<DropdownMenuItem
											disabled={!isAdmin}
											onClick={() => {
												onDeleteUser(row.original)
											}}
											className="text-destructive focus:text-destructive"
										>
											<LuTrash2 className="mr-2 h-4 w-4" />
											<span>Delete User</span>
										</DropdownMenuItem>
									</TooltipTrigger>
									{!isAdmin && (
										<TooltipContent>
											You need admin rights to delete users
										</TooltipContent>
									)}
								</Tooltip>
							)}
						</DropdownMenuContent>
					</DropdownMenu>
				</div>
			)
		},
	},
]

const getRole = (
	role: UserData["role"],
	overrideRole: UserData["override_role"],
) => {
	if (overrideRole) {
		return `Overridden (${capitalize(overrideRole.toLowerCase())})`
	}

	if (role === "N/A") {
		return role
	}

	return capitalize(role.toLowerCase())
}
