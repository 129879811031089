import { Admonition } from "@/components/Admonition"
import { Button } from "@/components/ui/button"
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form.tsx"
import { Input } from "@/components/ui/input"
import { trpc } from "@/trpc"
import { zodResolver } from "@hookform/resolvers/zod"
import { Book } from "lucide-react"
import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import z from "zod"

const formSchema = z.object({
	userToken: z.string().min(1, {
		message: "Token is required.",
	}),
})

interface ChooseBotUserModalProps {
	readonly open: boolean
	readonly onOpenChange: (open: boolean) => void
}

const ChooseBotUserModal: React.FC<ChooseBotUserModalProps> = ({
	open,
	onOpenChange,
}) => {
	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			userToken: "",
		},
	})

	const saveGitlabUserPreferenceQuery =
		trpc.providers.updateOrgGitlabUserPreference.useMutation({
			onSuccess: resp => {
				if (resp.success) {
					toast.success("GitLab user details saved successfully")

					form.reset({
						userToken: "",
					})

					onOpenChange(false)
				} else {
					toast.error("Failed to save GitLab user usage preference")
				}
			},
			onError: () => {
				toast.error("Failed to save GitLab user usage preference")
			},
		})

	const saveGitlabUserQuery = trpc.providers.saveOrgUser.useMutation({
		onSuccess: resp => {
			if (resp.success) {
				saveGitlabUserPreferenceQuery.mutate({
					useCustomUser: true,
				})
			} else if ("message" in resp && resp.message === "Invalid user token") {
				toast.error(
					"Invalid PAT token. Please double-check the token and try again.",
				)
			}
		},
		onError: () => {
			toast.error("Failed to save GitLab user details2")
		},
	})

	const onSubmit = (values: z.infer<typeof formSchema>) => {
		saveGitlabUserQuery.mutate({
			userToken: values.userToken,
		})
	}

	useEffect(() => {
		if (open) {
			form.reset()
		}
	}, [open])

	return (
		<>
			<Dialog open={open} onOpenChange={onOpenChange}>
				<Form {...form}>
					<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
						<DialogContent
							showCloseIcon={true}
							onPointerDownOutside={e => {
								e.preventDefault()
							}}
							className="sm:max-w-lg"
						>
							<DialogHeader>
								<DialogTitle>GitLab Integration</DialogTitle>
							</DialogHeader>
							<div className="space-y-4">
								<div className="text-muted-foreground text-sm">
									<p className="mb-3">
										To post review feedback on the Merge Request, we need an
										Access Token with the appropriate permissions. We recommend
										creating either a Group Access Token (available only with
										GitLab Premium or Ultimate) or a Personal Access Token.
									</p>

									<div className="mb-3">
										<p>
											<span className="font-medium">Group Access Token:</span>
											<br />
											Go to your GitLab Group → Settings → Access Tokens
										</p>
										<br />
										<p>
											<span className="font-medium">
												Personal Access Token:
											</span>
											<br />
											Go to your GitLab profile → Preferences → Access Tokens
											<br />
											<br />
											<Admonition type="note">
												We recommend if creating a Personal Access Token, to
												create one under a separate GitLab user account (e.g.
												CodeRabbit) to act as your reviewer. Merge Requests
												created by this user will not be reviewed. This user
												should have developer access to your group.
											</Admonition>
										</p>
									</div>
								</div>
							</div>
							<div>
								<FormField
									control={form.control}
									name="userToken"
									render={({ field }) => (
										<FormItem>
											<FormLabel className="text-sm">Access Token</FormLabel>
											<FormControl>
												<Input
													placeholder="glpat-xxxxxxxxxxxxxxxxxxxx"
													className="font-extralight"
													{...field}
												/>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>
							<div className="mt-3 flex w-full justify-between">
								<Button variant="outline" asChild>
									<Link
										target="_blank"
										to="https://docs.coderabbit.ai/platforms/saas-gitlab"
									>
										<Book size={16} className="mr-2" />
										Documentation
									</Link>
								</Button>
								<Button
									type="submit"
									onClick={async () => {
										await form.handleSubmit(onSubmit)()
									}}
									disabled={saveGitlabUserQuery.isLoading}
								>
									Submit
								</Button>
							</div>
						</DialogContent>
					</form>
				</Form>
			</Dialog>
		</>
	)
}

export default ChooseBotUserModal
