import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover"
import { cn } from "@/lib/utils"
import { ChevronsUpDown } from "lucide-react"
import * as React from "react"

interface ComboboxWithIdProps {
	options: { id: string; name: string }[]
	value: string
	onChange: (value: string) => void
	placeholder?: string
	idPlaceholder?: string
}

const ComboboxWithId: React.FC<ComboboxWithIdProps> = ({
	options,
	value,
	onChange,
	placeholder = "Select a channel",
	idPlaceholder = "(Optional) Enter channel ID (e.g. C12345678)",
}) => {
	const [open, setOpen] = React.useState(false)
	const [search, setSearch] = React.useState("")

	const filteredOptions =
		search === ""
			? options
			: options.filter(option =>
					option.name.toLowerCase().includes(search.toLowerCase()),
				)

	return (
		<div>
			<div className="font-500 font-poppins text-sm">
				Select a Channel by Name
			</div>
			<Popover open={open} onOpenChange={setOpen}>
				<PopoverTrigger asChild>
					<Button
						variant="outline"
						role="combobox"
						aria-expanded={open}
						className="mt-1 w-full justify-between"
					>
						{value
							? options.find(option => option.id === value)?.name
							: placeholder}
						<ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
					</Button>
				</PopoverTrigger>
				<PopoverContent className="w-full p-2">
					<Input
						placeholder={placeholder}
						value={search}
						onChange={e => {
							setSearch(e.target.value)
						}}
						className="mb-2"
					/>
					<div className="max-h-60 overflow-auto">
						{filteredOptions.length === 0 ? (
							<div className="text-muted-foreground p-2 text-sm">
								No results found
							</div>
						) : (
							filteredOptions.map(option => (
								<div
									key={option.id}
									className={cn(
										"flex cursor-pointer items-center rounded-sm px-2 py-2",
										value === option.id && "bg-accent",
										"hover:bg-accent",
									)}
									onClick={() => {
										onChange(option.id)
										setOpen(false)
										setSearch("")
									}}
								>
									{option.name}
								</div>
							))
						)}
					</div>
				</PopoverContent>
			</Popover>

			<div className="font-500 font-poppins mt-4 text-sm">
				Or enter Channel ID
			</div>
			<Input
				value={value}
				onChange={e => {
					const newValue = e.target.value
					if (/^C[0-9A-Z]+$/i.test(newValue) || newValue === "") {
						onChange(newValue)
					}
				}}
				placeholder={idPlaceholder}
			/>
		</div>
	)
}

export default ComboboxWithId
