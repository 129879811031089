import ImageButton from "@/components/ImageButton/ImageButton"
import Search from "@/components/Search/Search"
import { Button } from "@/components/ui/button"
import { DataTable } from "@/components/ui/data-table"
import { useColumnFilter } from "@/components/ui/data-table-utils"
import { Dialog, DialogContent, DialogFooter } from "@/components/ui/dialog"
import { Label } from "@/components/ui/label"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { Skeleton } from "@/components/ui/skeleton"
import { trpc } from "@/trpc"
import type { RowSelectionState } from "@tanstack/react-table"
import React, { useEffect, useMemo, useState } from "react"
import { FaRegTrashAlt } from "react-icons/fa"
import { useNavigate } from "react-router"
import { toast } from "react-toastify"
import CreateReportImage from "../../../assets/create-report.svg"
import DestructiveActionModal from "../../../components/Modal/DestructiveActionModal"
import { columns } from "./Columns"

export const reportTypes = {
	recurring: "Recurring",
	ondemand: "On-Demand",
} as const

const RecurringReports: React.FC = () => {
	const navigate = useNavigate()

	const listReports = trpc.reporting.listReports.useQuery()

	const reportList = listReports.data?.reports ?? []

	const [rowSelection, setRowSelection] = useState<RowSelectionState>({})
	const selectedRows = useMemo(
		() => Object.keys(rowSelection).map(index => reportList[Number(index)]?.id),
		[rowSelection, reportList],
	)

	const { columnFilters, setColumnFilters, setColumnFilter } =
		useColumnFilter<(typeof reportList)[number]>()

	const [deleteReportIDs, setDeleteReportIDs] = useState<string[]>([])

	const [hasSubscription, setHasSubscription] = useState<boolean>(true)

	const [selectReportTypeModal, setSelectReportTypeModal] =
		useState<boolean>(false)

	const deleteReports = trpc.reporting.deleteReports.useMutation({
		onSuccess: ({ data }) => {
			setRowSelection({})
			setDeleteReportIDs([])
			listReports.remove()
			void listReports.refetch().finally(() => {
				if (data.successfulDeletions === 0) {
					toast.error("Failed to delete the report(s)")
				} else if (data.failedDeletions === 0) {
					toast.success("Report(s) deleted successfully")
				} else {
					toast.warning(data.message)
				}
			})
		},
	})

	useEffect(() => {
		if (listReports.error) {
			if (listReports.error.data?.httpStatus === 401) {
				setHasSubscription(false)
			} else {
				toast.error(
					"Failed to fetch the list of reports: " + listReports.error.message,
				)
			}
		}
	}, [listReports.error])

	useEffect(() => {
		if (deleteReports.error) {
			toast.error(
				"Failed to delete the report(s): " + deleteReports.error.message,
			)
		}
	}, [deleteReports.error])

	const [reportType, setReportType] = useState<"ondemand" | "recurring">(
		"recurring",
	)

	return (
		<>
			<div className="container mx-auto px-8 pt-7 pb-2">
				<div className="container mx-auto">
					<div>
						<div className="mb-4 flex items-center justify-between">
							<div className="mb-3 flex flex-wrap items-center gap-3">
								<div className="font-500 font-inter text-foreground text-2xl leading-8">
									Reports
								</div>
							</div>

							{listReports.isLoading && <Skeleton className="h-10 w-32" />}
							{!listReports.isLoading && reportList.length !== 0 && (
								<ImageButton
									title="Create Report"
									onClick={() => {
										setSelectReportTypeModal(true)
									}}
								/>
							)}
						</div>

						{listReports.isLoading && (
							<div className="flex flex-col gap-4">
								<Search showLabel={false} />
								<div className="flex flex-col gap-4">
									{Array.from({ length: 10 }).map((_, index) => (
										<Skeleton key={index} className="h-12 w-full" />
									))}
								</div>
							</div>
						)}

						{!listReports.isLoading && reportList.length === 0 && (
							<div className="flex items-center justify-center rounded-md border">
								<div className="flex flex-col items-center justify-center px-6 py-20 text-center">
									<img src={CreateReportImage} alt="Create Report" />
									<h2 className="font-500 text-foreground mt-3 text-lg">
										{hasSubscription
											? "Create your first report"
											: "Reports are available on the Pro plan only"}
									</h2>
									<div className="text-muted-foreground">
										{hasSubscription
											? "Let's tell your data's story together!"
											: "Please upgrade to the Pro plan to access this feature"}
									</div>
									<div className="mt-4">
										{hasSubscription ? (
											<ImageButton
												title="Create Report"
												onClick={() => {
													setSelectReportTypeModal(true)
												}}
											/>
										) : (
											<Button
												onClick={() => {
													navigate("/settings/subscription")
												}}
											>
												Upgrade to Pro
											</Button>
										)}
									</div>
								</div>
							</div>
						)}
					</div>
					<Dialog
						open={selectReportTypeModal}
						onOpenChange={setSelectReportTypeModal}
					>
						<DialogContent>
							<div>
								<h2 className="text-lg font-medium">Select Report Type</h2>
								<div className="mt-4">
									<RadioGroup
										defaultValue="recurring"
										className="mt-4 flex flex-col gap-y-4"
										onValueChange={value => {
											setReportType(value as "ondemand" | "recurring")
										}}
									>
										<div className="flex flex-row items-start gap-x-2">
											<RadioGroupItem value="recurring" className="mt-1" />
											<div className="flex flex-col gap-y-1">
												<Label className="text-base leading-5 font-normal not-italic">
													Recurring
												</Label>
												<span className="text-muted-foreground text-sm">
													The report will be generated at the cadence you select
													and will run repeatedly
												</span>
											</div>
										</div>
										<div className="flex flex-row items-start gap-x-2">
											<RadioGroupItem value="ondemand" className="mt-1" />
											<div className="flex flex-col gap-y-1">
												<Label className="text-base leading-5 font-normal not-italic">
													On-Demand
												</Label>
												<span className="text-muted-foreground text-sm">
													The report will be generated for the date range you
													select and will run once
												</span>
											</div>
										</div>
									</RadioGroup>
									<DialogFooter>
										<Button
											onClick={() => {
												navigate(`./${reportType}/new`)
											}}
											className="mt-8"
										>
											Create Report
										</Button>
									</DialogFooter>
								</div>
							</div>
						</DialogContent>
					</Dialog>
					{!listReports.isLoading && reportList.length > 0 && (
						<div>
							<div className="mb-4 flex items-center justify-between gap-4">
								<Search
									onSearch={query => {
										setColumnFilter("name", query)
										setRowSelection({})
									}}
									showLabel={false}
								/>
								{selectedRows.length > 0 && (
									<button
										className="flex items-center gap-2 rounded-lg px-3 py-2 text-red-500 hover:bg-red-100"
										title="Delete"
										onClick={() => {
											setDeleteReportIDs(selectedRows.map(String))
										}}
									>
										<FaRegTrashAlt /> Delete selected
									</button>
								)}
							</div>
							<div className="block max-w-full overflow-x-auto overflow-y-hidden">
								<DataTable
									data={reportList}
									columns={columns({
										onDeleteReport: report => {
											setDeleteReportIDs([report.id])
										},
									})}
									tableOptions={{
										onRowSelectionChange: setRowSelection,
										onColumnFiltersChange: setColumnFilters,
										state: {
											rowSelection,
											columnFilters,
										},
									}}
								/>
							</div>

							<DestructiveActionModal
								active={deleteReportIDs.length > 0}
								title="Delete Report"
								description="Are you sure you want to delete the selected report(s)?"
								onCancel={() => {
									setDeleteReportIDs([])
								}}
								onConfirm={() => {
									if (deleteReportIDs.length > 0) {
										deleteReports.mutate(deleteReportIDs)
									}
								}}
								loading={deleteReports.isLoading}
							/>
						</div>
					)}
				</div>
			</div>
		</>
	)
}

export default RecurringReports
