import { Box, Modal } from "@mui/material"
import React from "react"

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	background: "white",
	borderRadius: 8,
	border: "none",
	boxShadow: 24,
	p: 4,
} as const

interface LimitReachedModalProps {
	readonly open: boolean
	readonly setOpen: (open: boolean) => void
}

const LimitReachedModal: React.FC<LimitReachedModalProps> = ({
	setOpen,
	open,
}) => {
	const handleClose = () => {
		setOpen(false)
	}

	return (
		<Modal open={open}>
			<Box sx={style}>
				<div className="flex flex-col gap-4">
					<span className="font-poppins">
						If you need to add more seats, please do so under the GitHub
						Marketplace via subscription management
					</span>
					<div className="flex flex-row justify-end gap-3">
						<button
							onClick={handleClose}
							className="bg-crb-primary flex items-center justify-center gap-2 self-stretch rounded-2xl p-2 px-5 text-white"
						>
							Ok
						</button>
					</div>
				</div>
			</Box>
		</Modal>
	)
}

export default LimitReachedModal
